import React from 'react'
import BivSwitch from "components/BivSwitch/BivSwitch.jsx";


const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

function TabGeneral(props) {

  const { values, onChange } = props

  TabGeneral.defaultProps = {
    onChange: () => null,
    values: { srt: true, sdv: true, sfv: true, sdid: true, sdic: true, edr: true, acr: true },
  }

  return (
    <div style={{ display: 'flex', flexDirection: "column", maxHeigth: "min-content" }}>

      <span>
        <BivSwitch
          text={languages[lang].proctoringC_viewTRTDashboardDTExam}
          value={values.srt}
          onChange={() => {
            if (!global.readOnlyUser) {
              values.srt = !values.srt
              onChange(values)
            }
          }}
        />
      </span>

      <span>
        <BivSwitch
          text={languages[lang].proctoringC_enableDR}
          value={values.edr}
          onChange={() => {
            if (!global.readOnlyUser) {
              values.edr = !values.edr
              if (!values.edr) {
                values.sdv = false
              }
              onChange(values)
            }
          }}
        />
      </span>

      <span>
        <BivSwitch
          text={languages[lang].proctoringC_showDR}
          value={values.sdv}
          disable={!values.edr}
          onChange={() => {
            if (!global.readOnlyUser) {
              values.sdv = !values.sdv
              onChange(values)
            }
          }}
        />
      </span>

      <span>
        <BivSwitch
          text={languages[lang].proctoringC_enableCR}
          value={values.acr}
          onChange={() => {
            if (!global.readOnlyUser) {
              values.acr = !values.acr
              if (!values.acr) {
                values.sfv = false
              }
              onChange(values)
            }
          }}
        />
      </span>

      <span>
        <BivSwitch
          text={languages[lang].proctoringC_showFR}
          value={values.sfv}
          disable={!values.acr}
          onChange={() => {
            if (!global.readOnlyUser) {
              values.sfv = !values.sfv
              onChange(values)
            }
          }}
        />
      </span>
      {
        global._uni_ === '1' &&
        <span>
          <BivSwitch
            text={languages[lang].proctoringC_showIDBD}
            value={values.sdid}
            onChange={() => {
              if (!global.readOnlyUser) {
                values.sdid = !values.sdid
                onChange(values)
              }
            }}
          />
        </span>
      }
      {
        global._uni_ === '1' &&
        <span>
          <BivSwitch
            text={languages[lang].proctoringC_showIDBC}
            value={values.sdic}
            onChange={() => {
              if (!global.readOnlyUser) {
                values.sdic = !values.sdic
                onChange(values)
              }
            }}
          />
        </span>
      }

    </div>
  )
}

export default TabGeneral