import React from 'react'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import CardBody from "components/Card/CardBody.jsx"
import BootstrapTable from "react-bootstrap-table-next"
import Button from "components/CustomButtons/Button.jsx"
import ClearIcon from '@material-ui/icons/Clear'

const languages = require('../../assets/translate/translate_columnTable_all.json')


let lang = localStorage.lang  ? localStorage.lang : 'es'

function KWAnomaliesSummary(props) {

  const {
    data,
    changeAnomaly,
    filteredAnomaly,
    clearFilter
  } = props

  const filter = (cell, row) => {
    return (
      <Button
        style={{ marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 }}
        color="info"
        onClick={() => {
          changeAnomaly(row._key)
        }}
        round
      >
        {languages[lang].allCT_filter}
        
      </Button>
    )
  }

  function getTableIncidences() {
    return [
      {
        dataField: "key",
        text: languages[lang].allCT_anomaly,
        editable: false,
        hidden: true,
      }, {
        dataField: "text",
        text: languages[lang].allCT_anomaly,
        editable: false,
        //sort: true,
        headerStyle: { minWidth: '130px' },
      //  sortCaret: utilTable.sortIcon,
      },
      {
        dataField: "percent",
        text: languages[lang].allCT_percentage,
        editable: false,
       // sort: true,
        headerStyle: { minWidth: "130px" },
       // sortCaret: utilTable.sortIcon,
      },
      {
        dataField: 'button',
        text: '',
        formatter: filter,
        //hidden: !this.state.userPermisionTrue,
        editable: false,
        headerStyle: { width: '78px' },
      },
    ]
  }

  KWAnomaliesSummary.defaultProps = {
    data: [{ incidencesTable: { counters: [] } }],
  }

  const _counterLength = data.incidencesTable ? data.incidencesTable.counters ? data.incidencesTable.counters.length : 0 : 0

  return (

    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{}} >
      {(_counterLength > 0) ?
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ minWidth: 400,overflow:'scroll' }}>
            <BootstrapTable
              bootstrap4
              keyField="key"
              data={data.incidencesTable.counters}
              columns={getTableIncidences()}
              striped
              bordered={true}
              wrapperClasses="table-responsive"
              classes='scrollable-table'
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
                    <div style={{ width: '100%', textAlign: 'end' }}>
                      <Button
                        round
                        disabled={(!filteredAnomaly)}
                        endIcon={<ClearIcon />}
                        style={{ width: '100%', maxWidth: 104, marginRight: 13, paddingTop: 5, paddingBottom: 5 }}
                        color="#B5C3AE"
                        onClick={() => {
                          clearFilter()
                        }}
                      >{languages[lang].examsNA_clean}</Button>
                    </div>
                  </GridItem>
        </GridContainer>

        :
        <CardBody style={{ overflow: "auto" }}>
          <div style={{ width: "100%", paddingTop: 20, paddingBottom: 20, display: "flex", flexDirection: "column", textAlign: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 16, fontWeight: 400, color: '#8c8a88' }}>No se detectaron anomalías.</div>
        </CardBody>
      }
    </GridItem >



  )

}

export default KWAnomaliesSummary
