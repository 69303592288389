import React from 'react'
import Button from "components/CustomButtons/Button.jsx";

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivButtonHigh(props) {

  //const {active, onClick} = props

  BivButtonHigh.defaultProps = {
    onClick: () => null,
    active: false,
  }
  
  if (props.active === true) {
    return <Button
      color="warning"
      size="sm"
      simple
      round
      onClick={() => {props.onClick()}}
      >{languages[lang].button_high}</Button>
  }

  return <Button
    color="warning"
    size="sm"
    round
    onClick={() => {props.onClick()}}
    >{languages[lang].button_high}</Button>

}

export default BivButtonHigh