import React from 'react'
import BivSwitch from "components/BivSwitch/BivSwitch.jsx";


import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Collapse from '@material-ui/core/Collapse';

import BivProcessList from "components/BivProcessList/BivProcessList.jsx";
import VirtualCamerasList from "components/VirtualCamerasList/VirtualCamerasList.jsx";
import IconButton from '@material-ui/core/IconButton';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';




const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

class TabEnvironment extends React.Component {

  //const {values, onChange} = props


  constructor(props) {
    super(props)
    this.state = {
      processListFilterText: "",
      vcListFilterText: "",
      processNewItemDialog: false,
      virtualCamerasNewItemDialog:false,
      processListShow: false,
    }
  }

  //TabEnvironment.defaultProps = {
  //onChange: () => null,
  //values: {bl: true, cs: true, pc: true, vm: true},
  //}
  //componentDidMount(){
  //let processListShow = this.state
  //processListShow = false
  //}

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden' }}>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_checkTBLevel}
            value={this.props.values.bl}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.bl = !this.props.values.bl
                this.props.onChange(this.props.values)
              }
            }}
          />
        </span>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlEOVMachines}
            value={this.props.values.vm}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.vm = !this.props.values.vm
                this.props.onChange(this.props.values)
              }
            }}
          />

        </span>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlAOFSSpace}
            value={this.props.values.cs}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.cs = !this.props.values.cs
                this.props.onChange(this.props.values)
              }
            }}
          />
        </span>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlTVLOTMicrophone}
            value={this.props.values.msc}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.msc = !this.props.values.msc
                this.props.onChange(this.props.values)
              }
            }}
          />
        </span>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlNOMonitors}
            value={this.props.values.mc}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.mc = !this.props.values.mc
                this.props.onChange(this.props.values)
              }
            }}
          />
        </span>

        {/* 
        <span>
          <BivSwitch
            text="Controlar identidad al habilitar un examen."
            value={this.props.values.ic}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.ic = !this.props.values.ic
                this.props.onChange(this.props.values)
              }
            }}
          />
        </span>
        */}

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlOProcesses}
            value={this.props.values.pc}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.pc = !this.props.values.pc
                if (this.props.values.pc === true) {
                  this.setState({ processListShow: true })
                }
                this.props.onChange(this.props.values)
              }
            }}
          />

          <IconButton
            disabled={!this.props.values.pc}
            onClick={() => {
              this.setState({ processListShow: !this.state.processListShow })
            }}
            //aria-expanded={this.state.expanded == {nameExpand}}
            //aria-expanded={"processExpand"}
            aria-label="show more"
          >
            {this.props.values.pc ? this.state.processListShow ? <ExpandLess /> : < ExpandMoreIcon /> : < ExpandMoreIcon />}
          </IconButton>

          <Collapse in={this.state.processListShow} timeout="auto" unmountOnExit>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11} lg={11} xl={11}>
                <Collapse in={this.props.values.pc} timeout="auto" unmountOnExit>
                  <BivProcessList
                    from={1}
                    list={this.props.values.pd}
                    spc={this.props.values.spc}
                    changeSpc={(x) => {
                      if (!global.readOnlyUser) {
                        this.props.values.spc = x
                        this.props.onChange(this.props.values)
                      }
                    }}
                    filterText={this.state.processListFilterText}

                    onFilter={(filterText) => {
                      this.setState({ processListFilterText: filterText }, () => {
                        this.props.checkChanges()
                      })
                    }}

                    onDelete={(item) => {
                      if (!global.readOnlyUser) {
                        this.setState({}, () => {
                          this.props.values.pd.splice(item, 1)
                          this.props.checkChanges()
                        })
                      }
                    }}

                    onChange={(item) => {
                      if (!global.readOnlyUser) {
                        this.setState({}, () => {
                          this.props.values.pd[item.row.key] = { 'pname': item.row.processname }
                          this.props.checkChanges()
                        })
                      }
                    }}

                    onAdd={(value) => {
                      this.setState({}, () => {
                        let newItem = { 'pname': value }
                        this.props.values.pd.push(newItem)
                        this.props.checkChanges()
                      })
                    }}

                    newItemDialog={this.state.virtualCamerasNewItemDialog}

                    togleNewItemDialog={() => {
                      this.setState({ virtualCamerasNewItemDialog: !this.state.virtualCamerasNewItemDialog })
                    }}

                  />

                </Collapse>
              </GridItem>
            </GridContainer>
          </Collapse>


        </span>

        <span>
          <BivSwitch
            text={languages[lang].proctoringC_controlOfVirtualCameras}
            value={this.props.values.vc.a}
            onChange={() => {
              if (!global.readOnlyUser) {
                this.props.values.vc.a = !this.props.values.vc.a
                if (this.props.values.vc.a === true) {
                  this.setState({ virtualListShow: true })
                }
                this.props.onChange(this.props.values)
              }
            }}
          />

          <IconButton
            disabled={!this.props.values.vc.a}
            onClick={() => {
              this.setState({ virtualListShow: !this.state.virtualListShow })
            }}
            //aria-expanded={this.state.expanded == {nameExpand}}
            //aria-expanded={"processExpand"}
            aria-label="show more"
          >
            {this.props.values.vc ? this.state.virtualListShow ? <ExpandLess /> : < ExpandMoreIcon /> : < ExpandMoreIcon />}
          </IconButton>

          <Collapse in={this.state.virtualListShow} timeout="auto" unmountOnExit>
            <GridContainer>
              <GridItem xs={11} sm={11} md={11} lg={11} xl={11}>
                <Collapse in={this.props.values.vc} timeout="auto" unmountOnExit>
                  <VirtualCamerasList
                    from={1}
                    list={this.props.values.vc.l}
                    spc={this.props.values.vc.sm}
                    changeSpc={(x) => {
                      if (!global.readOnlyUser) {
                        this.props.values.vc.sm = x
                        this.props.onChange(this.props.values)
                      }
                    }}
                    filterText={this.state.vcListFilterText}

                    onFilter={(filterText) => {
                      this.setState({ vcListFilterText: filterText }, () => {
                        this.props.checkChanges()
                      })
                    }}

                    onDelete={(item) => {
                      if (!global.readOnlyUser) {
                        this.setState({}, () => {
                          this.props.values.vc.l.splice(item, 1)
                          this.props.checkChanges()
                        })
                      }
                    }}

                    onChange={(item) => {
                      if (!global.readOnlyUser) {
                        this.setState({}, () => {
                          this.props.values.vc.l[item.row.key] = { 'pname': item.row.processname }
                          this.props.checkChanges()
                        })
                      }
                    }}

                    onAdd={(value) => {
                      this.setState({}, () => {
                        let newItem = { 'pname': value }
                        this.props.values.vc.l.push(newItem)
                        this.props.checkChanges()
                      })
                    }}

                    newItemDialog={this.state.processNewItemDialog}

                    togleNewItemDialog={() => {
                      this.setState({ processNewItemDialog: !this.state.processNewItemDialog })
                    }}

                  />

                </Collapse>
              </GridItem>
            </GridContainer>
          </Collapse>


        </span>

      </div>
    )
  }
}

export default TabEnvironment