import React from "react"
import { Paper } from "@mui/material"


class UserWithoutRol extends React.Component {
    render() {
        return (
            <div style={{ paddingLeft: 20, paddingRight: 20, top: 30, position: 'relative' }}>
                <Paper elevation={2} style={{
                    position: 'absolute',
                    width: '100%',
                    // border: '2px solid #000',
                    borderRadius: 15,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    left: '50%',
                    boxShadow: 24,
                    overflow: 'hidden'
                }}>
                    <div style={{ backgroundColor: '#01796b', padding: '32px 16px 8px 16px', }}>
                        <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}> Perfil creado exitosamente!</h3>
                    </div>
                    <div style={{ padding: '16px 16px 17px 16px', }}>
                        <h5 id="simple-modal-description" style={{ marginTop: 0, color: 'black' }}> Podrás navegar en el Panel, una vez que tu Institución asigne los permisos correspondientes a tu Usuario.</h5>


                    </div>

                </Paper>
                <p></p>
            </div>
        )
    }
}

export default UserWithoutRol
