import React from 'react'
import Button from "components/CustomButtons/Button.jsx";

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivButtonLow(props) {

  //const {active, onClick} = props

  BivButtonLow.defaultProps = {
    onClick: () => null,
    active: false,
  }

  if (props.active === true) {
    return <Button
      color="success"
      size="sm"
      simple
      round
      onClick={() => {props.onClick()}}
      >{languages[lang].button_low}</Button>
  }

  return <Button
    color="success"
    size="sm"
    round
    onClick={() => {props.onClick()}}
    >{languages[lang].button_low}</Button>

}

export default BivButtonLow