/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { borderBottom } from "@mui/system";
import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
  // Biv colors
  //-------------
  violetBiv,
  darkVioletBiv,
  greenBiv,
  lightBlueBiv,
  redBiv,
  yellowBiv,
  orangeBiv,
  lightBlueKW,
  //-------------
} from "assets/jss/material-dashboard-pro-react.jsx";

const navPillsStyle = theme => ({
  root: {
    marginTop: "20px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important"
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  displayNone: {
    display: "none !important"
  },
  fixed: {
    overflow: "visible !important"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    color: grayColor[6],
    height: "auto",
    opacity: "1",
    margin: "0 5px",
    fontSize: "12px",
    maxWidth: "100%",
    minWidth: "100px",
    minHeight: "48px",
    textAlign: "center",
    transition: "all .3s",
    fontWeight: "500",
    lineHeight: "24px",
    borderRadius: "0px",
    textTransform: "uppercase",
    letterSpacing: "initial"
  },
  pillsWithIcons: {
    borderRadius: "4px"
  },
  tabIcon: {
    width: "30px",
    height: "30px",
    display: "block",
    margin: "15px 0 !important"
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  labelContainer: {
    padding: "0!important",
    color: "inherit"
  },
  label: {
    lineHeight: "24px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "500",
    position: "relative",
    display: "block",
    color: "inherit"
  },
  contentWrapper: {
    marginTop: "20px"
  },
  primary: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.4)"
    }
  },
  // Biv colors
  //-----------------------------
  violetBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: violetBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(violetBiv[0]) +
        ", 0.4)"
    }
  },
  darkVioletBiv: {
    "&,&:hover": {
      color: "#00BFFF",
      borderBottom:"solid",
      borderBottomColor:"#00BFFF"
      // backgroundColor: darkVioletBiv[0],
      // boxShadow:
      //   "0 4px 20px 0px rgba(" +
      //   hexToRgb(blackColor) +
      //   ", 0.14), 0 7px 10px -5px rgba(" +
      //   hexToRgb(darkVioletBiv[0]) +
      //   ", 0.4)"
    }
  },
  greenBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: greenBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(greenBiv[0]) +
        ", 0.4)"
    }
  },
  lightBlueBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: lightBlueBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(lightBlueBiv[0]) +
        ", 0.4)"
    }
  },
  redBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: redBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(redBiv[0]) +
        ", 0.4)"
    }
  },
  yellowBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: yellowBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(yellowBiv[0]) +
        ", 0.4)"
    }
  },
  orangeBiv: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: orangeBiv[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(orangeBiv[0]) +
        ", 0.4)"
    }
  },
  lightBlueKW: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: lightBlueKW[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(lightBlueKW[0]) +
        ", 0.4)"
    }
  },
  //-----------------------------
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  }
});

export default navPillsStyle;
