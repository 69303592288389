import React from 'react'

import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from '@material-ui/core/IconButton';

import BivSwitch from "components/BivSwitch/BivSwitch.jsx";

import BivPriority from "components/BivPriority/BivPriority.jsx";

import BivNumberInput from "components/BivNumberInput/BivNumberInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";


class BivControlAudio extends React.Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render() {

    return (

      <div>

        {/* Switch */}
        <BivSwitch
          text={this.props.switchText}
          disable={this.props.switchDisable}
          value={this.props.values.a}
          onChange={this.props.switchOnChange}
        />

        {/* Icon */}
        <IconButton
          id={this.props.expandId}
          disabled={(global.readOnlyUser) ? false : !this.props.values.a}
          onClick={() => {
            this.props.expandOnChange()
            //this.handleExpandClick({nameExpand})
          }}
          //aria-expanded={this.state.expanded == {nameExpand}}
          aria-expanded={this.props.expandValue}
          aria-label="show more"
        >
          {this.props.expandValue ? <ExpandLess /> : < ExpandMoreIcon/> }
        </IconButton>

        {/* Expanded */}
        <Collapse in={this.props.expandValue} timeout="auto" unmountOnExit>

          <CardContent>

            {/* Valor maximo permitido .vm */}
            <GridContainer style={{ alignItems: 'baseline', marginLeft: 5 }}>

              <GridItem >
                <FormLabel>
                  {this.props.maxValueText}
                </FormLabel>
              </GridItem> 

              <GridItem >
                <BivNumberInput
                  value={this.props.values.vm}
                  disabled={this.props.values.a ? false : true}
                  onChange={(numero) => {
                    if (!global.readOnlyUser) {
                      this.props.maxValueOnChange(numero)
                    }
                  }}
                />
              </GridItem>

            </GridContainer>

            {/* Cantidad de veces para incidencia */}
            <GridContainer style={{ alignItems: 'baseline', marginLeft: 5 }}>
              <GridItem >
                <FormLabel>
                  {this.props.incidenceCountText}
                </FormLabel>
              </GridItem>
              <GridItem >
                <BivNumberInput
                  value={this.props.values.vw}
                  disabled={this.props.values.a ? false : true}
                  onChange={(number) => {
                    if (!global.readOnlyUser) {
                      this.props.incidenceCountOnChange(number)
                    }
                  }}
                />
              </GridItem>
            </GridContainer>

            {/* Prioridad Inicial */}
            <GridContainer style={{ alignItems: 'baseline', marginLeft: 5 }}>

              <GridItem >
                <FormLabel>
                  {this.props.initialPriorityText}
                </FormLabel>
              </GridItem>

              <GridItem >
                <BivPriority
                  value={this.props.values.ip}
                  onChangeFuncion={(active) => {
                    this.props.initialPriorityOnChange(active)
                  }}
                />
              </GridItem>

            </GridContainer>

            {/* Cambio de prioridad */}
            <GridContainer style={{ alignItems: 'baseline', marginLeft: 5, marginTop: 15 }}>
              <GridItem xs={12} >
                <FormLabel>
                  {this.props.changePriorityLabel}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                <FormLabel>
                  {this.props.changePriorityFromText}
                </FormLabel>
                <BivNumberInput
                  value={this.props.values.t.v}
                  disabled={this.props.values.a ? false : true}
                  onChange={(number) => {
                    if (!global.readOnlyUser) {
                      this.props.changePriorityFromOnChange(number)
                    }
                  }}
                />

                <FormLabel>
                  {this.props.changePriorityToText}
                </FormLabel>

                <BivPriority
                  value={this.props.values.t.p}
                  onChangeFuncion={(active) => {
                    this.props.changePriorityToOnChange(active)
                  }}
                />
              </GridItem>
            </GridContainer>
            <div style={{ width: "100%", height: 20, marginTop: 11, borderTop: '1px solid #ced4db' }}></div>

          </CardContent>

        </Collapse>

      </div>
    )
  }
}

BivControlAudio.defaultProps = {

  //values: { a: true, c: 1, ip: 1, t: { v: 1, p: 1 }, vm: 100, vw: 1 },

  switchText: '-',
  switchDisable: false,
  switchOnChange: () => null,

  expandValue: true,
  expandId: '',
  expandOnChange: () => null,

  maxValueText: 'Volumen máximo permitido:',
  maxValueOnChange: () => null,

  incidenceCountText: 'Cantidad de veces que debe ocurrir para que se produzca una incidencia:',
  incidenceCountOnChange: () => null,

  initialPriorityText: 'Prioridad Inicial:',
  initialPriorityOnChange: () => null,

  changePriorityLabel: 'Cambio de prioridad:',
  changePriorityFromText: 'A partir de',
  changePriorityFromOnChange: () => null,
  changePriorityToText: 'veces de aparición en un examen pasa a prioridad',
  changePriorityToOnChange: () => null,

}

export default BivControlAudio