/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard/Dashboard.jsx";

// import LoginPage from "views/Pages/LoginPage2.jsx";

import LoginPage from "views/Pages/Login/LoginPage.jsx";



// @material-ui/icons
// import Apps from "@material-ui/icons/Apps"
import DashboardIcon from "@material-ui/icons/Dashboard"
import Settings from "@material-ui/icons/Settings"
import Assignment from "@material-ui/icons/Assignment"
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentInd from "@material-ui/icons/AssignmentInd"

import BivConfigurationEnvironment from "components/BivConfigurationEnvironment/BivConfigurationEnvironment.jsx"
import BivConfigurationProctoring from "components/BivConfigurationProctoring/BivConfigurationProctoring.jsx"
import BivConfigurationRegistry from "components/BivConfigurationRegistry/BivConfigurationRegistry.jsx"
import BivRegistry from "components/BivRegistry/BivRegistry.jsx"
import BivEnroll from "components/BivEnrollNew/BivEnroll.jsx"

import ExamnsNewAudit from "views/Examns/ExamnsNewAudit.jsx"

import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import RolsManager from "components/RolsManager/RolsManager";
import UsersManager from "components/UsersManager/UsersManager";
import ProfileManager from "components/Profile/Profile";
import LockIcon from '@material-ui/icons/Lock';
import UserWithoutRol from "components/UserWithoutRol/UserWithoutRol";

import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import AssignmentConfig from "./assets/img/assignmentConfig.svg";
import LaptopConfig from "./assets/img/laptopConfig.svg"
// import UsersManager from "components/UsersManager2/UsersManager";



const languages = require('./assets/translate/translate_menu.json')
let lang = localStorage.lang ? localStorage.lang : 'es'
if (!localStorage.lang) {
  localStorage.lang = "es"
}

var dashRoutes = [
  // {
  //   componentNumber: 0,
  //     path: "/dashboard",
  //     name: languages[lang].menu_dashboard,
  //     rtlName: "لوحة القيادة",
  //     icon: DashboardIcon,
  //     component: Dashboard,
  //     layout: "/admin",
  //     message: "",
  // },
  
  
  {
    invisible: true,
    path: "/login-page",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    //mini: "L",
    //rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
    message: ""
  },

  {
    componentNumber: 1,
    path: "/proctorings/completas",
    name: languages[lang].menu_exam,
    mini: "EI",
    rtlName: "الحاجيات",
    icon: PlaylistAddCheckIcon,
    component: ExamnsNewAudit,
    layout: "/admin",
    message: ""
  },
 
 
  {
    componentNumber: 2,
    collapse: true,
    name: languages[lang].menu_settings,
    rtlName: "صفحات",
    icon: Settings,
    state: "pageCollapse",
    message: "",
    views: [
      {
        componentNumber: 11,
        path: "/ConfiguracionProctoring",
        name: languages[lang].menu_proctoring,
        rtlName: "عالتسعير",
        icon: LaptopConfig,
        // mini: "CP",
        rtlMini: "ع",
        iconImg:true,
        component: BivConfigurationProctoring,
        layout: "/admin",
        message: ""
      },
      {
        componentNumber: 12,
        path: "/ConfiguracionAmbiente",
        name: languages[lang].menu_enviroment,
        rtlName: "عالتسعير",
        icon: VideoSettingsIcon,
        // mini: "CP",
        rtlMini: "ع",
        component: BivConfigurationEnvironment,
        layout: "/admin",
        message: ""
      },
      // {
      //   componentNumber: 13,
      //   path: "/ConfiguracionRegistro",
      //   name: "Ajustes de registro",
      //   rtlName: "عالتسعير",
      //   // mini: "AR",
      //   iconImg:true,
      //   icon: AssignmentConfig,
      //   rtlMini: "ع",
      //   component: BivConfigurationRegistry,
      //   layout: "/admin",
      //   message: "",
      // },
      {
        componentNumber: 7,
        path: "/Usuarios",
        name: languages[lang].users,
        rtlName: "عالتسعير",
        mini: "U",
        rtlMini: "ع",
        component: UsersManager,
        layout: "/admin",
        message: ""
      },
      {
        componentNumber: 8,
        path: "/Roles",
        name: languages[lang].rols,
        rtlName: "عالتسعير",
        mini: "R",
        rtlMini: "ع",
        component: RolsManager,
        layout: "/admin",
        message: ""
      },
     
     
    ],
  },
  {
    componentNumber: 4,
    // collapse: true,
    path: "/generados",
    name: languages[lang].menu_enrollment,
    rtlName: "صفحات",
    icon: AssignmentInd,
    //state: "enrollCollapse",
    component: BivEnroll,
    layout: "/admin",
    message: "",
   

  }, 
  // {
  //   componentNumber:14,
  //   path:"/RolesUsuarios",
  //   name:"Roles y usuarios",
  //   mini:"RU",
  //   icon:PlaylistAddCheckIcon,
  //   component:UsersManager,
  //   layout: "/admin",
  //   message: ""

  // },
  {
    componentNumber: 3,
    path: "/registros",
    name: languages[lang].menu_logs,
    rtlName: "الحاجيات",
    icon: Assignment,
    component: BivRegistry,
    layout: "/admin",
    message: ""
  },
 
  // {
  //   componentNumber: 6,
  //   path: "/canvasExamns",
  //   name: 'Canvas',
  //   rtlName: "عالتسعير",
  //   rtlMini: "ع",
  //   icon: BlurOnIcon,
  //   component: Canvas,
  //   layout: "/admin",
  //   message: ""
  // },
  {
    componentNumber: 10,
    path: "/changepass",
    // invisible: true,
    name: languages[lang].changePass,
    rtlName: "عالتسعير",
    rtlMini: "ع",
    icon: LockIcon,
    component: ProfileManager,
    layout: "/admin",
    message: ""
  },
  {
    componentNumber: 5,
    path: "",
    invisible: true,
    name: languages[lang].menu_logOut,
    closeSession: true,
    rtlName: "عالتسعير",
    rtlMini: "ع",
    icon: ExitToAppIcon,
    component: "",
    layout: "",
    message: ""
  },
  {
    invisible: true,
    componentNumber:99,
    path: "/UserWithoutRol",
    name: 'norol',
    rtlName: "عالتسعير",
    rtlMini: "ع",
    component: UserWithoutRol,
    layout: "/admin",
    message: ""
  }
  
  
];
export default dashRoutes;
