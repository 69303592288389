import React from 'react'
import Button from "components/CustomButtons/Button.jsx"

import ZoomIn from "@material-ui/icons/ZoomIn"
import ZoomOut from "@material-ui/icons/ZoomOut"

function KWZoomButton(props) {

  KWZoomButton.defaultProps = {
    onClick: () => null,
    active: true,
    text: '',
  }

  //"#00acc1"
  if (props.active === true) {

    //Active & Selected
    return <Button
      style={{ color: '#FFFFFF', backgroundColor: '#0bb4e4', height: 35 }}
      size="sm"
      onClick={() => { props.onClick() }}
    >
      <div style={{ width: "100%", paddingLeft: 5 }}>
        {(props.text === '-' ? <ZoomOut /> : <ZoomIn />)}
      </div>
    </Button>

  }

  //Not Active
  return <Button
    style={{ color: '#A0A0A0', backgroundColor: '#FFFFFF', height: 35 }}
    size="sm"
    simple
    disabled
    onClick={() => { props.onClick() }}
  >
    <div style={{ width: "100%", paddingLeft: 5 }}>
      {(props.text === '-' ? <ZoomOut /> : <ZoomIn />)}
    </div>
  </Button>

}

export default KWZoomButton