/**
 * File to query backend
 *
 * Import:
 * import db from 'db/db.jsx'
 *
 */

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'


import utilities from 'utilities/utilities.jsx'

import moment from "moment"
moment.locale("es")

/**
* fillSectionStatus (exported)
*
* Fill global variable with status of section of admin panel
* 
* Use:
* db.fillSectionStatus()
*
*/
async function fillSectionStatus() {

  var direction = global.Direction_Back_panel + "/v2/sectionStatus"
  var headers = new Headers();
  var formData = new FormData();

  let _result = await petition(direction, headers, formData)

  if (_result !== null) {
    var result = JSON.parse(_result)

    global.validationInMaintenance = (result[0].validation === 1) ? true : false
    global.inicialValidationInMaintenance = (result[0].initialValidation === 1) ? true : false
    global.registryInMaintenance = (result[0].registry === 1) ? true : false
    global.enrollInMaintenance = (result[0].enroll === 1) ? true : false
    global.enrollIncompleteInMaintenance = (result[0].incompleteEnroll === 1) ? true : false

  }

}

/**
* getDashboardCards (exported)
* Return object with data for admin panel dashboard
*
* Use:
* db.getDashboardCards(dateStart, dateEnd)
*
* @param dateStart (Date) required - date from
* @param dateEnd (Date) required - date to
*
*/
async function getDashboardCards(
  dateStart,
  dateEnd,
) {

  var direction = global.Direction_Back_panel + "/dashboard/cardsV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);

  var formData = new FormData();

  formData.append("searchStartTimeStamp", Number(moment(dateStart).startOf('day').valueOf()))
  formData.append("searchEndTimeStamp", Number(moment(dateEnd).endOf('day').valueOf()))

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {
    return JSON.parse('{ "validations": [0, 0, 0, 0], "finalResultAuditory": [0, 0, 0, 0], "counters": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }')
  }

}

/**
* getDashboardTable (exported)
* Return object with data for admin panel dashboard
*
* Use:
* db.getDashboardTable(dateStart, dateEnd, pageNumber, card, order, column)
*
* @param dateStart (Date) required - date from
* @param dateEnd (Date) required - date to
* @param pageNumber (Integer) required - Page number of pagination
* @param card (Integer) required - Number of card selected
* @param order (String) required - "ASC" "DESC" for data order
* @param column (String) required - Column name for order
*
*/
async function getDashboardTable(
  dateStart,
  dateEnd,
  pageNumber,
  card,
  order,
  column
) {

  var direction = global.Direction_Back_panel + "/v2/dashboard/paginateDashboardV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  formData.append("searchStartTimeStamp", Number(moment(dateStart).startOf('day').valueOf()))
  formData.append("searchEndTimeStamp", Number(moment(dateEnd).endOf('day').valueOf()))
  formData.append("page", pageNumber)
  formData.append("card", card)
  formData.append("column", column)
  formData.append("order", order)

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {

    return JSON.parse('{"total": 0, "perPage": 20, "page": 1, "lastPage": 0, "data": []}')
  }

}

/**
* getDashboardGraphs (exported)
*
* Return object to fill admin panel dashboard graphs data
* 
* Use:
* db.getDashboardGraphs()
*
*/

/**
* getDashboardTableToExport (exported)
* Return object with dashboard table selected data for export
*
* Use:
* db.getDashboardTableToExport(dateStart, dateEnd)
*
* @param dateStart (Date) required - date from
* @param dateEnd (Date) required - date to
*
*/
async function getDashboardTableToExport(
  dateStart,
  dateEnd,
) {

  var direction = global.Direction_Back_panel + "/getDataForDashboardV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  formData.append("order", "DESC")
  formData.append("createdAtStart", Number(moment(dateStart).startOf('day').valueOf()))
  formData.append("createdAtEnd", Number(moment(dateEnd).endOf('day').valueOf()))

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)[0]
  } else {


    return []
  }

}

/**
* getDashboardTableToLink (exported)
* Return object with dashboard table selected data for export
*
* Use:
* db.getDashboardTableToLink(dateStart, dateEnd)
*
* @param dateStart (Date) required - date from
* @param dateEnd (Date) required - date to
* @param section (String) required - section to format xls
*
*/
async function getDashboardTableToLink(
  dateStart,
  dateEnd,
) {

  var direction = global.Direction_Back_panel + "/newGetValidationsDashboardV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  formData.append("order", "DESC")
  formData.append("createdAtStart", Number(moment(dateStart).startOf('day').valueOf()))
  formData.append("createdAtEnd", Number(moment(dateEnd).endOf('day').valueOf()))
  formData.append("section", "Dashboard")
  formData.append("tZone", localStorage.zonaHoraria)
  formData.append("token",localStorage.back_jwt)


  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"success":true,"link":""}')
  }

}

/**
* getInitialExamns (exported)
* Return object with initial examns data
*
* Use:
* db.getInitialExamns(validation, userName, createdAtStart, createdAtEnd, me, page, order, column)
*
* @param validation (String) required - Filter by examn validation
* @param userName (String) required - Filter by user name string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
* @param page (Integer) required - Page number of pagination
* @param order (String) required - "ASC" "DESC" for data order
* @param column (String) required - Column name for order
*
*/
async function getInitialExamns(
  validation,
  userName,
  createdAtStart,
  createdAtEnd,
  me,
  page,
  order,
  column
) {

  var direction = global.Direction_Back_panel + "/v2/validations/paginateValidationsV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }

  if (userName !== "") {
    formData.append("userName", userName.toUpperCase())
  }

  formData.append("createdAtStart", Number(moment(createdAtStart).startOf('day').valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))
  //formData.append("me", me)
  formData.append("page", page)
  formData.append("order", order)
  formData.append("column", column)

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"total": 0, "perPage": 20, "page": 1, "lastPage": 0, "data": []}')
  }

}

/**
* getInitialExamnsToExport (exported)
* 
*
* Use:
* db.getInitialExamnsToExport(validation, userName, createdAtStart, createdAtEnd, me)
*
* @param validation (String) required - Filter by examn validation
* @param userName (String) required - Filter by user name string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
*
*/
async function getInitialExamnsToExport(
  validation,
  userName,
  createdAtStart,
  createdAtEnd,
  me
) {

  var direction = global.Direction_Back_panel + "/dashboard/newGetValidationsExamV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }

  if (userName !== "") {
    formData.append("userName", userName.toUpperCase())
  }



  formData.append("createdAtStart", Number(moment(createdAtStart).startOf('day').valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))
  formData.append("tZone", localStorage.zonaHoraria)

  //formData.append("me", me)

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"total": 0, "perPage": 20, "page": 1, "lastPage": 0, "data": []}')
  }

}

/**
* getInitialExamnsToLink (exported)
* Return object with link to download.
*
* Use:
* db.getInitialExamnsToLink(validation, userName, createdAtStart, createdAtEnd, me)
*
* @param validation (String) required - Filter by examn validation
* @param userName (String) required - Filter by user name string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
*
*/
async function getInitialExamnsToLink(
  validation,
  userName,
  createdAtStart,
  createdAtEnd,
  me
) {

  var direction = global.Direction_Back_panel + "/v2/getValidationsV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData();

  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }

  if (userName !== "") {
    formData.append("userName", userName.toUpperCase())
  }

  formData.append("createdAtStart", Number(moment(createdAtStart).startOf('day').valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))
  formData.append("me", me)
  formData.append("section", 'InitialExamn')

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"success":true,"link":""}')
  }

}

/**
* getExamns (exported)
* Return object with examns data
*
* Use:
* db.getExamns(validation, searchExamType, userName, searchfile, searchSubject, createdAtStart, createdAtEnd, me, page, order, column)
*
* @param validation (String) required - Filter by examn validation
* @param searchExamType (String) required - Filter by examn type
* @param userName (String) required - Filter by user name string
* @param searchfile (String) required - Filter by user file
* @param searchSubject (String) required - Filter by subject string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
* @param page (Integer) required - Page number of pagination
* @param order (String) required - "ASC" "DESC" for data order
* @param column (String) required - Column name for order
*
*/
async function getExamns(
  validation,
  searchExamType,
  searchUserName,
  searchfile,
  searchSubject,
  createdAtStart,
  createdAtEnd,
  me,
  page,
  order,
  column,
  //dniNew
  searchdni,
  searchNickName,
  searchAudit,
  subjectCanvas,
  examnCanvas

) {

  // var direction = global.Direction_Back_panel + "/v2/validations/paginateValidationsV2"
  // var headers = new Headers()
  // var formData = new FormData()
  // headers.append("Authorization", "Bearer " + localStorage.jwt);
  var headers = new Headers()
  headers.append("Authorization", "Bearer " + localStorage.fb_jwt);
  var formData = new FormData()
  formData.append("token",localStorage.back_jwt)

  if(true){
    var direction = global.Direction_Back_panel + "/exam/list"

  }


  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }

  // if (searchExamType !== "Todos") {
  //   formData.append("examType", JSON.stringify(searchExamType))
  // }

  if (searchAudit !== "" && searchAudit !== "all") {
    formData.append("finalResultAuditory", JSON.stringify(searchAudit))
  }
  //dniNew
  if (searchdni && searchdni !== "") {
    formData.append("dni", searchdni)
  }

  if (searchUserName !== "") {
    formData.append("userName", searchUserName.toUpperCase())
  }
  //UserNew
  if (searchNickName && searchNickName !== "") {
    formData.append("user", searchNickName)
  }

  if (searchfile !== "") {
    formData.append("file", searchfile.toUpperCase())
  }


  if (global._lms_ === "4") {



    if (subjectCanvas) {
      if (subjectCanvas.title) {
        // formData.append("subjectName", JSON.stringify(searchSubject))
        formData.append("subjectName", JSON.stringify([subjectCanvas.title]))

      }

    }
    if (examnCanvas && examnCanvas !== "") {
      formData.append("examId", JSON.stringify([examnCanvas.exam_id + ""]))
    }
  }
  else {
    if (searchSubject !== "") {
      formData.append("subjectName", JSON.stringify(searchSubject))
    }
    if (searchExamType !== "Todos") {
      formData.append("examType", JSON.stringify(searchExamType))
    }
  }

  formData.append("createdAtStart", Number(moment(createdAtStart).valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))
  //formData.append("me", me)
  formData.append("page", page)
  formData.append("order", order)
  formData.append("column", column)


  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"total": 0, "perPage": 20, "page": 1, "lastPage": 0, "data": []}')
  }

}
}

/**
* getExamnsToExport (exported)
* Return object with examns data to export.
*
* Use:
* db.getExamnsToExport(validation, searchExamType, userName, searchfile, searchSubject, createdAtStart, createdAtEnd, me)
*
* @param validation (String) required - Filter by examn validation
* @param searchExamType (String) required - Filter by examn type
* @param userName (String) required - Filter by user name string
* @param searchfile (String) required - Filter by user file
* @param searchSubject (String) required - Filter by subject string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
*
*/
async function getExamnsToExport(
  validation,
  searchExamType,
  userName,
  searchfile,
  searchSubject,
  createdAtStart,
  createdAtEnd,
  me
) {

  var direction = global.Direction_Back_panel + "/dashboard/getValidationsV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData()

  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }

  if (searchExamType !== "Todos") {
    formData.append("examType", JSON.stringify(searchExamType))
  }

  if (userName !== "") {
    formData.append("userName", userName.toUpperCase())
  }



  if (searchfile !== "") {
    formData.append("file", searchfile.toUpperCase())
  }

  if (searchSubject !== "") {
    formData.append("searchSubject", searchSubject.toUpperCase())
  }

  formData.append("createdAtStart", Number(moment(createdAtStart).valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))

  //formData.append("me", me)

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"total": 0, "perPage": 20, "page": 1, "lastPage": 0, "data": []}')
  }

}

/**
* getExamnsToLink (exported)
* Return object with link to download.
*
* Use:
* db.getExamnsToLink(validation, searchExamType, userName, searchfile, searchSubject, createdAtStart, createdAtEnd, me)
*
* @param validation (String) required - Filter by examn validation
* @param searchExamType (String) required - Filter by examn type
* @param userName (String) required - Filter by user name string
* @param searchfile (String) required - Filter by user file
* @param searchSubject (String) required - Filter by subject string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
*
*/



/**
* getExamnsToLink (exported)
* Return object with link to download.
*
* Use:
* db.getExamnsToLink(validation, searchExamType, userName, searchfile, searchSubject, createdAtStart, createdAtEnd, me)
*
* @param validation (String) required - Filter by examn validation
* @param searchExamType (String) required - Filter by examn type
* @param userName (String) required - Filter by user name string
* @param searchfile (String) required - Filter by user file
* @param searchSubject (String) required - Filter by subject string
* @param createdAtStart (Date) required - date from
* @param createdAtEnd (Date) required - date to
* @param me (Integer) required - 1 or 0 for initial examns or real examns
*
*/
async function getExamnsToLink(
  validation,
  searchExamType,
  searchUserName,
  searchfile,
  searchSubject,
  createdAtStart,
  createdAtEnd,
  me,
  page,
  order,
  column,
  //dniNew
  searchdni,
  searchNickName,
  searchAudit,
  subjectCanvas,
  examnCanvas

) {

  var direction = global.Direction_Back_panel + "/newGetValidationsExamV2"
  var headers = new Headers()
 
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);

  var formData = new FormData()

  if (validation !== "Todas") {
    formData.append("ve", utilities.getValidationDbInteger(validation))
  }
  if(global._lms_==='4'){
    formData.append("token",localStorage.back_jwt)
  }
  // if (searchExamType !== "Todos") {
  //   formData.append("examType", JSON.stringify(searchExamType))
  // }

  if (searchAudit !== "" && searchAudit !== "all") {
    formData.append("finalResultAuditory", JSON.stringify(searchAudit))
  }
  //dniNew
  if (searchdni && searchdni !== "") {
    formData.append("dni", searchdni)
  }

  if (searchUserName !== "") {
    formData.append("userName", searchUserName.toUpperCase())
  }
  //UserNew
  if (searchNickName && searchNickName !== "") {
    formData.append("user", searchNickName)
  }

  if (searchfile !== "") {
    formData.append("file", searchfile.toUpperCase())
  }

  // if (searchSubject !== "") {
  //   formData.append("subjectName", JSON.stringify(searchSubject))
  // }

  if (global._lms_ === "4") {

    if (subjectCanvas) {
      if (subjectCanvas.title) {
        // formData.append("subjectName", JSON.stringify(searchSubject))
        formData.append("subjectName", JSON.stringify([subjectCanvas.title]))

      }

    }
    if (examnCanvas && examnCanvas !== "") {
      formData.append("examId", JSON.stringify([examnCanvas.exam_id + ""]))
    }
  }
  else {
    if (searchSubject !== "") {
      formData.append("subjectName", JSON.stringify(searchSubject))
    }
    if (searchExamType !== "Todos") {
      formData.append("examType", JSON.stringify(searchExamType))
    }
  }

  formData.append("createdAtStart", Number(moment(createdAtStart).valueOf()))
  formData.append("createdAtEnd", Number(moment(createdAtEnd).endOf('day').valueOf()))
  //formData.append("me", me)
  formData.append("page", page)
  formData.append("order", order)
  formData.append("column", column)


  formData.append("section", 'Examn')
  formData.append("tZone", localStorage.zonaHoraria)

  let _result = await petition(direction, headers, formData)
  if (_result) {
    _result = JSON.parse(_result)
    if (_result.message && _result.message === "Unauthorized") {
      global.showNoPermission()
      _result = null

    }
    else {
      _result = JSON.stringify(_result)
    }

  }
  if (_result !== null) {
    return JSON.parse(_result)
  } else {


    return JSON.parse('{"success":true,"link":""}')
  }

}

/**
* updateValidationExamns (exported)
* Update examn audition data
*
* Use:
* db.updateValidationExamns(keyProctoring, finalResultAuditory, finalResultAuditoryDetail, startOfAudit)
*
* @param keyProctoring (String) required - Examn key to modify
* @param finalResultAuditory (Integer) required - Result key of examn
* @param finalResultAuditoryDetail (String) required - Auditor detail writen
* @param startOfAudit (Date) required - Start time from audit
*
*/
async function updateValidationExamns(
  keyProctoring,
  finalResultAuditory,
  finalResultAuditoryDetail,
  startOfAudit
) {

  var direction = global.Direction_Back_panel + "/updateValueNewV2"
  let headers = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  headers.append("Authorization", "Bearer " + _token);
  var formData = new FormData()

  formData.append("keyProctoring", keyProctoring);
  formData.append("finalResultAuditory", finalResultAuditory);
  formData.append("finalResultAuditoryDetail", finalResultAuditoryDetail);
  formData.append("startOfAudit", startOfAudit);

  formData.append("auditorUser", JSON.parse(localStorage.USER).name);
  formData.append("endOfAudit", Date.now());

  //  JSON.parse(await petition(direction, headers, formData))

  let requestOptions = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }


  let _res = await fetch(direction, requestOptions)
    .then((response) => {
      return response.json()

      if (response.status === 200) {
        return response.text()
      } else {
        throw new Error(response.text())
      }
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log(error)

      return null
    })

    
  if(_res.message && _res.message==="Unauthorized"){
    global.showNoPermission()
  }
  return _res

}

/**
* petition (private)
* Use for petition to backend
*
* Use:
* petition(direction, headers, formData)
*
* @param direction (String) required - direction of petition
* @param headers (Object) required - Headers of petition
* @param formData (Object) required - Parameter of petition
*
*/
async function petition(direction, headers, formData) {

  let requestOptions = {
    method: 'POST',
    headers: headers,
    body: formData,
    redirect: 'follow'
  }


  return await fetch(direction, requestOptions)
    .then((response) => {
      if (response.status === 200) {
        return response.text()
      } else {
        throw new Error(response.status)
      }
    })
    .then(result => {
      return result
    })
    .catch(error => {
      console.log(error)

      return null
    })
}

async function getRols(cb1, cb2) {
  let myHeaders = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  myHeaders.append("Authorization", "Bearer " + _token);
  var formData = new FormData()

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'

  };

  fetch(global.Direction_Back_panel + "/rol/allRols", requestOptions)
    .then(response => response.text())
    .then(result => {
      let _res = JSON.parse(result)
      if (_res.message && _res.message === 'Unauthorized') {
        global.showNoPermission()

        cb2()
        return true
      }
      cb1(_res)

    })
    .catch(error => console.log('error', error));
}
async function getUsers(cb1, cb2) {

  let myHeaders = new Headers();
  let _token = localStorage.getItem('fb_jwt')
  myHeaders.append("Authorization", "Bearer " + _token);

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(global.Direction_Back_panel + "/users/getUser", requestOptions)
    .then(response => response.text())
    .then(result => {

      let _res = JSON.parse(result)
      if (_res.message && _res.message === 'Unauthorized') {
        global.showNoPermission()

        cb2()
        return true
      }
      cb1(_res.users)

    })
    .catch(error => console.log('error', error));
}
export default {
  fillSectionStatus,
  getDashboardCards,
  getDashboardTable,
  getDashboardTableToExport,
  getDashboardTableToLink,
  getInitialExamns,
  getInitialExamnsToExport,
  getInitialExamnsToLink,
  getExamns,
  getExamnsToExport,
  getExamnsToLink,
  updateValidationExamns,
  getRols,
  getUsers
}
