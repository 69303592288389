import React from 'react'

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";


function BivSwitch(props) {

  const {text, disable, value, onChange} = props

  BivSwitch.defaultProps = {
    onChange: () => null,
    text: '-',
    value: true,
    disable: false,
  }

  return (
      <FormControlLabel
        style={{ color: "#67707c", marginLeft: 5 }}
        control={
          <Switch
            color="primary"
            disabled={disable}
            checked={value}
            onChange={onChange}
          />
        }
        label={text}
      />
  )
 
}

export default BivSwitch