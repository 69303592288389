import React from 'react'

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

function BivDashboardCardIcon(props) {

    const { loading, title, value, description, icon, color, clickFunction, classes} = props

    return (

        <Card style={{ cursor: 'pointer' }} onClick={clickFunction}>
            <CardHeader color={color} stats icon >
                <CardIcon color={color} style={{ padding: 0, width: 70, height: 66, display: "flex", justifyContent: "center", alignItems: "center" }} >
                    {icon}
                </CardIcon>
                <p className={classes.cardCategory}>{title}</p>
                <h3 className={classes.cardTitle}>{(loading === true) ? "-" : value}</h3>
            </CardHeader>
            <CardFooter stats>
                <div className={classes.stats}>
                    {description}
                </div>
            </CardFooter>
        </Card>    
    )

}

export default BivDashboardCardIcon