import React from "react";
import BivSwitch from "components/BivSwitch/BivSwitch.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Collapse from "@material-ui/core/Collapse";

import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Typography from "views/Components/Typography";

import Button from "components/CustomButtons/Button.jsx";
// import { TRUE } from "node-sass";

const languages = require("../../../assets/translate/translate_proctoringControl.json");
let lang = localStorage.lang ? localStorage.lang : "es";

class TabRegistry extends React.Component {
  //const {values, onChange} = props

  constructor(props) {
    super(props);
    this.state = {
      processListFilterText: "",
      vcListFilterText: "",
      processNewItemDialog: false,
      virtualCamerasNewItemDialog: false,
      processListShow: false,

      changeLow: false,
      changeMedium: false,
      changeHigh: false,
      changeVeryHigh: false,
      valuePriority: this.props && this.props.values && this.props.values.fr && this.props.values.fr.co?this.props.values.fr.co:0
    };
    this.setValuePriority=(x)=>{
      this.setState({valuePriority:x})
      this.props.values.fr.co = x
      
      this.props.onChange(this.props.values)
  
    }
  }


  //TabEnvironment.defaultProps = {
  //onChange: () => null,
  //values: {bl: true, cs: true, pc: true, vm: true},
  //}
  //componentDidMount(){
  //let processListShow = this.state
  //processListShow = false
  //}

  // percentValue(veString) {
  //   switch (veString) {
  //     case "BAJO":
  //       return 25;
  //     case "MEDIO":
  //       return 35;
  //     case "ALTO":
  //       return 45;
  //     case "SUPERIOR":
  //       return 55;
  //     default:
  //       return -1;
  //   }
  // }

  render() {
    return (
      <GridContainer sm={12} style={{ display: "flex" }}>
        {/* <GridItem sm={12}>
          <BivSwitch
            sm={12}
            text="Coincidencia de datos biometricos"
            value={true}
            onChange={() => {
              if (!global.readOnlyUser) {
                // this.props.values.bl = !this.props.values.bl;
                // this.props.onChange(this.props.values);
              }
            }}
          />
          <IconButton
            disabled={false}
            onClick={() => {
              this.setState({ virtualListShow: !this.state.virtualListShow });
            }}
            //aria-expanded={this.state.expanded == {nameExpand}}
            //aria-expanded={"processExpand"}
            aria-label="show more"
          >
            {this.props.values.vc ? (
              this.state.virtualListShow ? (
                <ExpandLess />
              ) : (
                <ExpandMoreIcon />
              )
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        </GridItem> */}

        <GridItem sm={12}>
          {/* <Collapse
            in={this.state.virtualListShow}
            timeout="auto"
            unmountOnExit
          > */}
            <GridContainer style={{ display: "grid" }}>
              {/* <Collapse in={this.props.values.vc} timeout="auto" unmountOnExit> */}
                <GridItem sm={12} style={{ display: "flex" }}>
                  <GridItem
                    sm={5}
                    style={{ justifyContent: "left", alignItems: "left" }}
                  >
                    <h5>Nivel de rigurosidad para el reconocimiento facial:</h5>
                  </GridItem>

                  <GridItem sm={5}>
                   
                      <Button
                        color="success"
                        size="sm"
                        simple={!this.state.valuePriority || this.state.valuePriority !== 0.27}
                        round
                        onClick={() => {
                          this.setState({
                            changeLow: true,
                            changeMedium: false,
                            changeHigh: false,
                            changeVeryHigh: false,
                          });
                          this.setValuePriority(0.27)

                        }}
                      >
                        BAJO
                      </Button>
                   
                    
                      <Button
                        color="yellowBiv"
                        size="sm"
                        simple={!this.state.valuePriority || this.state.valuePriority !== 0.35}
                        round
                        onClick={() => {
                          this.setState({
                            changeMedium: true,
                            changeLow: false,
                            changeHigh: false,
                            changeVeryHigh: false,
                          });
                          this.setValuePriority(0.35)

                        }}
                      >
                        MEDIO
                      </Button>

                  
                      <Button
                        color="orangeBiv"
                        size="sm"
                        simple={!this.state.valuePriority || this.state.valuePriority !== 0.43}
                        round
                        onClick={() => {
                          this.setState({
                            changeHigh: true,
                            changeLow: false,
                            changeMedium: false,
                            changeVeryHigh: false,
                          });
                          this.setValuePriority(0.43)

                        }}
                      >
                        ALTO
                      </Button>
                    
                  </GridItem>
                </GridItem>
              {/* </Collapse> */}
            </GridContainer>
          {/* </Collapse> */}
        </GridItem>
      </GridContainer>
    );
  }
}

export default TabRegistry;
