import React from 'react'
import Button from "components/CustomButtons/Button.jsx";

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivButtonMedium(props) {

  //const {active, onClick} = props

  BivButtonMedium.defaultProps = {
    onClick: () => null,
    active: false,
  }

  if (props.active === true) {
    return <Button
      color="yellowBiv"
      size="sm"
      simple
      round
      style={{ color: '#f1e00f' }}
      onClick={() => {props.onClick()}}
      >{languages[lang].button_medium}</Button>
  }

  return <Button
    color="yellowBiv"
    size="sm"
    round
    onClick={() => {props.onClick()}}
    >{languages[lang].button_medium}</Button>

}

export default BivButtonMedium