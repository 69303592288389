import React from 'react'

import * as firebase from 'firebase';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import NavPills from "components/NavPills/NavPills.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Switch from "@material-ui/core/Switch";


import CircularProgress from '@material-ui/core/CircularProgress';

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import _ from 'lodash';
import { Prompt } from 'react-router-dom';

import ActionInformation from "./ActionInformation/ActionInformationFn.jsx";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TabRegistry from './TabRegistry/TabRegistry.jsx';


const languages = require('../../assets/translate/translate_proctoringControl.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#00BFFF",
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#00BFFF",
  },
}));


class BivConfigurationRegistry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      change: false,
      confOrig: [],
      conf: [],
      viewActions: false,
      activeUsers: [],
    }
  }
  componentWillUnmount() {
    clearInterval(this.intervalActivity)
  }
  componentDidMount() {

    const userdata = JSON.parse(localStorage.rol)
    //let loading = this.state
    //loading = true
    // TODO : add common/ when morin upload new backend
    this.intervalActivity = setInterval(() => {
      var myHeaders = new Headers();
      let _token = localStorage.getItem("fb_jwt");
      myHeaders.append("Authorization", "Bearer " + _token);
      var formdata = new FormData();
      formdata.append("type", "B");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(global.Direction_Back_panel + "/panel/activeUserConfig", requestOptions)
        .then((response) => response.text())
        .then((result) => {
        })
        .catch((error) => console.log("error", error));
    }, 20000)
    const processUsers = (users) => {
      if (users) {
        const nowTime = Date.now()
        for (let i in users) {
          let user = users[i]
          if (user.time < nowTime - 60000 || userdata.authorities.id == i) {
            delete users[i]
          }
        }

        this.setState({ activeUsers: Object.values(users) })
      }
    }

    firebase.database().ref("/activeUsers/configurationsRegistry").on('value', (snapshot) => {
      processUsers(snapshot.val())
    })

    let route = (((global.common_config)) ? 'common/' : '') + 'configurationsRegistry';
    firebase.database().ref(route).once('value', (x) => {
      if (x.val()) {

        let _confOrig = JSON.parse(JSON.stringify(x.val()))
        let _conf = JSON.parse(JSON.stringify(x.val()))
       
        this.setState({
          loading: false,
          confOrig: _confOrig,
          conf: _conf,
        })

        //console.log('configurationsB')
        //console.log(this.state.conf)

      } else {
       
        //TODO: mostrar error
      }
    })
  }

  checkChanges() {

    if (!global.readOnlyUser) {
      let _change = _.isEqual(this.state.confOrig, this.state.conf)
      this.setState({ change: !_change })
    }

  }

  guardarCambios() {
    //this.state.confOrig = JSON.parse(JSON.stringify(this.state.conf))
    this.setState({ confOrig: JSON.parse(JSON.stringify(this.state.conf)) }, () => {
      var myHeaders = new Headers();
      let _token = localStorage.getItem('fb_jwt')
      myHeaders.append("Authorization", "Bearer " + _token);
      var formdata = new FormData();
      formdata.append("type", "Registry");
      formdata.append("config", JSON.stringify(this.state.conf));

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(global.Direction_Back_panel + "/panel/config", requestOptions)
        .then(response => response.text())
        .then(result => {
          this.setState({ change: false })
        })
        .catch(error => console.log('error', error));

      // firebase.database().ref('configurationsB').update(this.state.conf).then(() => {
      // })
    })
  }

  resetConfiguration() {
    //this.state.conf = JSON.parse(JSON.stringify(this.state.confOrig))
    this.setState({ conf: JSON.parse(JSON.stringify(this.state.confOrig)), change: false })
  }



  render() {

    return (

      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden' }}>
        <Prompt
          when={this.state.change}
          message={location =>
            languages[lang].proctoringC_areYSYWTLTSWSTChanges
          }
        />
        {this.state.loading ?

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 150, zIndex: 99 }}>
            <CircularProgress />
          </div>

          :
          <GridContainer >

            {/* Switch Environment */}
            <GridItem xs={6}>
              <h3 style={{ fontWeight: "bold", display: "inline-block" }}>{"Ajustes de registro"}</h3>
             

            </GridItem>

            {/*Boton Historial*/}
            <GridItem xs={6} style={{ justifyContent: "flex-end", display: "flex", flexDirection: "column", alignItems: "end",position:"relative" }}>
              <Button
                color="success"
                style={{
                  marginRight: 10,
                  color: "#FFF",
                  backgroundColor: "#0bb4e4",
                }}
                onClick={() => {
                  this.setState({ viewActions: true });
                }}
              >
                <HistoryIcon />
                HISTORIAL
              </Button>
              {this.state.activeUsers.length > 0 ? <div style={{ maxWidth: 270, display: "flex", paddingTop: "15px",position:"absolute",top:'60px' }}>

                <Paper className="divActiveUsers" elevation={3} style={{ maxWidth: 250, padding: 10, alignContent: "flex-start", alignItems: "flex-start",maxHeight:"300px",overflow:"scroll",zIndex:99 }} >
                  <Typography noWrap={true} variant="subtitle2" gutterBottom style={{ paddingBottom: '5px' }}>Usuarios activos <Tooltip placement="top" title="Lista de usuarios editando en este momento."><InfoOutlinedIcon style={{ color: "#ff9800" }} /></Tooltip></Typography>

                  {this.state.activeUsers.map((a, b) => (
                    <Typography noWrap={true} variant="body1" gutterBottom key={b}>{a.email}</Typography>
                  ))}

                </Paper>

              </div> : null}

              <Modal
                open={this.state.viewActions}
                onClose={() => {
                  this.setState({ viewActions: false });
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  style={{
                    position: "absolute",
                    right: "0%",
                    // transform: 'translate(-50%, -50%)',
                    width: "50%",
                    height: "100%",
                    backgroundColor: "#f8f9fa",
                    // boxShadow: 24,
                    p: 4,
                  }}
                >
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ viewActions: false });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                  <ActionInformation
                    field={this.state.activeTab}

                    searchStartDate={this.state.searchStartDate}
                    changeStartDate={(value) => {
                      this.setState({
                        searchStartDate: value,
                      });
                    }}
                    searchEndDate={this.state.searchEndDate}
                    changeEndDate={(value) => {
                      this.setState({
                        searchEndDate: value,
                      });
                    }}
                  ></ActionInformation>
                </Box>
              </Modal>
            </GridItem>


          

            {/* Menu */}
            <GridItem xs={12}>
              {true ?
                <NavPills
                  color="darkVioletBiv"

                  tabs={[
                    {
                      tabButton: "Registro",
                    tabContent: (
                      <TabRegistry
                          values={this.state.conf}
                          onChange={(values) => {
                            //this.state.conf = values
                            //this.checkChanges()
                            if (!global.readOnlyUser) {
                              this.setState({ conf: values }, this.checkChanges)
                            }

                          }}
                          checkChanges={() => {
                            this.checkChanges()
                          }}
                        />
                    )
                    }
                  ]}
                />
                :
                <div style={{ height: 300, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <h3>{languages[lang].proctoringC_enviromentControlDisabled}</h3>
                  <h4>{languages[lang].proctoringC_toAccesTSYMAIt}</h4>
                </div>
              }
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "end", height: 30, position: "fixed", bottom: 30, right: 5 }}>
              {global.readOnlyUser ? (
                <div />
              ) : (
                <div>

                  <Button
                    disabled={!this.state.change}
                    onClick={() => {
                      this.guardarCambios();
                    }}
                    style={{
                      marginRight: 10,
                      color: "#FFF",
                      backgroundColor: "#0bb4e4",
                      borderColor: "#0bb4e4",
                    }}
                  >
                    {languages[lang].proctoringC_save}
                  </Button>

                  <Button
                    disabled={!this.state.change}
                    style={{
                      marginRight: 10,
                      color: "#0bb4e4",
                      backgroundColor: "#FFF",
                      borderColor: "#0bb4e4",
                    }}
                    onClick={() => {
                      this.resetConfiguration();
                    }}
                  >
                    {languages[lang].proctoringC_cancel}
                  </Button>
                </div>
              )}
            </GridItem>

           

          </GridContainer>

        }
      </div>
    )

  }

}

export default withStyles(sweetAlertStyle)(BivConfigurationRegistry)
