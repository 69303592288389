import React from 'react'

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";




const languages = require('../../assets/translate/translate_registros.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivResultCount(props) {

  const {
    resultArray,
  } = props

  BivResultCount.defaultProps = {
    resultArray: []
  }

  const _count = (resultArray !== null) ? (Number(resultArray.length).toString() === 'NaN') ? "0" : Number(resultArray.length).toString() : "0" 

  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}
        style={{ display: "flex", direction: "column", justifyContent: 'top', textAlign: 'left', marginTop: 5, marginBottom: 5, marginLeft: 30, marginRight: 30 }}
        >
        <div style={{marginRight: 8}}>{languages[lang].registros_result}Cantidad de Registros: </div><div style={{fontWeight: "bold"}}> { _count } </div>
      </GridItem>
    </GridContainer>
  )

}

export default BivResultCount

