import React from 'react'
import Button from "components/CustomButtons/Button.jsx"

import SaveAlt from "@material-ui/icons/SaveAlt"

import BivExportCsvFunction from "components/BivExportCsvFunction/BivExportCsvFunction.jsx"

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivExportCsvButton(props) {

  BivExportCsvButton.defaultProps = {
    active: false,
    csvData: [],
    fileName: '',
    customFunction: false,
    onClickCustom: () => null,
  }
  
  return (
    <Button
      disabled={!props.active}
      color="lightBlueKW"
      round
      style={{width:'100%'}}
      endIcon={<SaveAlt />}
      onClick={() => {
        if (props.active) {
          if(props.customFunction) {
            props.onClickCustom()
          } else {
            BivExportCsvFunction(props.csvData, props.fileName)
          }
        }
      }}
    >
      <div style={{ width: "100%", textAlign: "center",}}>
      {languages[lang].button_export}
      </div>
    </Button>
  )

}

export default BivExportCsvButton