import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import db from "db/db";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Collapse, TextField, IconButton, Paper, TableBody, TableCell, TableHead, TableRow, Typography, Input, Icon } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import CardBody from "components/Card/CardBody";
import Add from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Search from "@material-ui/icons/Search";
import { Prompt } from "react-router-dom";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'


class UsersManager extends React.Component {

  constructor(props) {
    super(props);
    this._admin = JSON.parse(localStorage.rol).admin
    this.defaultValues = {
      activeSave: false,
      editRowEmail: false,
      saved: false,
      deleted: false,
      deleteUserModal: false,
      textToDelete: null,
      discardChanges: false,
      showSearch: false, errorBack: false,
      errorBackText: ''
    }
    this.userList = []
    this.rolList = {}
    this.rolList2 = {}
    this.loaded = {
      users: false,
      rols: false
    }
    this.defaultValues1 = {
      ...this.defaultValues,
      newUserEmail: null,
      newUser: false,
      activeRow: null,
      saved: false
    }
    this.state = {
      ...this.defaultValues1,
      rows: [],
      order: 'asc'
    }
  }


  sortIcon(order) {

    if (!order) {
      return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    }
    else if (order === 'asc') {
      return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    }
    else if (order === 'desc') {
      return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
    }

  }

  sortFunctionRol(order) {
    order = order === 'asc' ? 'desc' : 'asc'
    console.log(this.state.rows, order)
    let rows = JSON.parse(JSON.stringify(this.state.rows))

    var sortRoles = rows.sort(function (a, b) {
      if (order === 'asc') {
        return a.rolName.toLowerCase() < b.rolName.toLowerCase() ? 1 : -1
      }
      return a.rolName.toLowerCase() > b.rolName.toLowerCase() ? 1 : -1
    })

    this.setState({
      rows: sortRoles,
      //   orderChange: true,
      order: order,
      //   loading: true,
    })
  }

  sortFunctionEmail(order) {
    order = order === 'asc' ? 'desc' : 'asc'
    console.log(this.state.rows, order)
    let rows = JSON.parse(JSON.stringify(this.state.rows))

    var sortRoles = rows.sort(function (a, b) {
      if (order === 'asc') {
        return a.email.toLowerCase() < b.email.toLowerCase() ? 1 : -1
      }
      return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
    })

    this.setState({
      rows: sortRoles,
      //   orderChange: true,
      order: order,
      //   loading: true,
    })
  }


  createData = () => {
    if (this.loaded.users && this.loaded.rols) {
      let newData = []
      for (let i in this.userList) {
        let elem = this.userList[i]
        let data = { "email": elem.email, 'uid': elem.id, "rolName": elem.rol_name, rid: elem.rol_id }
        if (!elem.rol_id) {
          data.rid = 0
          data.rolName = ''
        }
        newData.push(data)
      }
      this.allrows = newData
      this.setState({ 'rows': newData })
    }
  }
  componentDidMount() {
    if (!this._admin) {
      this.loaded.rols = true
      this.createData()
      this.getUsers()

      return true
    }
    db.getRols((_res) => {
      let newData = {}
      for (let i in _res) {
        let elem = _res[i]
        newData[elem.id] = elem
      }
      this.rolList = newData
      this.rolList2 = _res
      this.loaded.rols = true
      this.createData()
    }, () => {

    })
    this.getUsers()
  }
  getUsers() {
    db.getUsers((_res) => {
      this.userList = _res
      this.loaded.users = true

      this.createData()
    }, () => {

    })
  }

  setNull() {
    this.activeUserOriginal = {}
    this.newUserEmail = null
    this.newPassword = null
    this.setState({ ...this.defaultValues1, deleted: false })
  }
  discardChanges() {
    let y = this.secondparam
    let _x = JSON.parse(JSON.stringify(this.firstparam))
    this.activeUserOriginal = JSON.parse(JSON.stringify(_x))

    if (y) {
      this.setState({ activeRow: _x, ...this.defaultValues, newUser: y, editRowEmail: true, })
    }
    else {
      this.setState({ activeRow: _x, ...this.defaultValues, newUser: false })
    }

  }
  selectUser(x, y) {
    if (this.eltimeout) {
      clearTimeout(this.eltimeout)
    }
    this.firstparam = x
    this.secondparam = y
    if ((this.state.newUser || this.state.deleteUserModal || this.state.activeSave) && !this.state.errorBack) {
      this.setState({ discardChanges: true })
    }
    else {
      this.discardChanges()
    }

  }

  saveUser() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let _token = localStorage.getItem('fb_jwt')
    myHeaders.append("Authorization", "Bearer " + _token);
    let user = JSON.parse(JSON.stringify(this.state.activeRow))
    let raw
    if (this.state.newUser) {
      raw = JSON.stringify(
        {
          email: this.newUserEmail,
          // password: this.newPassword,
          rolID: user.rid
        }
      );
    }
    else {
      raw = JSON.stringify({
        "rolID": user.rid,
        "userID": user.uid
      });
    }


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };


    if (this.state.newUser) {
      fetch(global.Direction_Back_panel + "/users/createUser", requestOptions)
        .then(response => response.text())
        .then(result => {
          let _res = JSON.parse(result)
          if (_res.message && _res.message === 'Unauthorized') {
            global.showNoPermission()
            this.setNull()
            return true
          }
          else if (!_res.success) {

            this.showError(_res)

            return true
          }
          if (this.eltimeout) {
            clearTimeout(this.eltimeout)
          }
          setTimeout(() => {
            this.setNull()
          }, 2500)
          this.setState({ saved: true })
          this.getUsers()
        })
        .catch(error => console.log('error', error));
    }
    else {

      fetch(global.Direction_Back_panel + "/rol/assingRol", requestOptions)
        .then(response => response.text())
        .then(result => {
          let _res = JSON.parse(result)
          if (_res.message && _res.message === 'Unauthorized') {
            global.showNoPermission()
            this.setNull()

            return true
          }
          if (!_res.success && _res.message === "Can't modify this user, the system needs an admin account.") {
            global.showNoPermission('No se puede editar este usuario, el sistema necesita una cuenta de administrador.')
            this.setNull()
            return true
          }
          if (!_res.success && _res.message === "You can't modify user @klarway") {
            global.showNoPermission('No se pueden modificar los usuarios @klarway.')
            this.setNull()
            return true
          }
          if (this.eltimeout) {
            clearTimeout(this.eltimeout)
          }
          setTimeout(() => {
            this.setNull()
          }, 2500)
          this.setState({ saved: true })
          this.getUsers()
        })
        .catch(error => console.log('error', error));
    }
  }
  showError(x) {
    let text = ''
    switch (x.code) {
      case 2:
        text = "Ya existe un usuario con ese email."
        break;
      case 1:
        text = "Acción restringida, intente con otro email."

        break;
      case 3:
        text = "Ocurrió un error inesperado, intente nuevamente más tarde."

        break;
      default:
        text = "Ocurrió un error inesperado, intente nuevamente más tarde."

        break;
    }
    console.log(text)
    this.setState({ errorBack: true, errorBackText: text })

  }
  deleteUser() {
    this.setState({ deleteUserModal: false })
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let _token = localStorage.getItem('fb_jwt')
    myHeaders.append("Authorization", "Bearer " + _token);
    let raw = JSON.stringify({
      "id": this.activeUserOriginal.uid
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.Direction_Back_panel + "/users/deleteUser", requestOptions)
      .then(response => response.text())
      .then(result => {
        let _res = JSON.parse(result)
        if (_res.message && _res.message === 'Unauthorized') {
          global.showNoPermission()
          this.setNull()
          return true
        }
        else if (_res.message && _res.message === "Can't delete this user, the system needs an admin account.") {
          global.showNoPermission('No se puede eliminar este usuario, el sistema necesita una cuenta de administrador.')
          this.setNull()
          return true
        }
        else if (_res.success) {
          this.setState({ deleted: true })
        }
        // this.setState({ deleteUserModal: false })

        if (this.eltimeout) {
          clearTimeout(this.eltimeout)
        }
        setTimeout(() => {
          this.setNull()
        }, 2500)
        this.getUsers()

      })
      .catch(error => console.log('error', error));
  }
  filterUsers(text) {
    let users
    if (text.length > 0) {
      users = this.allrows.filter(a => (a.email + ' ' + a.rolName).includes(text))
    } else {
      users = this.allrows
    }
    this.setState({ rows: users })
  }
  render() {
    return (<div>

      <Prompt
        when={this.state.newUser || this.state.deleteUserModal || this.state.activeSave}
        message={location =>
          '¿Está seguro de que desea salir de esta sección sin guardar los cambios?'
        }
      />
      <Modal
        open={this.state.discardChanges && !this.state.errorBack}
        onClose={() => {
          this.setState({ discardChanges: false })
        }}

        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper elevation={2} style={{
          position: 'absolute',
          width: 400,
          // border: '2px solid #000',
          borderRadius: 15,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          left: '50%',
          boxShadow: 24,
          overflow: 'hidden'
        }}>
          <div style={{ backgroundColor: '#ff8f00', padding: '32px 16px 8px 16px', }}>
            <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}>Se modificó el usuario y no se guardaron los cambios.</h3>

            <p style={{ color: 'white' }} id="simple-modal-description">
              ¿Desea descartar los cambios?
            </p>
          </div>
          <div style={{ padding: '16px 16px 17px 16px', }}>

            <div style={{ display: 'flex', justifyContent: 'end', padding: '16px 0px 0px 0px' }}>
              <Button onClick={() => {
                this.setState({ discardChanges: false })
              }} >Cancelar</Button>
              <Button onClick={() => { this.discardChanges() }} >Descartar</Button>
            </div>
            {/* <Input placeholder={this.activeRolOriginal.name} inputProps={{ 'aria-label': 'description' }} /> */}
          </div>

        </Paper>
      </Modal>
      <Modal
        open={this.state.deleteUserModal}
        onClose={() => {
          this.setState({ deleteUserModal: false })
        }}

        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper elevation={2} style={{
          position: 'absolute',
          width: 400,
          // border: '2px solid #000',
          borderRadius: 15,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          left: '50%',
          boxShadow: 24,
          overflow: 'hidden'
        }}>
          <div style={{ backgroundColor: '#ff5252', padding: '32px 16px 8px 16px', }}>
            <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}>Borrar usuario</h3>

            <p style={{ color: 'white' }} id="simple-modal-description">
              Eliminar un usuario es una acción permanente.
            </p>
          </div>
          <div style={{ padding: '16px 16px 17px 16px', }}>

            <div style={{ display: 'flex', justifyContent: 'end', padding: '0px 0px 0px 0px' }}>
              <Button onClick={() => {
                this.setState({ deleteUserModal: false })
              }} >Cancelar</Button>
              <Button onClick={() => { this.deleteUser() }} >Borrar</Button>
            </div>
            {/* <Input placeholder={this.activeRolOriginal.name} inputProps={{ 'aria-label': 'description' }} /> */}
          </div>

        </Paper>
      </Modal>
      <GridContainer style={{ paddingRight: 0 }}>
        <GridItem sm={12} lg={this._admin ? 7 : 12} >
          <Paper elevation={1} style={{ marginBottom: 10 }} >
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 5 }}>
                <Typography color="textPrimary" variant="h5" component="p">
                  Usuarios
                </Typography>
                <div>
                  <IconButton aria-label="add" onClick={() => {
                    this.setState({ showSearch: !this.state.showSearch })
                  }} style={{}}>
                    <Search />
                  </IconButton>
                  {this._admin ? <IconButton aria-label="add" onClick={() => {
                    this.selectUser({ "email": '', 'uid': null, rid: 0, rolName: '' }, 1)
                  }} style={{}}>
                    <Add />
                  </IconButton>
                    : null}
                </div>

              </div>
              {this.state.showSearch ?
                <FormControl fullWidth style={{ paddingBottom: 20, }} >
                  {/* <InputLabel htmlFor="standard-adornment-amount">Buscar</InputLabel> */}
                  <Input
                    id="standard-adornment-amount"
                    //  value={values.amount}
                    onChange={(ev) => this.filterUsers(ev.target.value)}
                    startAdornment={<Search />}
                  />
                </FormControl>
                : null}
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Email <IconButton onClick={() => { this.sortFunctionEmail(this.state.order) }} aria-label="order"  >
                      {this.sortIcon(this.state.order)}
                    </IconButton></TableCell>
                    {!this._admin?
                    <TableCell align="right">&nbsp;</TableCell>
 :null}
                    <TableCell align="right">Rol <IconButton onClick={() => { this.sortFunctionRol(this.state.order) }} aria-label="order"  >
                      {this.sortIcon(this.state.order)}
                    </IconButton></TableCell>
                    {this._admin?
                    <TableCell align="right">&nbsp;</TableCell>
 :null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.rows ? this.state.rows.map((row) => (
                      <TableRow
                      style={{cursor:"pointer"}}
                      onClick={() => {
                        this.selectUser(row)
                      }} key={row.uid}>
                        <TableCell component="th" scope="row">
                          {row.email}
                        </TableCell>
                        {!this._admin?
                    <TableCell align="right"><Icon edge="end" aria-label="editar">
                    </Icon></TableCell>
 :null}
                        <TableCell align="right">{row.rolName}</TableCell>
                        {this._admin?
                        <TableCell align="right">
                          <IconButton onClick={() => {
                            // this.selectUser(row)
                          }} edge="end" aria-label="editar">
                            <EditIcon></EditIcon>
                          </IconButton>
                        </TableCell>
                        :null}
                      </TableRow>
                    ))
                      : null
                  }
                </TableBody>
              </Table>
            </CardBody>
          </Paper>
        </GridItem>
        {this._admin ?
          <GridItem sm={12} lg={5}>
            <Collapse in={this.state.saved}>
              <Alert
                style={{ marginBottom: 10 }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      // setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                El usuario se {this.state.newUser ? 'creó' : 'modificó'} correctamente
              </Alert>
            </Collapse>
            <Collapse in={this.state.deleted}>
              <Alert
                style={{ marginBottom: 10 }}
                severity="success" color="info"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      // setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                El usuario se eliminó correctamente
              </Alert>
            </Collapse>
            <Collapse in={this.state.errorBack}>
              <Alert
                style={{ marginBottom: 10 }}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ errorBack: false })
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {this.state.errorBackText}
              </Alert>
            </Collapse>
            <Paper elevation={1} >
              <CardBody >

                {!this.state.activeRow ?
                  <Typography variant="h5" color="textSecondary" component="h3">
                    Seleccione un usuario para ver o editar su información
                  </Typography>
                  :
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 5 }}>
                      <Typography variant="h5" color="textPrimary" component="h2">
                        Email
                      </Typography>
                      <div>
                        {
                          !this.state.newUser ?
                            <IconButton onClick={() => {
                              this.setState({ deleteUserModal: true })
                            }}>
                              <DeleteIcon />
                            </IconButton>
                            : null
                        }
                        <div>
                        </div>
                      </div>
                    </div>



                    {this.state.newUser ?
                      <ListItem
                        style={{ paddingLeft: 0 }}

                      >
                        <TextField style={{ width: '100%' }} variant="filled" placeholder="Email" value={this.state.newUserEmail} defaultValue={this.state.activeRow.email} onChange={(event) => {
                          this.setState({ randNumber: Math.random() })
                          this.setState({ newUserEmail: event.target.value.split(' ').join('') })
                          this.newUserEmail = event.target.value.split(' ').join('')
                        }} inputProps={{ 'aria-label': 'description' }} />
                      </ListItem>
                      :
                      <ListItem>
                        <ListItemText
                          primary={this.state.activeRow.email}
                        />
                      </ListItem>
                    }
                    {/* {
                    this.state.newUser ?
                      <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 5 }}>
                        <Typography variant="h5" color="textPrimary" component="h2">
                          Contraseña
                        </Typography>

                      </div>
                      : null
                  }
                  {
                    this.state.newUser ?
                      <FormControl variant="filled" style={{ width: '100%', marginBottom: 10 }} >
                        <TextField onChange={(ev) => {
                          this.setState({ randNumber: Math.random() })

                          this.newPassword = ev.target.value
                        }} id="filled-basic" label="" variant="filled" />
                      </FormControl>
                      : null
                  } */}
                    <Typography variant="h5" color="textPrimary" component="h2">
                      Rol
                    </Typography>
                    <FormControl variant="filled" style={{ width: '100%' }} >
                      {/* <InputLabel>Rol</InputLabel> */}
                      <Select
                        // labelId="demo-simple-select-autowidth-label"
                        // id="demo-simple-select-autowidth"
                        value={this.state.activeRow.rid}
                        onChange={(event) => {
                          let value = event.target.value
                          let row = this.state.activeRow
                          let selectedRol = this.rolList[value]
                          row.rid = value
                          if (selectedRol) {
                            row.rolName = selectedRol.name
                          }
                          let activeSave = (this.activeUserOriginal.rid + '' !== row.rid + '') && row.rid
                          this.setState({ activeRow: row, activeSave: activeSave })
                        }}

                      >
                        <MenuItem value={0}>
                          <em>&nbsp;</em>
                        </MenuItem>
                        {this.rolList2.map((a, b) => (
                          <MenuItem key={b} value={a.id}>{a.name}</MenuItem>
                        ))}
                      </Select>
                      {this.state.newUser ?
                        <div style={{ paddingTop: 10 }}><Typography variant="caption" display="block" gutterBottom>* La contraseña llegará al email ingresado.</Typography></div> : null}
                    </FormControl>
                    <div style={{ textAlign: "right", paddingTo: 10 }} >
                      <Button onClick={() => {
                        this.setNull()
                      }} >Cancelar</Button>
                      <Button onClick={() => { this.saveUser() }} disabled={(!this.state.newUser && !this.state.activeSave) || (this.state.newUser && (!this.newUserEmail || !this.state.activeRow.rid))}>Guardar</Button>
                    </div>

                  </div>

                }

              </CardBody >
            </Paper>
          </GridItem>
          : null}
      </GridContainer>
    </div>
    )
  }

}

export default withStyles(sweetAlertStyle)(UsersManager)
