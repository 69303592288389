import React from 'react'
import Button from "components/CustomButtons/Button.jsx"
import Search from "@material-ui/icons/Search"

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivButtonFind(props) {

  BivButtonFind.defaultProps = {
    onClick: () => null,
  }

  return <Button
    color="greenBiv"
    style={{backgroundColor: "#20DFB1",width:'100%'}}
    round
    onClick={() => {props.onClick()}}
    endIcon={<Search />}
  >

    <div style={{ width: "100%", textAlign: "center" }}>
    {languages[lang].button_find}
    </div>

  </Button>

}

export default BivButtonFind