/**
 * File to serve all function for sort, order and format tables
 *
 * Import:
 * import db from 'utilities/utilTable.jsx'
 *
 */

import React from "react"

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

import Block from "@material-ui/icons/Block"
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline"

import BivPrioritySentiment from "components/BivPrioritySentiment/BivPrioritySentiment.jsx"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import moment from "moment"
import { Tooltip } from "@material-ui/core"
moment.locale("es")

function orderByDate(a, b, order) {
  if (order === 'asc') {
    return Number(moment(a, 'DD/MM/YYYY').format('x')) - Number(moment(b, 'DD/MM/YYYY').format('x'))
  } else if (order === 'desc') {
    return Number(moment(b, 'DD/MM/YYYY').format('x')) - Number(moment(a, 'DD/MM/YYYY').format('x'))
  }
}

function sortIcon(order, column) {
  if (!order) return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
  else if (order === 'asc') return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
  else if (order === 'desc') return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
  return null
}

function formatStyleValidation(cell, row, rowIndex, colIndex) {
  let _case = row.validation
  // if (row.recovery > 0 && (!row.faceVideo || !row.desktopVideo)) {
  //   _case = 'errorrecovery'
  // }
  // if (row.recovery === 0 && (!row.faceVideo || !row.desktopVideo)) {
  //   _case = 0
  // }
  switch (_case) {
    case 1:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#66bb6a', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 2:
      return { paddingTop: "2%", paddingBottom: '2%', color: 'rgb(241, 224, 15)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 3:
      return { color: '#ffa726', paddingTop: "2%", paddingBottom: '2%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 4:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#ef5350', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 0:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#2196f3', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 5:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#a3a3a3', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    case 6:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#2196f3', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    // case 'errorrecovery':
      // return { paddingTop: "2%", paddingBottom: '2%', color: '#a3a3a3', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
    default:
      return { paddingTop: "2%", paddingBottom: '2%', color: '#2196f3', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
  }

}

function formatStyleIcon(cell, row, rowIndex, colIndex) {

  //return { paddingTop: "2%", paddingBottom: '2%', color: '#66bb6a', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
  //return { paddingTop: "2%", paddingBottom: '2%', color: 'rgb(241, 224, 15)', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
  //return { color: '#ffa726', paddingTop: "2%", paddingBottom: '2%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }
  return { paddingTop: "2%", paddingBottom: '2%', color: '#ef5350', justifyContent: 'center', alignItems: 'center', textAlign: 'center', verticalAlign: 'middle' }
}

function tooltip (cell, row, rowIndex, colIndex){return(
  <Tooltip title={row.subjectName} >
        <span style={{ textOverflow: "ellipsis", maxWidth: "130px", overflow: "hidden", whiteSpace: "nowrap" }}>{row.subjectName}</span>
      </Tooltip>
  )}

function formatIconValidation(cell, row, rowIndex, colIndex) {
  return <BivPrioritySentiment value={row.validation} recovery={row.recovery} videos={row.faceVideo || row.desktopVideo} />
}

function formatIconDesktopVideo(cell, row, rowIndex, colIndex) {
  return <div style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
    {(row.desktopVideo === '' || row.desktopVideo === null) ? <Block style={{ color: "#999" }} /> : <CheckCircleOutline style={{ color: "#6ab97e" }} />}
  </div>
}

function formatIconFaceVideo(cell, row, rowIndex, colIndex) {
  return <div style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
    {(row.faceVideo === '' || row.faceVideo === null) ? <Block style={{ color: "#999" }} /> : <CheckCircleOutline style={{ color: "#6ab97e" }} />}
  </div>
}

function formatIconInitialExamnSucess(cell, row, rowIndex, colIndex) {
  return <div style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
    {(row.me === 0) ? <Block style={{ color: "#999" }} /> : <CheckCircleOutline style={{ color: "#6ab97e" }} />}
  </div>
}
function formatIconVerifiedUser(cell, row, rowIndex, colIndex) {
  return <div style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
    {(row.ve === 2) ? <CancelOutlinedIcon style={{ color: "#FF1506" }} /> : (row.ve === 1) ? <CheckCircleOutline style={{ color: "#6ab97e" }} /> : <Block style={{ color: "#999" }} />}
  </div>
}
function formatTextEnrollState(cell, row, rowIndex, colIndex) {

  return <div style={{ width: "100%", display: 'flex', alignItems: "center", justifyContent: "center" }}>
    {(row.res === 'En espera') ? <p style={{ color: `#fd7e14` }}>{row.res}</p> : <p style={{}}>{row.res}</p>}
  </div>
}
function formatStyleResultAuditory(cell, row, rowIndex, colIndex) {

  switch (cell) {
    case 'Validado':
      return { backgroundColor: "#4ea852", paddingTop: 27, color: "#fff", fontSize: 18, fontWeight: 400, textAlign: "center" }
    case 'Con solic. de sanción':
      return { backgroundColor: "rgb(241, 224, 15)", paddingTop: 27, color: "#fff", fontSize: 18, fontWeight: 400, textAlign: "center" }
    case 'Aplazado':
      return { backgroundColor: "rgb(255, 152, 0)", paddingTop: 27, color: "#fff", fontSize: 18, fontWeight: 400, textAlign: "center" }
    case 'Llamado de atención':
      return { backgroundColor: "rgb(255, 152, 0)", paddingTop: 27, color: "#fff", fontSize: 18, fontWeight: 400, textAlign: "center" }
    default:
      return { backgroundColor: "#4ea852", paddingTop: 27, color: "#fff", fontSize: 18, fontWeight: 400, textAlign: "center" }
  }

}

function styleHeaderIncidences() {
  return { marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 }
}

function styleHeaderIncidencesCenter() {
  return { marginTop: 0, marginBottom: 0, paddingTop: 5, paddingBottom: 5 }
}

export default {
  orderByDate,
  sortIcon,
  formatStyleIcon,
  formatStyleValidation,
  formatIconValidation,
  formatIconDesktopVideo,
  formatIconFaceVideo,
  formatIconInitialExamnSucess,
  formatStyleResultAuditory,
  styleHeaderIncidences,
  formatIconVerifiedUser,
  formatTextEnrollState,
  styleHeaderIncidencesCenter,
  tooltip,
}
