import React from 'react'

import moment from 'moment'
import GridItem from "components/Grid/GridItem.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Problem from '@material-ui/icons/ReportProblem'
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
//import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import TextField from '@material-ui/core/TextField'

moment.locale('es')

const languages = require('../../assets/translate/translate_validationVideo&infoPC&auditResult.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function KWValidationFormAuditResult(props) {



  KWValidationFormAuditResult.defaultProps = {
    examType: "",
    showLoading: () => null,
    data: [],
    auditResult: 1,
    auditResultChange: () => null,
    auditTextChange: () => null,
    saveAuditResult: () => null,
  }
    // Declaración de una variable de estado que llamaremos "count"
    const [count, setCount] = React.useState(0);
    const rol=global.rol.authorities.permission

    const {
      examType,
      showLoading,
      data,
      _data,
      auditResult,
      auditResultChange,
      auditTextChange,
      saveAuditResult,

      _data1
      //classes,
    } = props


    return (

      data.keyproctoring && data.enroll.ve === 1 ? 

      <GridContainer style={{ marginTop: 0, paddingLeft: 0 }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
          {(examType === "Complete") ?

            (data.finalResultAuditory !== -1) ?
              <div>
                <h4 style={{ marginTop: 0, marginLeft: 10 }}>{languages[lang].KWValidationFormAuditResult_auditResult}</h4>
                <div>
                  <h5 style={{ marginTop: 0, color: "#797979", fontSize: 14, marginLeft: 15 }}>{(data.finalResultAuditory === 1) ? languages[lang].KWValidationFormAuditResult_validExam : (data.finalResultAuditory === 2) ? languages[lang].KWValidationFormAuditResult_deferredExam : (data.finalResultAuditory === 0) ? languages[lang].KWValidationFormAuditResult_examinationWithRFSanction : languages[lang].KWValidationFormAuditResult_callOfAttention} </h5>
                  <div style={{ maxHeight: 190, overflow: "auto", marginLeft: 15 }}>
                    <h4 style={{ fontSize: 14 }}>{languages[lang].KWValidationFormAuditResult_detail}</h4>
                    <h4 style={{ fontSize: 14, color: "#797979" }}>{data.finalResultAuditoryDetail ? (data.finalResultAuditoryDetail !== 'null')  ? data.finalResultAuditoryDetail : languages[lang].KWValidationFormAuditResult_doesNotContainDetail : languages[lang].KWValidationFormAuditResult_doesNotContainDetail}</h4>
                  </div>
                  <div style={{ display: "flex", marginLeft: 15 }}>
                    <h4 style={{ fontSize: 14, marginRight: 5 }}>{languages[lang].KWValidationFormAuditResult_auditDuration}</h4>
                    <h4 style={{ fontSize: 14, color: "#797979" }}>{data.auditTime ? data.auditTime + " hs" : "-"}</h4>
                  </div>
                </div>
              </div>
              :
              (global.readOnlyUser) ?

                <div
                  style={{
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }
                  }>
                  <Problem
                    style={{ fontSize: 50, marginBottom: 10 }}
                  />
                  <div style={{ fontSize: 19 }}>
                    {languages[lang].KWValidationFormAuditResult_youANATAudit}
                  </div>
                </div>
                :
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <div>
                    <h4 style={{ marginTop: 0, marginLeft: 10 }}>{languages[lang].KWValidationFormAuditResult_auditResult}</h4>

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser}
                      control={
                        <Radio
                          checked={auditResult === 1}
                          onChange={() => {
                            auditResultChange(1)
                          }}
                          value="1"
                          aria-label="1"
                          style={{ color: auditResult === 1 ? '#6ab97e' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />
                      }

                      label={languages[lang].KWValidationFormAuditResult_validExam}
                    />

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser}
                      control={
                        <Radio
                          checked={auditResult === 2}
                          onChange={() => {
                            auditResultChange(2)
                          }}
                          value="2"
                          aria-label="2"
                          style={{ color: auditResult === 2 ? '#ffbe5f' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />
                      }

                      label={languages[lang].KWValidationFormAuditResult_deferredExam}
                    />

                    {/* Llamado de atención. NEWVALIDATION
                  <FormControlLabel
                    style={{ margin: 0 }}
                    disabled={global.readOnlyUser}
                    control={
                      <Radio
                        checked={auditResult === 3}
                        onChange={() => {
                          auditResultChange(3)
                        }}
                        value="3"
                        aria-label="3"
                        style={{ color: auditResult === 3 ? '#ffbe5f' : "#999" }}
                      //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      //checkedIcon={
                      //<FiberManualRecord className={classes.radioChecked} />
                      //}
                      />
                    }
                    label="Llamado de atención."
                  />
                  */}

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser}
                      control={
                        <Radio

                          checked={auditResult === 0}
                          onChange={() => {
                            auditResultChange(0)
                          }}
                          value="0"
                          aria-label="0"
                          style={{ color: auditResult === 0 ? '#f55451' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />


                      }

                      label={languages[lang].KWValidationFormAuditResult_examinationWithRFSanction}
                    />
                  </div>

                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                    <TextField
                      style={{ width: "100%", marginLeft: 0 }}
                      id="outlined-multiline-static"
                      disabled={global.readOnlyUser}
                      label={languages[lang].KWValidationFormAuditResult_detail1}
                      multiline
                      //   onInput = {(e) =>{
                      //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                      // }}
                      inputProps={{
                        maxLength: 800,
                      }}
                      rows={2}
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      onChange={(event) => {
                        //seSelecciono()
                        setCount(event.target.value.length)
                        auditTextChange(event.target.value)
                        
                      }}
                    />
                    <p style={{ marginRigth: 50,textAlign:'right'}} color="#bdbdbd" > {count}/800 </p>
                  </GridItem>

                  <div style={{ textAlign: 'end' }}>
                    {data.far ?
                      <div></div>
                      :
                      <Button style={{ marginRight: 10 }} disabled={global.readOnlyUser || (+auditResult !== 0 && +auditResult!==1  && +auditResult!==2)} color="greenBiv" size='sm' onClick={() => {

                        if (!global.readOnlyUser) {
                          showLoading(true)
                          saveAuditResult()
                        }

                      }} round>{languages[lang].KWValidationFormAuditResult_save}</Button>
                    }

                  </div>
                </div>

            :

            <div
              style={{
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }
              }>
              <div style={{ fontSize: 19 }}>
                {languages[lang].KWValidationFormAuditResult_initialExam}
              </div>
              <div style={{ color: 'grey', fontSize: 19 }}>
                {languages[lang].KWValidationFormAuditResult_noAuditRequired}
              </div>
            </div>
          }

        </GridItem>

      </GridContainer>

      :
      //desde aca es si esta en ve = 2

      <GridContainer style={{ marginTop: 0, paddingLeft: 0 }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} >
          {(examType === "Complete") ?

            (_data.finalResultAuditory !== -1) ?
              <div>
                <h4 style={{ marginTop: 0, marginLeft: 10 }}>{languages[lang].KWValidationFormAuditResult_auditResult}</h4>
                <div>
                  <h5 style={{ marginTop: 0, color: "#797979", fontSize: 14, marginLeft: 15 }}>{(_data.finalResultAuditory === 1) ? languages[lang].KWValidationFormAuditResult_validExam : (_data.finalResultAuditory === 2) ? languages[lang].KWValidationFormAuditResult_deferredExam : (_data.finalResultAuditory === 0) ? languages[lang].KWValidationFormAuditResult_examinationWithRFSanction : languages[lang].KWValidationFormAuditResult_callOfAttention} </h5>
                  <div style={{ maxHeight: 190, overflow: "auto", marginLeft: 15 }}>
                    <h4 style={{ fontSize: 14 }}>{languages[lang].KWValidationFormAuditResult_detail}</h4>
                    <h4 style={{ fontSize: 14, color: "#797979" }}>{_data.finalResultAuditoryDetail ? (_data.finalResultAuditoryDetail !== 'null')  ? _data.finalResultAuditoryDetail : languages[lang].KWValidationFormAuditResult_doesNotContainDetail : languages[lang].KWValidationFormAuditResult_doesNotContainDetail}</h4>
                  </div>
                  <div style={{ display: "flex", marginLeft: 15 }}>
                    <h4 style={{ fontSize: 14, marginRight: 5 }}>{languages[lang].KWValidationFormAuditResult_auditDuration}</h4>
                    <h4 style={{ fontSize: 14, color: "#797979" }}>{_data.auditTime ? _data.auditTime + " hs" : "-"}</h4>
                  </div>
                </div>
              </div>
              :
              (global.readOnlyUser) ?

                <div
                  style={{
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }
                  }>
                  <Problem
                    style={{ fontSize: 50, marginBottom: 10 }}
                  />
                  <div style={{ fontSize: 19 }}>
                    {languages[lang].KWValidationFormAuditResult_youANATAudit}
                  </div>
                </div>
                :
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <div>
                    <h4 style={{ marginTop: 0, marginLeft: 10 }}>{languages[lang].KWValidationFormAuditResult_auditResult}</h4>

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser || !rol.editExamns }
                      control={
                        <Radio
                          checked={auditResult === 1}
                          onChange={() => {
                            auditResultChange(1)
                          }}
                          value="1"
                          aria-label="1"
                          style={{ color: auditResult === 1 ? '#6ab97e' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />
                      }

                      label={languages[lang].KWValidationFormAuditResult_validExam}
                    />

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser || !rol.editExamns}
                      control={
                        <Radio
                          checked={auditResult === 2}
                          onChange={() => {
                            auditResultChange(2)
                          }}
                          value="2"
                          aria-label="2"
                          style={{ color: auditResult === 2 ? '#ffbe5f' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />
                      }

                      label={languages[lang].KWValidationFormAuditResult_deferredExam}
                    />

                    {/* Llamado de atención. NEWVALIDATION
                  <FormControlLabel
                    style={{ margin: 0 }}
                    disabled={global.readOnlyUser}
                    control={
                      <Radio
                        checked={auditResult === 3}
                        onChange={() => {
                          auditResultChange(3)
                        }}
                        value="3"
                        aria-label="3"
                        style={{ color: auditResult === 3 ? '#ffbe5f' : "#999" }}
                      //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                      //checkedIcon={
                      //<FiberManualRecord className={classes.radioChecked} />
                      //}
                      />
                    }
                    label="Llamado de atención."
                  />
                  */}

                    <FormControlLabel
                      style={{ margin: 0 }}
                      disabled={global.readOnlyUser || !rol.editExamns}
                      control={
                        <Radio

                          checked={auditResult === 0}
                          onChange={() => {
                            auditResultChange(0)
                          }}
                          value="0"
                          aria-label="0"
                          style={{ color: auditResult === 0 ? '#f55451' : "#999" }}
                        //icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        //checkedIcon={
                        //<FiberManualRecord className={classes.radioChecked} />
                        //}

                        />


                      }

                      label={languages[lang].KWValidationFormAuditResult_examinationWithRFSanction}
                    />
                  </div>

                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                    <TextField
                      style={{ width: "100%", marginLeft: 0 }}
                      id="outlined-multiline-static"
                      disabled={global.readOnlyUser || !rol.editExamns}
                      label={languages[lang].KWValidationFormAuditResult_detail1}
                      multiline
                      //   onInput = {(e) =>{
                      //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                      // }}
                      inputProps={{
                        maxLength: 800,
                      }}
                      rows={2}
                      defaultValue=""
                      variant="outlined"
                      size="small"
                      onChange={(event) => {
                        //seSelecciono()
                        setCount(event.target.value.length)
                        auditTextChange(event.target.value)
                        
                      }}
                    />
                    <p style={{ marginRigth: 50,textAlign:'right'}} color="#bdbdbd" > {count}/800 </p>
                  </GridItem>

                  {/* <div style={{color:'#f55451', marginLeft: 12}}>
                    {!data.enroll.ve  ? <b>*Registro no verificado, valídalo para poder guardar la auditoria.</b> : null}
                    {data.enroll.ve && data.enroll.ve !== 1 ? <b>*Registro rechazado, valídalo para poder guardar la auditoria.</b> : null}
                  
                  </div> */}

                  <div style={{ textAlign: 'end' }}>
                    {_data.far ?
                      <div></div>
                      :
                      <Button style={{ marginRight: 10 }} disabled={global.readOnlyUser || (auditResult+'' !== '0' && auditResult+''!=='1'  && auditResult+''!=='2') || !rol.editExamns} color="greenBiv" size='sm' onClick={() => {

                        if (!global.readOnlyUser ) {
                          showLoading(true)
                          saveAuditResult()
                        }

                      }} round>{languages[lang].KWValidationFormAuditResult_save}</Button>
                    }

                  </div>
                </div>

            :

            <div
              style={{
                height: 250,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
              }
              }>
              <div style={{ fontSize: 19 }}>
                {languages[lang].KWValidationFormAuditResult_initialExam}
              </div>
              <div style={{ color: 'grey', fontSize: 19 }}>
                {languages[lang].KWValidationFormAuditResult_noAuditRequired}
              </div>
            </div>
          }

        </GridItem>

      </GridContainer>
    )

  }

  export default KWValidationFormAuditResult