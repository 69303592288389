import React from 'react'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';




const languages = require('../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivTableAVR(props) {

  const {
    values,
    onChange,
  } = props

  BivTableAVR.defaultProps = {
    values: { bs: true, br: 1, bd: 1, ms: true, mr: 1, md: 1, as: true, ar: 1, ad: 1, cs: true, cr: 1, cd: 1 },
    onChange: () => null,
  }

  const afterSaveCell = (oldValue, newValue, row, column) => {
    

    switch (row.key) {
      case 1:
        onChange({
          bs: row.ra === languages[lang].proctoringC_actived ? true : false,
          br: row.audit === languages[lang].proctoringC_approved ? 1 : row.audit === languages[lang].proctoringC_disapproved ? 2 : row.audit === languages[lang].proctoringC_callOAttention ? 3 : 0,
          bd: row.detail,
          ms: values.ms, mr: values.mr, md: values.md,
          as: values.as, ar: values.ar, ad: values.ad,
          cs: values.cs, cr: values.cr, cd: values.cd
        })
        break;
      case 2:
        onChange({
          bs: values.bs, br: values.br, bd: values.bd,
          ms: row.ra === languages[lang].proctoringC_actived ? true : false,
          mr: row.audit === languages[lang].proctoringC_approved ? 1 : row.audit === languages[lang].proctoringC_disapproved ? 2 : row.audit === languages[lang].proctoringC_callOAttention ? 3 : 0,
          md: row.detail,
          as: values.as, ar: values.ar, ad: values.ad,
          cs: values.cs, cr: values.cr, cd: values.cd
        })
        break;
      case 3:
        onChange({
          bs: values.bs, br: values.br, bd: values.bd,
          ms: values.ms, mr: values.mr, md: values.md,
          as: row.ra === languages[lang].proctoringC_actived ? true : false,
          ar: row.audit === languages[lang].proctoringC_approved ? 1 : row.audit === languages[lang].proctoringC_disapproved ? 2 : row.audit === languages[lang].proctoringC_callOAttention ? 3 : 0,
          ad: row.detail,
          cs: values.cs, cr: values.cr, cd: values.cd
        })
        break;
      case 4:
        onChange({
          bs: values.bs, br: values.br, bd: values.bd,
          ms: values.ms, mr: values.mr, md: values.md,
          as: values.as, ar: values.ar, ad: values.ad,
          cs: row.ra === languages[lang].proctoringC_actived ? true : false,
          cr: row.audit === languages[lang].proctoringC_approved ? 1 : row.audit === languages[lang].proctoringC_disapproved ? 2 : row.audit === languages[lang].proctoringC_callOAttention ? 3 : 0,
          cd: row.detail
        })
        break;
      default:
        onChange(values)
        break;
    }

  }

  const valuesToTable = (x) => {
    //ve: "Baja", Ra: "Desactivada", audit: "Aprobado", Detail:
    return ([{
      key: 1,
      ve: languages[lang].proctoringC_low,
      ra: x.bs === true ? languages[lang].proctoringC_actived : languages[lang].proctoringC_desactived ,
      audit: x.br === 1 ? languages[lang].proctoringC_approved : x.br === 2 ? languages[lang].proctoringC_disapproved : x.br === 4 ? languages[lang].proctoringC_callOAttention : languages[lang].proctoringC_withRFSanction,
      detail: x.bd,
    }, {
      key: 2,
      ve: languages[lang].proctoringC_medium,
      ra: x.ms === true ? languages[lang].proctoringC_actived : languages[lang].proctoringC_desactived ,
      audit: x.mr === 1 ? languages[lang].proctoringC_approved : x.mr === 2 ? languages[lang].proctoringC_disapproved : x.mr === 4 ? languages[lang].proctoringC_callOAttention : languages[lang].proctoringC_withRFSanction,
      detail: x.md,
    }, {
      key: 3,
      ve: languages[lang].proctoringC_high,
      ra: x.as === true ? languages[lang].proctoringC_actived  : languages[lang].proctoringC_desactived ,
      audit: x.ar === 1 ? languages[lang].proctoringC_approved : x.ar === 2 ? languages[lang].proctoringC_disapproved : x.ar === 4 ? languages[lang].proctoringC_callOAttention : languages[lang].proctoringC_withRFSanction,
      detail: x.ad,
    }, {
      key: 4,
      ve: languages[lang].proctoringC_critic,
      ra: x.cs === true ? languages[lang].proctoringC_actived : languages[lang].proctoringC_desactived,
      audit: x.cr === 1 ? languages[lang].proctoringC_approved : x.cr === 2 ? languages[lang].proctoringC_disapproved : x.cr === 4 ? languages[lang].proctoringC_callOAttention : languages[lang].proctoringC_withRFSanction,
      detail: x.cd,
    }])
  }

  const columns = [
    {
      dataField: "key",
      text: "key",
      editable: false,
      hidden: true
    },
    {
      dataField: "ve",
      text: languages[lang].proctoringC_examValidation1,
      editable: false,
      headerStyle: { width: "170px" },
    },
    {
      dataField: "ra",
      text: languages[lang].proctoringC_automaticResolution,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return [{
            value: "Activada",
            label: "Activada"
          }, {
            value: "Desactivada",
            label: "Desactivada"
          }];
        }
      }
    },
    {
      dataField: "audit",
      text: languages[lang].proctoringC_examStatus,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return [{
            value: "Aprobado",
            label: languages[lang].proctoringC_approved
          }, {
            value: "Desaprobado",
            label: languages[lang].proctoringC_disapproved
          },/* {
            value: "Llamado de atención",
            label: "Llamado de atención"
          }, */{
            value: "Con solicitud de sanción",
            label: languages[lang].proctoringC_withRFSanction
          }];
        }
      }
    },
    {
      dataField: "detail",
      text: languages[lang].proctoringC_detail,
      editable: true
    }
  ]

  const columnsReadOnly = [
    {
      dataField: "key",
      text: "key",
      editable: false,
      hidden: true
    },
    {
      dataField: "ve",
      text: languages[lang].proctoringC_examValidation1,
      editable: false,
      headerStyle: { width: "170px" },
    },
    {
      dataField: "ra",
      text: languages[lang].proctoringC_automaticResolution,
    },
    {
      dataField: "audit",
      text: languages[lang].proctoringC_examStatus,
    },
    {
      dataField: "detail",
      text: languages[lang].proctoringC_detail,
      editable: false
    }
  ]

  return (

    <div>
      {(global.readOnlyUser) ?
        <BootstrapTable
          keyField="key"
          data={valuesToTable(values)}
          columns={(global.readOnlyUser) ? columnsReadOnly : columns}
        />
        :
        <BootstrapTable
          bootstrap4
          keyField="key"
          data={valuesToTable(values)}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell
          })}
        />
      }


    </div>
  )

}

export default BivTableAVR