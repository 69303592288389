import React from 'react'

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

function BivDashboardCard(props) {

    const { loading, title, value, description, color, clickFunction, classes} = props

    return (

        <Card color={color} style={{ cursor: 'pointer' }} onClick={clickFunction}>
            <CardHeader stats >
                <p className={classes.cardCategory} style={{fontSize: '0.9rem', color: 'white', fontWeight: 'bold' }}>{title}</p>
                <h3 className={classes.cardTitle} style={{ paddingTop: "20px", color: 'white', fontWeight: 'bold' }}>{(loading === true) ? "-" : value}</h3>
            </CardHeader>
            <CardFooter stats>
                <div className={classes.stats} style={{fontSize: '0.7rem', color: 'white' }}>
                    {description}
                </div>
            </CardFooter>
        </Card>    
    )

}

export default BivDashboardCard