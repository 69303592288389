import React from 'react'

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import BivSwitch from "components/BivSwitch/BivSwitch.jsx";

import BivNumberInput from "components/BivNumberInput/BivNumberInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import noUiSlider from "nouislider";
import wNumb from 'wnumb';




const languages = require('../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

class BivLimitRanges extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {

    var cambiarLimites = (x) => {
      
      this.props.sliderOnChange(x)

    }

    var classes = ['#4caf50', 'rgb(241, 224, 15)', '#ff9800', '#f44336', '#fff']

    var miSlider = document.getElementById(this.props.sliderName)

    if (global.readOnlyUser) {
      miSlider.setAttribute('disabled', true)
    } else {
      miSlider.removeAttribute('disabled');
    }

    noUiSlider.create(miSlider, {
      start: [this.props.sliderValues[0], this.props.sliderValues[1], this.props.sliderValues[2]],
      connect: [true, true, true, true],
      tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
      step: 1,
      range: {
        min: this.props.fromValue ? this.props.fromValue : 0,
        max: this.props.toValue ? this.props.toValue === this.props.fromValue ? this.props.toValue + 1 : this.props.toValue : this.props.fromValue + 1
      }
    }).on('change.one', function (x) {
      cambiarLimites(x)
    })

    var connect = document.getElementById(this.props.sliderName).querySelectorAll('.noUi-connect')

    for (var i = 0; i < connect.length; i++) {
      connect[i].style.backgroundColor = classes[i]
    }
  }

  componentDidUpdate(){
    //console.log('componentDidUpdate')

    let _min = this.props.fromValue ? this.props.fromValue : 0
    let _max = this.props.toValue ? this.props.toValue === _min ? this.props.toValue + 1 : this.props.toValue : _min + 1

    var miSlider = document.getElementById(this.props.sliderName)
    if (miSlider.noUiSlider) {
      miSlider.noUiSlider.updateOptions({
        range: {
          'min': _min,
          'max': _max,
        },
        start: [this.props.sliderValues[0], this.props.sliderValues[1], this.props.sliderValues[2]]
      });
    }

  }

  render() {

    return (

      <span>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <h4 style={{ fontWeight: 400, fontSize: 16 }}>{this.props.title}</h4>
          <FormControlLabel
            style={{ color: "#67707c", margin: 0, marginLeft: 35 }}
            control={
              <BivSwitch
                text={''}
                value={this.props.switchActive}
                onChange={() => {
                  if (!global.readOnlyUser) {
                    this.props.switchOnChange()
                  }
                }}
              />
            }
            label={languages[lang].proctoringC_limit}
            labelPlacement="start"
          />
        </div>

        {
          (this.props.switchActive === true || global.readOnlyUser === true) ?
            <div>
              {this.props.limited === 1 ?

                <GridContainer style={{ alignItems: 'baseline', marginLeft: 5 }}>

                  <GridItem >
                    <FormLabel>
                      {this.props.fromText}
                    </FormLabel>
                  </GridItem>

                  <GridItem >

                    <BivNumberInput
                      value={this.props.fromValue}
                      onChange={(number) => {
                        if (!global.readOnlyUser) {
                          this.props.fromOnChange(number, this.props.sliderValues)

                          let _min = number ? number : this.props.fromValue
                          let _max = this.props.toValue ? this.props.toValue === _min ? this.props.toValue + 1 : this.props.toValue : _min + 1
  
                          var miSlider = document.getElementById(this.props.sliderName)
                          if (miSlider.noUiSlider) {
                            miSlider.noUiSlider.updateOptions({
                              range: {
                                'min': _min,
                                'max': _max,
                              },
                              start: [this.props.sliderValues[0], this.props.sliderValues[1], this.props.sliderValues[2]]
                            });
                          }
                          this.setState({state: this.state})
                          }
                      }}
                    />
                  </GridItem>
                  <GridItem >
                    <FormLabel>
                      {this.props.toText}
                    </FormLabel>
                  </GridItem>
                  <GridItem >
                    <BivNumberInput
                      value={this.props.toValue}
                      onChange={(number) => {
                        if (!global.readOnlyUser) {
                          this.props.toOnChange(number, this.props.sliderValues)

                          let _min = this.props.fromValue ? this.props.fromValue : 0
                          let _max = number ? number === _min ? number + 1 : number : this.props.toValue ? this.props.toValue : _min +1
  
                          var miSlider = document.getElementById(this.props.sliderName)
                          if (miSlider.noUiSlider) {
                            miSlider.noUiSlider.updateOptions({
                              range: {
                                'min': _min,
                                'max': _max,
                              },
                              start: [this.props.sliderValues[0], this.props.sliderValues[1], this.props.sliderValues[2]]
                            });
                          }

                          this.setState({state: this.state})
                        }
                      }}
                    />

                  </GridItem>

                </GridContainer>

                :

                <div></div>

              }

            </div>
            :
            <div></div>

        }
        <div
          id={this.props.sliderName}
          style={{ display: (this.props.switchActive || global.readOnlyUser) ? "block" : "none", margin: 30 }}
          className="slider-info"
        />

      </span>

    )
  }

}
BivLimitRanges.defaultProps = {
  title: languages[lang].proctoringC_influenceOCAnomalies,
  limited: 1,

  switchText: languages[lang].proctoringC_limit,
  switchActive: true,
  switchOnChange: () => null,

  fromText: languages[lang].proctoringC_rangeFrom,
  //fromValue: 0,
  fromOnChange: () => null,

  toText: languages[lang].proctoringC_to,
  //toValue: 100,
  toOnChange: () => null,

  //sliderValues: [25, 50, 75],
  sliderOnChange: () => null,
}

export default BivLimitRanges