import React from "react"
import { purple } from '@material-ui/core/colors';
import withStyles from "@material-ui/core/styles/withStyles"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import CardBody from "components/Card/CardBody";
import EditIcon from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import { Paper, TextField, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { variables } from "variables/variables"

import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import db from "db/db";
import { Prompt } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import utilTable from 'utilities/utilTable.jsx'

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

const PurpleSwitch = withStyles({
    switchBase: {
        color: purple[300],
        '&$checked': {
            color: purple[500],
        },
        '&$checked + $track': {
            backgroundColor: purple[500],
        },
    },
    checked: {},
    track: {},
})(Switch);

const languages = {
    'es': {
        // 'downloadDashboard': 'Descargar resumen',
        'downloadEnrolls': 'Descargar registros',
        // 'downloadExamns': 'Descargar auditorías',
        'editEnrolls': 'Editar',
        'editExamns': 'Editar auditoría',
        // 'editRol': 'Editar Roles',
        'editSettings': 'Administrar configuraciones de proctoring y ambiente',
        'editEnviromentSettings': 'Administrar configuraciones de ambiente',
        'editProctoringSettings': 'Administrar configuraciones de proctoring',
        'seeDashboard': 'Leer',
        'editEnrolls': 'Editar',
        'seeEnrolls': 'Leer',
        'seeExamns': 'Leer',
        // 'seeRol': 'Leer Roles',
        // 'editUsers': 'Administrar usuarios'
    }, 'en': {}
}
let lang = localStorage.lang ? localStorage.lang : 'es'
class RolsManager extends React.Component {

    constructor(props) {
        super(props);
        this.defaultSections = [
            // { title: "Dashboard", key: 'Dashboard', permissionsList: 'dashboardPermisions', active: false },
        { title: "Monitoreos", key: 'Examns', permissionsList: 'examsPermisions', active: false },
        { title: "Configuraciones", key: 'Settings', permissionsList: 'settingsPermisions', active: false },
        { title: "Registros", key: 'Enrolls', permissionsList: 'enrollsPermisions', active: false }]
        this.defaultValues = {
            editRolName: false,
            activeSave: true,
            saved: false,
            deleted: false,
            discardChanges: false,
            deleteRolModal: false,
            textToDelete: null,
            sections: [{ title: "Dashboard", key: 'Dashboard', permissionsList: 'dashboardPermisions', active: false },
            { title: "Monitoreos", key: 'Examns', permissionsList: 'examsPermisions', active: false },
            { title: "Configuraciones", key: 'Settings', permissionsList: 'settingsPermisions', active: false },
            { title: "Registros", key: 'Enrolls', permissionsList: 'enrollsPermisions', active: false }]
        }
        this.eltimeout = null
        this.defaultValues1 = {
            ...this.defaultValues, activeRol: null, newRol: false,
        }
        this.activeRolOriginal = {}
        this.dashboardPermisions = [
            // 'downloadDashboard',
            'seeDashboard',
        ]
        this.settingsPermisions = [
            // 'seeSettings',
            // 'editSettings',
            'editProctoringSettings',
            'editEnviromentSettings',
            // 'seeRol',
            // 'editRol',
            // 'editUsers'
            // 'seeUsers',
            // 'editUsers',
        ]
        this.examsPermisions = [
            'seeExamns',
            'editExamns',
            // 'downloadExamns',

        ]
        this.enrollsPermisions = [
            'seeEnrolls',
            'editEnrolls',
        ]
        this.permissions = [
            'downloadDashboard',
            'downloadEnrolls',
            'downloadExamns',
            'editEnrolls',
            'editExamns',
            'editProctoringSettings',
            'editEnviromentSettings',
            // 'editRol',
            // 'editSettings',
            'seeDashboard',
            'seeEnrolls',
            'seeExamns',
            // 'seeRol',
            'editUsers'
        ]
        this.rolesDependences = {
            'editEnrolls': { type: 2, ref: 'seeEnrolls' },
            'editExamns': { type: 2, ref: 'seeExamns' },
            // 'editRol': { type: 2, ref: 'seeRol' },
            'editProctoringSettings': { type: 3 },
            'editEnviromentSettings': { type: 3 },
            'seeEnrolls': { type: 1, ref: 'editEnrolls' },
            'seeExamns': { type: 1, ref: 'editExamns' },
            // 'seeRol': { type: 1, ref: 'editRol' },
            // 'editUsers': { type: 3 }
        }
        this.sectionsRead = {
            'Dashboard': 'seeDashboard',
            'Examns': 'seeExamns',
            'Settings': null,
            'Enrolls': 'seeEnrolls',
        }


        this.state = { ...this.defaultValues1, roles: [],order:'asc', hasPermission:false,  }
        
    }


    sortIcon(order) { 
       
        if (!order){
            return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
        } 
        else if  (order === 'asc') {
            return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
        } 
        else if (order === 'desc') {
            return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
        }
        
      }

      sortFunction( order) {
        order= order=='asc'?'desc':'asc'
        console.log(this.state.roles,order)
        let roles=JSON.parse(JSON.stringify(this.state.roles))
        
        var sortRoles = roles.sort(function(a, b) {
            if(order==='asc'){
                return a.name.toLowerCase() < b.name.toLowerCase()?1:-1
            }
            return a.name.toLowerCase() > b.name.toLowerCase()?1:-1
        })
    
        this.setState({
            roles: sortRoles,
        //   orderChange: true,
           order: order,
        //   loading: true,
        })
      }
      


    getRols() {
        db.getRols((_res) => {
            this.setState({ roles: _res })
        }, () => {
            this.setNull()
        })
    }
    componentDidMount() {
        this.getRols()
        let rol = localStorage.rol

        try {
          rol = JSON.parse(rol)
        }
        catch (_e) {
          rol = {}
        }
    
        rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
        this.setState({hasPermission:rol.editRol===1?true:false})
    }
    discardChanges() {
        let y = this.secondparam
        let x = JSON.parse(JSON.stringify(this.firstparam))
        this.activeRolOriginal = x
        if (y) {
            this.setState({ activeRol: x, ...this.defaultValues, sections: JSON.parse(JSON.stringify(this.defaultSections)), newRol: y, editRolName: true, })
        }
        else {
            let sections = JSON.parse(JSON.stringify(this.defaultSections))
            for (let i in sections) {
                let section = this[sections[i].permissionsList]

                for (let j in section) {
                    console.log(j)
                    if (x.permission[section[j]]) {
                        sections[i].active = true
                        break;
                    }
                }
            }
            this.setState({ activeRol: x, ...this.defaultValues, newRol: false, sections: sections })
        }
    }
    selectRol(x, y) {

        if (this.eltimeout) {
            clearTimeout(this.eltimeout)
        }
        this.firstparam = x
        this.secondparam = y
        if (this.state.newRol || (!this.state.activeSave && !this.state.editRolName)) {
            this.setState({ discardChanges: true })
        }
        else {
            this.discardChanges()
        }



    }
    deleteRol() {
        this.setState({ deleteRolModal: false })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let _token = localStorage.getItem('fb_jwt')
        myHeaders.append("Authorization", "Bearer " + _token);
        let raw = JSON.stringify({
            "rolId": this.activeRolOriginal.id
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.Direction_Back_panel + "/rol/deleteRol", requestOptions)
            .then(response => response.text())
            .then(result => {
                let _res = JSON.parse(result)
                if (_res.message && _res.message === 'Unauthorized') {
                    global.showNoPermission()
                    this.setNull()
                    return true
                }
                if(!_res.success && _res.code==1){
                    global.showNoPermission('No se puede eliminar el rol de administrador.')
                    this.setNull()
                    return true
                }

                this.setState({ deleteRolModal: false })

                if (this.eltimeout) {
                    clearTimeout(this.eltimeout)
                }
                setTimeout(() => {
                    this.setNull()
                }, 2500)
                this.setState({ deleted: true })
                this.getRols()

            })
            .catch(error => console.log('error', error));
    }
    saveRol() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let _token = localStorage.getItem('fb_jwt')
        myHeaders.append("Authorization", "Bearer " + _token);
        let rol = JSON.parse(JSON.stringify(this.state.activeRol))
        rol.permissions = rol.permission
        delete rol.permission
        if (!this.state.newRol) {
            rol.rol = rol.name
            delete rol.name
        }
        var raw = JSON.stringify(rol);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        if (this.state.newRol) {
            fetch(global.Direction_Back_panel + "/rol/createRol", requestOptions)
                .then(response => response.text())
                .then(result => {
                    let _res = JSON.parse(result)
                    if (_res.message && _res.message === 'Unauthorized') {
                        global.showNoPermission()
                        this.setNull()
                        return true
                    }
                   
                    if (this.eltimeout) {
                        clearTimeout(this.eltimeout)
                    }
                    setTimeout(() => {
                        this.setNull()
                    }, 2500)
                    this.setState({ saved: true })
                    this.getRols()

                })
                .catch(error => console.log('error', error));
        }
        else {
            fetch(global.Direction_Back_panel + "/rol/modifiedRol", requestOptions)
                .then(response => response.text())
                .then(result => {
                    let _res = JSON.parse(result)
                    if (_res.message && _res.message === 'Unauthorized') {
                        global.showNoPermission()
                        this.setNull()
                        return true
                    }
                    if(!_res.success && _res.message=="Can't modified rol admin"){
                        global.showNoPermission('No se puede editar el rol de administrador.')
                        this.setNull()
                        return true
                    }
                    if (this.eltimeout) {
                        clearTimeout(this.eltimeout)
                    }
                    this.eltimeout = setTimeout(() => {
                        this.setNull()
                    }, 2500)
                    this.setState({ saved: true })
                    this.getRols()
                })
                .catch(error => console.log('error', error));
        }
    }
    setNull() {
        this.activeRolOriginal = {}
        this.newRolName = null

        this.setState({ ...this.defaultValues1 })
    }
    // 

    render() {
        return (
            <div>
                <Prompt
                    when={this.state.newRol || (!this.state.activeSave && !this.state.editRolName)}
                    message={location =>
                        '¿Está seguro de que desea salir de esta sección sin guardar los cambios?'
                    }
                />
                <Modal
                    open={this.state.discardChanges}
                    onClose={() => {
                        this.setState({ discardChanges: false })
                    }}

                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Paper elevation={2} style={{
                        position: 'absolute',
                        width: 400,
                        // border: '2px solid #000',
                        borderRadius: 15,
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        left: '50%',
                        boxShadow: 24,
                        overflow: 'hidden'
                    }}>
                        <div style={{ backgroundColor: '#ff8f00', padding: '32px 16px 8px 16px', }}>
                            <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}>Se modificó el rol y no se guardaron los cambios.</h3>

                            <p style={{ color: 'white' }} id="simple-modal-description">
                                ¿Desea descartar los cambios?
                            </p>
                        </div>
                        <div style={{ padding: '16px 16px 17px 16px', }}>

                            <div style={{ display: 'flex', justifyContent: 'end', padding: '16px 0px 0px 0px' }}>
                                <Button onClick={() => {
                                    this.setState({ discardChanges: false })
                                }} >Cancelar</Button>
                                <Button onClick={() => { this.discardChanges() }} >Descartar</Button>
                            </div>
                            {/* <Input placeholder={this.activeRolOriginal.name} inputProps={{ 'aria-label': 'description' }} /> */}
                        </div>

                    </Paper>
                </Modal>
                <Modal
                    open={this.state.deleteRolModal}
                    onClose={() => {
                        this.setState({ deleteRolModal: false })
                    }}

                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Paper elevation={2} style={{
                        position: 'absolute',
                        width: 400,
                        // border: '2px solid #000',
                        borderRadius: 15,
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        left: '50%',
                        boxShadow: 24,
                        overflow: 'hidden'
                    }}>
                        <div style={{ backgroundColor: '#ff5252', padding: '32px 16px 8px 16px', }}>
                            <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}>Borrar rol</h3>

                            <p style={{ color: 'white' }} id="simple-modal-description">
                                Eliminar un rol es una acción permanente.
                            </p>
                        </div>
                        <div style={{ padding: '16px 16px 17px 16px', }}>
                            <p id="simple-modal-description">
                                Para eliminar el rol, escribe su nombre:
                            </p>
                            <TextField
                                id="outlined-name"
                                label="Name"
                                style={{ width: '100%' }}
                                placeholder={this.activeRolOriginal.name}
                                onChange={(event) => {
                                    this.setState({ textToDelete: event.target.value })
                                }}
                                variant="outlined"
                            />
                            <div style={{ display: 'flex', justifyContent: 'end', padding: '16px 0px 0px 0px' }}>
                                <Button onClick={() => {
                                    this.setState({ deleteRolModal: false })
                                }} >Cancelar</Button>
                                <Button onClick={() => { this.deleteRol() }} disabled={this.state.textToDelete !== this.activeRolOriginal.name}>Borrar</Button>
                            </div>
                            {/* <Input placeholder={this.activeRolOriginal.name} inputProps={{ 'aria-label': 'description' }} /> */}
                        </div>

                    </Paper>
                </Modal>
                <GridContainer style={{ paddingRight: 0 }}>
                    <GridItem md={4}>
                        <Paper elevation={1} >
                            <CardBody>
                                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 5 }}>
                                    <Typography color="textPrimary" variant="h5" component="p">
                                        Roles
                                    </Typography>
                                    <div>
                                    <IconButton onClick={() => { this.sortFunction(this.state.order) }} aria-label="order"  >
                                        {this.sortIcon(this.state.order)}
                                    </IconButton>

                                    <IconButton aria-label="add" onClick={(a,b) => {
                                        this.selectRol({ name: '', permission: {} }, 1)
                                    }} style={{}}>
                                        <Add />
                                    </IconButton>
                                    </div>
                                </div>
                                <List dense={false} >
                                    {this.state.roles.map((a, b) => {
                                        return (
                                            <ListItem
                                                key={b}
                      style={{cursor:"pointer"}}

                                                onClick={() => { this.selectRol(a) }}
                                                secondaryAction={
                                                    <IconButton 
                                                    // onClick={() => { this.selectRol(a) }} 
                                                    edge="end" aria-label="delete">
                                                        {!this.state.hasPermission?
                                                        <VisibilityIcon/>
                                                        :
                                                        <EditIcon />}
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemText
                                                    primary={a.name}
                                                />
                                            </ListItem>
                                        )
                                    })}

                                </List>
                            </CardBody>
                        </Paper>

                    </GridItem>
                    <GridItem md={8}>
                        <Collapse in={this.state.saved}>
                            <Alert
                                style={{ marginBottom: 10 }}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            // setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                El rol se {this.state.newRol ? 'creó' : 'modificó'} correctamente
                            </Alert>
                        </Collapse>
                        <Collapse in={this.state.deleted}>
                            <Alert
                                style={{ marginBottom: 10 }}
                                severity="success" color="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            // setOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                El rol se eliminó correctamente
                            </Alert>
                        </Collapse>
                        <Paper elevation={1} >
                            <CardBody >

                                {!this.state.activeRol ?
                                    <Typography variant="h5" color="textSecondary" component="h3">
                                        Seleccione un rol para ver o editar sus permisos
                                    </Typography>
                                    :
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 5 }}>
                                            <Typography variant="h5" color="textPrimary" component="h2">
                                                Permisos
                                            </Typography>
                                            <div>
                                                {
                                                    !this.state.newRol ?
                                                        <IconButton disabled={!this.state.hasPermission} onClick={() => {
                                                            this.setState({ deleteRolModal: true })
                                                        }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <ListItem>
                                            <Typography variant="h5" color="textPrimary" component="h2">
                                                Rol
                                            </Typography>
                                        </ListItem>
                                        {!this.state.editRolName ?

                                            <ListItem

                                                secondaryAction={
                                                    <IconButton  disabled={!this.state.hasPermission}  onClick={() => {
                                                        this.setState({ editRolName: true })
                                                    }} edge="end" aria-label="delete">
                                                        <EditIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <Typography variant="h6" style={{ fontWeight: 400, fontSize: 20 }} component="h2">
                                                    {this.state.activeRol.name}
                                                </Typography>

                                            </ListItem>
                                            :
                                            <ListItem

                                                secondaryAction={
                                                    <IconButton onClick={() => {
                                                        let rol = this.state.activeRol
                                                        if (this.newRolName) {
                                                            rol.name = this.newRolName

                                                            let activeSave = JSON.stringify(this.activeRolOriginal) !== JSON.stringify(rol)
                                                            if (this.state.activeRol.name !== rol.name) {
                                                                this.setState({ editRolName: false, activeRol: rol, activeSave: activeSave })
                                                            }
                                                            else {
                                                                this.setState({ editRolName: false, activeSave: activeSave })
                                                            }
                                                        }

                                                    }} edge="end" aria-label="delete">
                                                        <CheckIcon />
                                                    </IconButton>
                                                }
                                            >
                                                <TextField variant="filled" placeholder="Name" defaultValue={this.state.activeRol.name} onChange={(event) => {
                                                    this.newRolName = event.target.value
                                                }} inputProps={{ 'aria-label': 'description' }} />
                                            </ListItem>
                                        }
                                        <Typography variant="h5" color="textPrimary" component="h2">
                                            &nbsp;
                                        </Typography>
                                        {this.state.sections.map((c, d) => (
                                            <List key={d} dense={true} >
                                                <ListItem>
                                                    <Typography variant="h6" style={{ fontWeight: 400 }} component="h3" >
                                                        {c.title}
                                                    </Typography>
                                                    <ListItemSecondaryAction >
                                                        <PurpleSwitch
                                                        disabled={!this.state.hasPermission}
                                                            edge="end"
                                                            color={"warning"}
                                                            onChange={(x) => {
                                                                let _sections = this.state.sections
                                                                let permissionList = this[c.permissionsList]
                                                                let rol = this.state.activeRol
                                                                let activeSave = JSON.stringify(this.activeRolOriginal) !== JSON.stringify(rol)
                                                                if (c.active) {
                                                                    _sections[d].active = false
                                                                    for (let i in permissionList) {
                                                                        let _perm = permissionList[i]
                                                                        rol.permission[_perm] = 0
                                                                    }
                                                                }
                                                                else {
                                                                    _sections[d].active = true
                                                                    let _sr=this.sectionsRead[c.key]
                                                                    if(_sr){
                                                                        let readPerm = _sr
                                                                        rol.permission[readPerm] = 1
                                                                    }
                                                                }
                                                                this.setState({ activeRol: rol, activeSave: activeSave })

                                                            }}
                                                            checked={c.active}
                                                            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                {this[c.permissionsList].map((a, b) => {
                                                    return (<ListItem key={b}>
                                                        <ListItemText id="switch-list-label-wifi" primary={languages[lang][a]} />
                                                        <ListItemSecondaryAction >
                                                            <Switch
                                                                disabled={!c.active || !this.state.hasPermission}
                                                                edge="end"
                                                                color="primary"
                                                                onChange={() => {
                                                                    let rol = this.state.activeRol
                                                                    let permissionRef = this.rolesDependences[a]
                                                                    let st = rol.permission[a] && rol.permission[a] === 1 ? 0 : 1
                                                                    rol.permission[a] = st
                                                                    if (permissionRef.type === 1 && st === 0) {
                                                                        rol.permission[permissionRef.ref] = 0
                                                                    }
                                                                    else if (permissionRef.type === 2 && st === 1) {
                                                                        rol.permission[permissionRef.ref] = 1
                                                                    }
                                                                    let activeSave = JSON.stringify(this.activeRolOriginal) !== JSON.stringify(rol)
                                                                    this.setState({ activeRol: rol, activeSave: activeSave })
                                                                }}
                                                                checked={this.state.activeRol.permission && this.state.activeRol.permission[a] === 1}
                                                                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>)
                                                })}
                                            </List>
                                        ))}

                                        <div style={{ textAlign: "right", paddingTo: 10 }} >
                                            <Button onClick={() => {
                                                this.setNull()
                                            }} >Cancelar</Button>
                                            <Button onClick={() => { this.saveRol() }} disabled={this.state.activeSave || this.state.editRolName || !this.state.hasPermission}>Guardar</Button>
                                        </div>
                                    </div>
                                }
                            </CardBody >
                        </Paper>



                    </GridItem>
                </GridContainer>
            </div >
        )
    }

}

export default withStyles(sweetAlertStyle)(RolsManager)
