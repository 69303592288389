import React from 'react'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import BivPaginationButton from 'components/BivPaginationButton/BivPaginationButton.jsx'

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

//class BivPagination extends React.Component {

function BivPagination(props) {

  BivPagination.defaultProps = {
    onClickPrev: () => null,
    onClickPage: () => null,
    onClickNext: () => null,
  }

  const _totalPages = props.totalRegistry ? props.totalRegistry : 0
  const page = props.page
  const totalPages = Math.ceil(_totalPages / 20)

  const _pageText = (page === 1) ? 2 : (page === totalPages) ? totalPages - 1 : page

  //Anterior
  const btnPreActive = (totalPages === 1) ? false : (page === 1) ? false : true

  //First
  const btnFirstActive = (totalPages === 1) ? false : true
  const btnFirstSelected = (page === 1) ? true : false

  //... 
  const btnSeparator1Visible = (totalPages > 4) ? (page > 3) ? true : false : false
  //...
  const btnSeparator2Visible = (totalPages > 4) ? (page < totalPages - 2) ? true : false : false

  //Prev
  const btnBackVisible = (totalPages < 4) ? false : (_pageText - 1 === 1) ? false : true

  //Page
  const btnCurrentText = _pageText
  const btnCurrentVisible = (totalPages < 3) ? false : true
  const btnCurrentSelected = (page === 1) ? false : (page === totalPages) ? false : true

  //Next
  const btnForwardVisible = (totalPages < 4) ? false : (_pageText + 1 === totalPages) ? false : true

  //Last
  const btnLastSelected = (page === totalPages) ? true : false
  const btnLastVisible = (totalPages < 2) ? false : true
  const btnLastText = totalPages

  //Siguiente
  const btnNextActive = (totalPages === 1) ? false : (page === totalPages) ? false : true

  return (

    <GridContainer>

      <GridItem xs={12} sm={12} md={12} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%', marginRight: 30, marginTop: 8, marginBottom: 8 }}>

        {/* Anterior */}
        <BivPaginationButton
          id="Anterior"
          onClick={props.onClickPrev}
          active={btnPreActive}
          visible={true}
          text={languages[lang].button_previous}
          selected={false}
        />

        {/* First */}
        <BivPaginationButton
          id="First"
          onClick={() => { props.onClickPage(1) }}
          active={btnFirstActive}
          visible={true}
          text='1'
          selected={btnFirstSelected}
        />

        {/* ... */}
        <BivPaginationButton
          id="separator1"
          onClick={() => { }}
          active={true}
          visible={btnSeparator1Visible}
          text='...'
          selected={false}
        />

        {/* Prev */}
        <BivPaginationButton
          id="Prev"
          onClick={() => { props.onClickPage(btnCurrentText - 1) }}
          active={true}
          visible={btnBackVisible}
          text={(btnCurrentText - 1).toString()}
          selected={false}
        />

        {/* Page */}
        <BivPaginationButton
          id="Page"
          //onClick={this.handleGoToPage(paginationProps, this.state.btnCurrentText)}
          onClick={() => { props.onClickPage(btnCurrentText) }}
          active={true}
          visible={btnCurrentVisible}
          text={btnCurrentText.toString()}
          selected={btnCurrentSelected}
        />

        {/* Next */}
        <BivPaginationButton
          id="Next"
          onClick={() => { props.onClickPage(btnCurrentText + 1) }}
          active={true}
          visible={btnForwardVisible}
          text={(btnCurrentText + 1).toString()}
          selected={false}
        />

        {/* ... */}
        <BivPaginationButton
          id="separator2"
          onClick={() => { }}
          active={true}
          visible={btnSeparator2Visible}
          text='...'
          selected={false}
        />

        {/* Last */}
        <BivPaginationButton
          id="Last"
          onClick={() => { props.onClickPage(btnLastText) }}
          active={true}
          visible={btnLastVisible}
          text={btnLastText.toString()}
          selected={btnLastSelected}
        />

        {/* Siguiente */}
        <BivPaginationButton
          id="Siguiente"
          onClick={props.onClickNext}
          active={btnNextActive}
          visible={true}
          text={languages[lang].button_next}
        />

      </GridItem>

    </GridContainer>

  )
}

export default BivPagination
