import React from 'react'

import moment from 'moment'

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import Image from 'react-bootstrap/Image'


import ValidationLabel from "components/ValidationLabel/ValidationLabel.jsx"

import KWTextWithTitle from "components/KWTextWithTitle/KWTextWithTitle.jsx"
import noPhoto from "assets/img/nophoto.png"


moment.locale('es')

const languages = require('../../assets/translate/translate_validationFormProfile.json')
let lang = localStorage.lang  ? localStorage.lang : 'es'

function KWValidationFormProfile(props) {

  const {
    examType,
    data,
    _data,
    //showInfoPc,
    //showInfoPcChange,
    exam_table_fields
  } = props

  KWValidationFormProfile.defaultProps = {
    examType: "Complete",
    data: [],
    //showInfoPc: false,
    //showInfoPcChange: () => null,
  }

  return (

    data.keyproctoring ?
    <GridContainer>
     
      <GridItem xs={12} sm={6} md={2} lg={2} xl={2} style={{ display: "flex", flexDirection: "column" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
            <Image src={data.userData?JSON.parse(data.userData).i0.i:''} rounded fluid
              style={{ maxHeight: "300px" }}
              onClick={() => {
                //this.setState({photoDialog: true })
                // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      {(examType === "Complete") ?
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_nameLastName} text={data.userName ? data.userName : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_user} text={data.user ? data.user : "" } />
            <KWTextWithTitle title={global.dni_name} text={data.dni ? data.dni : "" } />
            { exam_table_fields.file ? <KWTextWithTitle title={languages[lang].validationFormProfile_file} text={data.file ? data.file : "" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_file} text={data.file ? data.file : "" } /> */}
            <KWTextWithTitle title={exam_table_fields.subjectName.name} text={data.subjectName ? data.subjectName : "-" } />
            { exam_table_fields.examType ? <KWTextWithTitle title={languages[lang].validationFormProfile_examType} text={data.examType ? data.examType : "-" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_examType} text={data.examType ? data.examType : "-" } /> */}
          </GridContainer>
        </GridItem>
        :
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_nameLastName} text={data.userName ? data.userName : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_subject} text={data.validation ? <ValidationLabel validation={data.validation} /> : "-" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_examDate} text={data._createdAt ? data._createdAt : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_coincidence} text= {languages[lang].validationFormProfile_yes} />
          </GridContainer>
        </GridItem>
      }
      {(examType === "Complete") ?
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_validation} text={data.validation ? <ValidationLabel validation={data.validation} /> : "-" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_examDate} text={data._createdAt ? data._createdAt : "" } />
            { exam_table_fields.qualification ? <KWTextWithTitle title={ global._uni_ === "1" ?languages[lang].validationFormProfile_provisionalMark : "Calificación"} text={data.qualification ? data.qualification : "" } /> : null}
            {/* <KWTextWithTitle title={ global._uni_ === "1" ?languages[lang].validationFormProfile_provisionalMark : "Calificación"} text={data.qualification ? data.qualification : "" } /> */}
            { exam_table_fields.grade ? <KWTextWithTitle title={languages[lang].validationFormProfile_finalMark} text={data.grade ? data.grade : "" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_finalMark} text={data.grade ? data.grade : "" } /> */}
            <KWTextWithTitle title={languages[lang].validationFormProfile_coincidence} text={languages[lang].validationFormProfile_yes} />
          </GridContainer>
        </GridItem>
        :
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }} />
      }

      <GridItem xs={12} sm={6} md={4} lg={4} xl={4} style={{ display: "flex", flexDirection: "column" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>{languages[lang].validationFormProfile_imageThatWasUsedDuringTheExam}</h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", height: "226px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
           
            <Image src={data.instantPhoto} rounded fluid
              style={{ maxHeight: "226px" }}
              onClick={() => {
                //this.setState({photoDialog: true })
                // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              }}
            />
          </GridItem>

          {/*
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", marginRight: 15, alignItems: "flex-end", justifyContent: "flex-end" }}>
            <Button
              color="info"
              size="sm"
              simple
              round
              onClick={showInfoPcChange}
            >
              {!showInfoPc ? "Más información" : "Menos información"}
            </Button>

          </GridItem>
          */}

        </GridContainer>
      </GridItem>

      {/*showInfoPc ? */}

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "column" }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
          <div style={{ width: "99.4%", height: 5, borderTop: '1px solid #ced4db' }} />
        </GridItem>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 15, width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>{languages[lang].validationFormProfile_deviceData}</h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 10, display: "flex", flexDirection: "row", marginBottom: 10 }}>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_processor}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.brand : " -"}</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_nuclei}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.cores : " -"  }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_physicalNuclei}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.physicalCores : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_mhz}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport? " " + JSON.parse(data.systemReport).cpu.speed : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_memory}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + Math.trunc(JSON.parse(data.systemReport).mem.total / 1024 / 1024) + " Mb" : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_availableMemory}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + Math.trunc(JSON.parse(data.systemReport).mem.free / 1024 / 1024) + " Mb" : " -" }</h5>
            </GridItem>

          </GridItem>
        </GridContainer>
      </GridItem>

      {/*showInternetSpeed ? */}

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "column" }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
          <div style={{ width: "99.4%", height: 5, borderTop: '1px solid #ced4db' }} />
        </GridItem>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 15, width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>Conexión a internet (subida): </h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 10, display: "flex", flexDirection: "row", marginBottom: 10 }}>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Velocidad al inicio del examen:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_init ? " " + parseFloat(data.upload_init.replace(' MBps','')).toFixed(2) + " MBps"  : " -"}</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Velocidad al final del examen:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_end ? " " + parseFloat(data.upload_end.replace(' MBps','')).toFixed(2) + " MBps"  : " -"  }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Promedio de velocidad:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_init ? " " + ((parseFloat(data.upload_init.replace(' MBps','')) + parseFloat(data.upload_end.replace(' MBps','')) ) / 2).toFixed(2)  + " MBps": " -" }</h5>
            </GridItem>

          </GridItem>
        </GridContainer>
      </GridItem>

    </GridContainer >

    :

    <GridContainer>
     
      <GridItem xs={12} sm={6} md={2} lg={2} xl={2} style={{ display: "flex", flexDirection: "column" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
            <Image src={_data.userData?JSON.parse(_data.userData).i0.i:null } rounded fluid
              style={{ maxHeight: "300px" }}
              onClick={() => {
                //this.setState({photoDialog: true })
                // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      {(examType === "Complete") ?
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_nameLastName} text={data.userName ? data.userName : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_user} text={data.user ? data.user : "" } />
            <KWTextWithTitle title={global.dni_name} text={data.dni ? data.dni : "" } />
            { exam_table_fields.file ? <KWTextWithTitle title={languages[lang].validationFormProfile_file} text={data.file ? data.file : "" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_file} text={data.file ? data.file : "" } /> */}
            <KWTextWithTitle title={exam_table_fields.subjectName.name} text={data.subjectName ? data.subjectName : "-" } />
            { exam_table_fields.examType ? <KWTextWithTitle title={languages[lang].validationFormProfile_examType} text={data.examType ? data.examType : "-" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_examType} text={data.examType ? data.examType : "-" } /> */}
          </GridContainer>
        </GridItem>
        :
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_nameLastName} text={data.userName ? data.userName : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_subject} text={data.validation ? <ValidationLabel validation={data.validation} /> : "-" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_examDate} text={data._createdAt ? data._createdAt : "" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_coincidence} text= {languages[lang].validationFormProfile_yes} />
          </GridContainer>
        </GridItem>
      }
      {(examType === "Complete") ?
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }}>
          <GridContainer>
            <KWTextWithTitle title={languages[lang].validationFormProfile_validation} text={data.validation ? <ValidationLabel validation={data.validation} /> : "-" } />
            <KWTextWithTitle title={languages[lang].validationFormProfile_examDate} text={data._createdAt  ? data._createdAt : "" } />
            { exam_table_fields.qualification ? <KWTextWithTitle title={ global._uni_ === "1" ?languages[lang].validationFormProfile_provisionalMark : "Calificación"} text={data.qualification ? data.qualification : "" } /> : null}
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_provisionalMark} text={data.qualification ? data.qualification : "" } /> */}
            { exam_table_fields.grade ? <KWTextWithTitle title={languages[lang].validationFormProfile_finalMark} text={data.grade ? data.grade : "" } /> : null }
            {/* <KWTextWithTitle title={languages[lang].validationFormProfile_finalMark} text={data.grade ? data.grade : "" } /> */}
            <KWTextWithTitle title={languages[lang].validationFormProfile_coincidence} text={languages[lang].validationFormProfile_yes} />
          </GridContainer>
        </GridItem>
        :
        <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ display: "flex", flexDirection: "column" }} />
      }

      <GridItem xs={12} sm={6} md={4} lg={4} xl={4} style={{ display: "flex", flexDirection: "column" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>{languages[lang].validationFormProfile_imageThatWasUsedDuringTheExam}</h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", height: "226px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            {
              data.instantPhoto?
              <Image src={data.instantPhoto} rounded fluid
              style={{ maxHeight: "226px" }}
              onClick={() => {
                //this.setState({photoDialog: true })
                // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              }}
            />

              :
              <Image src={noPhoto} rounded fluid
              style={{ maxHeight: "226px" }}
              onClick={() => {
                //this.setState({photoDialog: true })
                // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              }}
              
            />
            }
           
          </GridItem>

          {/*
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", marginRight: 15, alignItems: "flex-end", justifyContent: "flex-end" }}>
            <Button
              color="info"
              size="sm"
              simple
              round
              onClick={showInfoPcChange}
            >
              {!showInfoPc ? "Más información" : "Menos información"}
            </Button>

          </GridItem>
          */}

        </GridContainer>
      </GridItem>

      {/*showInfoPc ? */}

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "column" }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
          <div style={{ width: "99.4%", height: 5, borderTop: '1px solid #ced4db' }} />
        </GridItem>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 15, width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>{languages[lang].validationFormProfile_deviceData}</h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 10, display: "flex", flexDirection: "row", marginBottom: 10 }}>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_processor}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.brand : " -"}</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_nuclei}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.cores : " -"  }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_physicalNuclei}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + JSON.parse(data.systemReport).cpu.physicalCores : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_mhz}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport? " " + JSON.parse(data.systemReport).cpu.speed : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_memory}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + Math.trunc(JSON.parse(data.systemReport).mem.total / 1024 / 1024) + " Mb" : " -" }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>{languages[lang].validationFormProfile_availableMemory}</h6>
              <h5 style={{ fontSize: 14 }}> {data.systemReport ? " " + Math.trunc(JSON.parse(data.systemReport).mem.free / 1024 / 1024) + " Mb" : " -" }</h5>
            </GridItem>

          </GridItem>
        </GridContainer>
      </GridItem>

      {/*showInternetSpeed ? */}

      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", flexDirection: "column" }}>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
          <div style={{ width: "99.4%", height: 5, borderTop: '1px solid #ced4db' }} />
        </GridItem>

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 15, width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <h4 style={{ color: 'dimgray', marginBottom: 0 }}>Conexión a internet (subida): </h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 10, display: "flex", flexDirection: "row", marginBottom: 10 }}>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Velocidad al inicio del examen:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_init ? " " + parseFloat(data.upload_init.replace(' MBps','')).toFixed(2) + " MBps"  : " -"}</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Velocidad al final del examen:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_end ? " " + parseFloat(data.upload_end.replace(' MBps','')).toFixed(2) + " MBps"  : " -"  }</h5>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={2} xl={2}>
              <h6>Promedio de velocidad:</h6>
              <h5 style={{ fontSize: 14 }}> {data.upload_init && data.upload_end ? " " + ((parseFloat(data.upload_init.replace(' MBps','')) + parseFloat(data.upload_end.replace(' MBps','')) ) / 2).toFixed(2)  + " MBps": " -" }</h5>
            </GridItem>

          </GridItem>
        </GridContainer>
      </GridItem>

    </GridContainer >

  )

}

export default KWValidationFormProfile