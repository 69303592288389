import React from 'react'

import BivButtonLow from "components/BivButtonLow/BivButtonLow.jsx";
import BivButtonMedium from "components/BivButtonMedium/BivButtonMedium.jsx";
import BivButtonHigh from "components/BivButtonHigh/BivButtonHigh.jsx";
import BivButtonCritical from "components/BivButtonCritical/BivButtonCritical.jsx";

/*
* Recibe props "value" "onChangeFuncion".
* value [1-4] indica cual esta activo
* onChangeFuncion () function de retorno
*/

class BivPriority extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      activo: this.props.value
    }
  }

  onClickFunction(x){
    if (!global.readOnlyUser) {
      this.setState({}, () => {
        this.setState({activo : x},this.props.onChangeFuncion(x))
      })
    }
  }

  render() {
  
    return (

      <div>

        <BivButtonLow
          active={this.state.activo === 1 ? false : true}
          onClick={() => {this.onClickFunction(1)}}
        ></BivButtonLow>

        <BivButtonMedium active={this.state.activo === 2 ? false : true}
          onClick={() => {this.onClickFunction(2)}}
        ></BivButtonMedium>

        <BivButtonHigh active={this.state.activo === 3 ? false : true}
          onClick={() => {this.onClickFunction(3)}}
        ></BivButtonHigh>

        <BivButtonCritical active={this.state.activo === 4 ? false : true}
          onClick={() => {this.onClickFunction(4)}}
        ></BivButtonCritical>

      </div>
    )
  }

}

export default BivPriority