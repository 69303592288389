import React from 'react'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import Card from "components/Card/Card.jsx"

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

function CardInMaintenance() {

  return (

    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '120px' }}>
            <ErrorOutlineIcon style={{ width: '85', height: '85', color: 'rgb(74, 179, 255)' }} />
          </div>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1 style={{ color: 'rgb(74, 179, 255)', fontWeight: 'bold', fontSize:30 }}>¡ups!</h1>
          </div>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '120px' }}>
            <h3 style={{ color: 'rgb(0, 0, 0)', fontSize:22 }}>Sección en mantenimiento.</h3>
          </div>
        </Card>
      </GridItem>
    </GridContainer>

  )

}

export default CardInMaintenance