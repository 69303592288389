import React from 'react'

import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from '@material-ui/core/IconButton';

import BivSwitch from "components/BivSwitch/BivSwitch.jsx";

import BivPriority from "components/BivPriority/BivPriority.jsx";

import BivNumberInput from "components/BivNumberInput/BivNumberInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";




const languages = require('../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivControlComun(props) {

  
    const {
      values,
      switchText,
      switchDisable,
      switchOnChange,

      expandValue,
      expandId,
      expandOnChange,

      initialPriorityText,
      initialPriorityOnChange,

      changePriorityLabel,
      changePriorityFromText,
      changePriorityFromOnChange,
      changePriorityToText,
      changePriorityToOnChange,
    }= props

    BivControlComun.defaultProps = {
      values: {a:true, c:1, ip:1, t:{v:1, p:1}, vm:100, vw:1},
      
      switchText: '-',
      switchDisable: false,
      switchOnChange: () => null,

      expandValue: true,
      expandId:'',
      expandOnChange: () => null,

      initialPriorityText: languages[lang].proctoringC_initialPriority1,
      initialPriorityOnChange: () => null,

      changePriorityLabel: languages[lang].proctoringC_priorityChange1,
      changePriorityFromText: languages[lang].proctoringC_from1,
      changePriorityFromOnChange: () => null,
      changePriorityToText: languages[lang].proctoringC_timesOfAIAEGTPriority,
      changePriorityToOnChange: () => null,

    }

    return (

      <div>
        
        {/* Switch */}
        <BivSwitch
          text={switchText}
          disable={switchDisable}
          value={values.a}
          onChange={switchOnChange}
        />

        {/* Icon */}
        <IconButton
          id={expandId}
          disabled={(global.readOnlyUser) ? false : !values.a}
          onClick={() => { 
            expandOnChange()
            //this.handleExpandClick({nameExpand})
          }}
          //aria-expanded={this.state.expanded == {nameExpand}}
          aria-expanded={expandValue}
          aria-label="show more"
        >
          {expandValue ? <ExpandLess /> : < ExpandMoreIcon/> }
        </IconButton>

        {/* Expanded */}
        <Collapse in={expandValue} timeout="auto" unmountOnExit>

            <CardContent>

               {/* Prioridad Inicial */}
              <GridContainer style={{ alignItems: 'baseline', marginLeft: 5 }}>
              <GridItem >
                  <FormLabel>
                    {initialPriorityText}
                  </FormLabel>
                </GridItem>
                <GridItem >
                  <BivPriority
                    value= {values.ip}
                    onChangeFuncion = {(active)=>{
                      initialPriorityOnChange(active)
                    }}
                  />
                </GridItem>
              </GridContainer>

              {/* Cambio de prioridad */}
              <GridContainer style={{ alignItems: 'baseline', marginLeft: 5, marginTop: 15 }}>
                <GridItem xs={12} >
                  <FormLabel>
                    {changePriorityLabel}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                  <FormLabel>
                    {changePriorityFromText}
                  </FormLabel>
                  <BivNumberInput
                    value={values.t.v}
                    disabled={values.a ? false : true}
                    onChange={(number)=>{
                      if (!global.readOnlyUser) {
                        changePriorityFromOnChange(number)
                      }
                    }}
                  />
                  <FormLabel>
                    {changePriorityToText}
                  </FormLabel>

                  <BivPriority
                    value= {values.t.p}
                    onChangeFuncion = {(active)=>{
                      changePriorityToOnChange(active)
                    }}
                  />                  
                </GridItem>
              </GridContainer>
              <div style={{ width: "100%", height: 20, marginTop: 11, borderTop: '1px solid #ced4db' }}></div>



            </CardContent>

          </Collapse>

      </div>
    )

}

export default BivControlComun