import React from 'react'
import CustomInput from "components/CustomInput/CustomInput.jsx";

function BivNumberInput(props) {

  const {value, onChange, disabled} = props

  BivNumberInput.defaultProps = {
    onChange: () => null,
    disabled: false,
  }

  return (
    <CustomInput
      formControlProps={{
        style: { paddingTop: 0 }
      }}
      inputProps={{
        value: value ? value : '',
        disabled: disabled,
        type: "number",
        onChange: (event) => {
          let number = parseInt(event.target.value)
          onChange(number)
        },
      }}
    />
  )
}

export default BivNumberInput