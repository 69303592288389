/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { grayColor } from "assets/jss/material-dashboard-pro-react.jsx";

const headingStyle = {
  heading: {
    marginBottom: "30px"
  },
  rightTextAlign: {
    textAlign: "right"
  },
  leftTextAlign: {
    textAlign: "left"
  },
  centerTextAlign: {
    textAlign: "center"
  },
  title: {
    marginTop: "10px",
    color: grayColor[2],
    textDecoration: "none"
  },
  category: {
    margin: "0 0 10px"
  }
};

export default headingStyle;
