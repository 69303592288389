import React from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import BivTableAVR from "components/BivTableAVR/BivTableAVR.jsx";
import BivLimitRanges from "components/BivLimitRanges/BivLimitRanges.jsx";




const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'
class TabValidacion extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    //let miSlider = document.getElementById("sliderabL");
  }

  render() {
    
    return (
      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden'  }}>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <h4 style={{ fontWeight: 400, fontSize: 18 }}>{languages[lang].proctoringC_automaticResolutions}</h4>
            <KeyboardArrowDown />
        </div>

        <div>
          <BivTableAVR
            values={this.props.values.avr}
            onChange={(newValues)=>{
              this.setState({},() => {
                this.props.values.avr = newValues
                this.props.checkChanges()
              })
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <h4 style={{ fontWeight: 400, fontSize: 18 }}>{languages[lang].proctoringC_matrixConfiguration} </h4>
          <KeyboardArrowDown />
        </div>

        <BivLimitRanges
          title={languages[lang].proctoringC_influenceOAWLowC}
          limited={this.props.values.mve.cm}
    
          switchActive={this.props.values.mve.abA}
          switchOnChange={()=>{
            this.setState({},() => {
              this.props.values.mve.abA = !this.props.values.mve.abA
              this.props.checkChanges()
            })
          }}

          fromValue= {this.props.values.mve.abL.min}
          fromOnChange={(newValue, newValues)=>{
              this.setState({},() => {
                this.props.values.mve.abL.min = newValue
                this.props.values.mve.abV = newValues
                this.props.checkChanges()
              })
            }}

          toValue= {this.props.values.mve.abL.max}
          toOnChange={(newValue, newValues)=>{
            this.setState({},() => {
              this.props.values.mve.abL.max = newValue
              this.props.values.mve.abV = newValues
              this.props.checkChanges()
            })
          }}

          sliderName={"sliderabL"}
          sliderValues={this.props.values.mve.abV}
          sliderOnChange={(newValues)=>{
            this.props.values.mve.abV = newValues
            this.props.checkChanges()
            //this.setState({state: this.state})
          }}
        />

        <BivLimitRanges
          title={languages[lang].proctoringC_influenceOAWMediumC}
          limited={this.props.values.mve.cm}
    
          switchActive={this.props.values.mve.amA}
          switchOnChange={()=>{
            this.setState({},() => {
              this.props.values.mve.amA = !this.props.values.mve.amA
              this.props.checkChanges()
            })
          }}

          fromValue= {this.props.values.mve.amL.min}
          fromOnChange={(newValue, newValues)=>{
              this.setState({},() => {
                this.props.values.mve.amL.min = newValue
                this.props.values.mve.amV = newValues
                this.props.checkChanges()
              })
            }}

          toValue= {this.props.values.mve.amL.max}
          toOnChange={(newValue, newValues)=>{
            this.setState({},() => {
              this.props.values.mve.amL.max = newValue
              this.props.values.mve.amV = newValues
              this.props.checkChanges()
            })
          }}

          sliderName={"slideramL"}
          sliderValues={this.props.values.mve.amV}
          sliderOnChange={(newValues)=>{
            this.setState({},() => {
              this.props.values.mve.amV = newValues
              this.props.checkChanges()
            })
          }}
        />
        
        <BivLimitRanges
          title={languages[lang].proctoringC_influenceOAWHighC}
          limited={this.props.values.mve.cm}
    
          switchActive={this.props.values.mve.aaA}
          switchOnChange={()=>{
            this.setState({},() => {
              this.props.values.mve.aaA = !this.props.values.mve.aaA
              this.props.checkChanges()
            })
          }}

          fromValue= {this.props.values.mve.aaL.min}
          fromOnChange={(newValue, newValues)=>{
              this.setState({},() => {
                this.props.values.mve.aaL.min = newValue
                this.props.values.mve.aaV = newValues
                this.props.checkChanges()
              })
            }}

          toValue= {this.props.values.mve.aaL.max}
          toOnChange={(newValue, newValues)=>{
            this.setState({},() => {
              this.props.values.mve.aaL.max = newValue
              this.props.values.mve.aaV = newValues
              this.props.checkChanges()
            })
          }}

          sliderName={"slideraaL"}
          sliderValues={this.props.values.mve.aaV}
          sliderOnChange={(newValues)=>{
            this.setState({},() => {
              this.props.values.mve.aaV = newValues
              this.props.checkChanges()
            })
          }}
        />

        <BivLimitRanges
          title={languages[lang].proctoringC_influenceOCriticalCA}
          limited={this.props.values.mve.cm}
    
          switchActive={this.props.values.mve.acA}
          switchOnChange={()=>{
            this.setState({},() => {
              this.props.values.mve.acA = !this.props.values.mve.acA
              this.props.checkChanges()
            })
          }}

          fromValue= {this.props.values.mve.acL.min}
          fromOnChange={(newValue, newValues)=>{
              this.setState({},() => {
                this.props.values.mve.acL.min = newValue
                this.props.values.mve.acV = newValues
                this.props.checkChanges()
              })
            }}

          toValue= {this.props.values.mve.acL.max}
          toOnChange={(newValue, newValues)=>{
            this.setState({},() => {
              this.props.values.mve.acL.max = newValue
              this.props.values.mve.acV = newValues
              this.props.checkChanges()
            })
          }}

          sliderName={"slideracL"}
          sliderValues={this.props.values.mve.acV}
          sliderOnChange={(newValues)=>{
            this.setState({},() => {
              this.props.values.mve.acV = newValues
              this.props.checkChanges()
            })
          }}
        />

      </div>

    )

  }

}

export default TabValidacion
