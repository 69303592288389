import React from 'react'

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import BivDashboardCardListItem from 'components/BivDashboardCardListItem/BivDashboardCardListItem.jsx';

function BivDashboardCardList(props) {

    const { title, values, color, clickFunction, classes} = props

    return (

        <Card color={color} style={{ cursor: 'pointer' }} onClick={clickFunction}>
            <CardHeader color={color} stats icon >
                <p className={classes.cardCategory} style={{paddingBottom: 2, paddingTop: 2, marginBottom: 0, marginTop: 5,paddingRight: 3, fontSize: '0.7rem', color: 'white', textAlign: 'right', fontWeight: 'bold' }}>{title}</p>
            </CardHeader>

            <CardBody style={{ paddingBottom: 2, paddingTop: 2, marginBottom: 2, marginTop: 2 }}>

                {values.map((value, key) =>(
                    <BivDashboardCardListItem key={value.key} name={value.name} value={value.value} classes={classes}/>
                ))}

            </CardBody>

        </Card>    
    )

}

export default BivDashboardCardList