import React from 'react'

import BivControlAudio from "components/BivControlAudio/BivControlAudio.jsx";
import BivControlComun from "components/BivControlComun/BivControlComun.jsx";



const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

class TabAudio extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      controlExpanded: "",
    }
  }

  handleExpandClick(x) {
    if (this.state.controlExpanded === x) {
      this.setState({ controlExpanded: "" })
    } else {
      this.setState({ controlExpanded: x })
    }
  };

  render() {
    
    return (
      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden'  }}>

        <span>
          <BivControlAudio 
            values={this.props.values.sc}

            switchText={languages[lang].proctoringC_existingAmbientAIDetected}
            switchOnChange={()=>{
              if (!global.readOnlyUser) {
                this.setState({},() => {
                  this.props.values.sc.a = !this.props.values.sc.a
                  if (this.props.values.sc.a) {
                    this.setState({controlExpanded : "scA"})
                  } else {
                    this.setState({controlExpanded : ""})
                  }
                  this.props.checkChanges()
                })
              }
            }}

            expandValue={(this.state.controlExpanded === "scA") ? true : false}
            expandId="scA"
            expandOnChange={this.handleExpandClick.bind(this, "scA")}

            maxValueText={languages[lang].proctoringC_maximumAV}
            maxValueOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.sc.vm = number
                this.props.checkChanges()
              })
            }}

            incidenceCountText={languages[lang].proctoringC_numberOfTimesTMOFAITOccur}
            incidenceCountOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.sc.vw = number
                this.props.checkChanges()
              })
            }}

            initialPriorityText={languages[lang].proctoringC_initialPriority}
            initialPriorityOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.sc.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityLabel={languages[lang].proctoringC_priorityChange}
            changePriorityFromText={languages[lang].proctoringC_from}
            changePriorityFromOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.sc.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToText={languages[lang].proctoringC_timesOfAIAEGTPriority}
            changePriorityToOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.sc.t.p = number
                this.props.checkChanges()
              })
            }}
          />

          <BivControlComun
            values={this.props.values.msc}

            switchText={languages[lang].proctoringC_microphoneInputVLControl}
            switchOnChange={()=>{
              if (!global.readOnlyUser) {
                this.setState({},() => {
                  this.props.values.msc.a = !this.props.values.msc.a
                  if (this.props.values.msc.a) {
                    this.setState({controlExpanded : "mscA"})
                  } else {
                    this.setState({controlExpanded : ""})
                  }
                  this.props.checkChanges()
                })
              }
            }}
            
            expandValue={(this.state.controlExpanded === "mscA") ? true : false}
            expandId="mscA"
            expandOnChange={this.handleExpandClick.bind(this, "mscA")}

            initialPriorityOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.msc.ip = number
                this.props.checkChanges()
              })
            }}

            changePriorityFromOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.msc.t.v = number
                this.props.checkChanges()
              })
            }}
            changePriorityToOnChange={(number)=>{
              this.setState({},() => {
                this.props.values.msc.t.p = number
                this.props.checkChanges()
              })
            }}

          />


        </span>

      </div>   

    )
  }
}

export default TabAudio
