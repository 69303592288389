import * as React from 'react';
import Modal from '@mui/material/Modal';
import {  Paper } from '@material-ui/core';
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#009EFD', // Change this to your desired color
    },
    // Other palette options...
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#FFFFFF', // Set text color for contained buttons
        },
      },
    },
  },
  // Other theme options...
});

export default function NoPermission(props) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => props.close();

  return (
    props.textNoPermission?.timeout ?
    <ThemeProvider theme={theme}>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={props.active}
        onClose={handleClose}>
        <DialogTitle>Tiempo de espera agotado</DialogTitle>
        <DialogContent>
          <DialogContentText style={{paddingBottom:"15px"}}>
            Intenta buscar nuevamente:
          </DialogContentText>
          <DialogContentText>
            &nbsp; <b>•</b> Revisando tu conexión a internet.
          </DialogContentText>
          <DialogContentText>
            &nbsp; <b>•</b> Reduciendo la cantidad de datos de la búsqueda.
          </DialogContentText>
          <Button style={{marginTop:"30px"}} onClick={handleClose} primary fullWidth variant="contained">ACEPTAR</Button>
        </DialogContent>

      </Dialog>
      </ThemeProvider>
      :
      <Modal
        open={props.active}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={2} style={{
          position: 'absolute',
          width: 400,
          // border: '2px solid #000',
          borderRadius: 15,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          left: '50%',
          boxShadow: 24,
          overflow: 'hidden'
        }}>
          <div style={{ backgroundColor: '#ffa726', padding: '32px 16px 8px 16px', }}>
            <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}>Acción restringida</h3>

            <h4 style={{ color: 'white' }} id="simple-modal-description">
              Lo sentimos!

            </h4>
          </div>
          <div style={{ padding: '16px 16px 17px 16px', }}>
            {props.textNoPermission ?
              <p id="simple-modal-description">
                {props.textNoPermission}
              </p>
              :
              <p id="simple-modal-description">
                No tienes permisos suficientes para ejecutar esta acción {props.textNoPermission}.
              </p>}

            <p id="simple-modal-description">
              Si crees que se trata de un error, ponte en contacto con un administrador.
            </p>

            <div style={{ display: 'flex', justifyContent: 'end', padding: '16px 0px 0px 0px' }}>
              <Button onClick={handleClose} >Aceptar</Button>
            </div>
          </div>
        </Paper>
      </Modal>

  );
}
