import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import moment from "moment"
moment.locale("es")

function BivExportCsvFunction(csvData, fileName) {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  //const ws = XLSX.utils.json_to_sheet(csvData)
  //const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
  //const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  //const data = new Blob([excelBuffer], { type: fileType })
  //FileSaver.saveAs(data, fileName + fileExtension)

  var _positionDateField = 4

  switch (fileName) {
    case 'klarway-initial-validations':
      _positionDateField = 4
      break
    case 'klarway-data':
      _positionDateField = 4
      break
    case 'klarway-validations':
      _positionDateField = 5
      break
    default:
      _positionDateField = 4
      break
  }

  const worksheet = XLSX.utils.json_to_sheet(csvData)
  //var _positionDateField = 5//XLSX.utils.decode_col("E")
  var range = XLSX.utils.decode_range(worksheet['!ref'])

  for (var i = range.s.r + 1; i <= range.e.r; ++i) {
    var ref1 = XLSX.utils.encode_cell({ r: i, c: _positionDateField })
    worksheet[ref1].t = 'd'
    worksheet[ref1].v = moment(worksheet[ref1].v, 'DD/MM/YYYY').format('MM/DD/YYYY hh:mm:ss')
    worksheet[ref1].z = "dd/mm/yyyy"
  }

  const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }
  workbook.creator = 'klarway'
  workbook.lastModifiedBy = 'klarway-panel'

  const config = {
    bookType: 'xlsx',
    type: 'array',
    cellDates: false,
    WTF: false,
    cellStyles: true,
    dateNF: 'dd/mm/yyyy',
    cellINF: true
  }

  const excelBuffer = XLSX.write(workbook, config)
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)

}

export default BivExportCsvFunction