import React from 'react'
import Button from "components/CustomButtons/Button.jsx";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import SaveAlt from "@material-ui/icons/SaveAlt";
import moment from 'moment';
//import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

export const ExportCSV = ({ active, csvData, fileName, customFunction }) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    var range = XLSX.utils.decode_range(ws['!ref'])

    // for (var i = range.s.r + 1; i <= range.e.r; ++i) {
    //   var ref1 = XLSX.utils.encode_cell({ r: i, c: 3 })
    //   ws[ref1].t = 'd'
    //   ws[ref1].v = moment(ws[ref1].v, 'DD/MM/YYYY').format('MM/DD/YYYY hh:mm:ss')
    //   ws[ref1].z = "dd/mm/yyyy"
    // }


    for (var i = range.s.r + 1; i <= range.e.r; ++i) {
      var ref1 = XLSX.utils.encode_cell({ r: i, c: 3 })
      ws[ref1].t = 'd'
      ws[ref1].v = moment(ws[ref1].v, 'DD/MM/YYYY').format('MM/DD/YYYY hh:mm:ss')
      ws[ref1].z = "dd/mm/yyyy"
    }

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { cellDates: true, bookType: 'xlsx', type: 'array' });

    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Button
      disabled={!active}
      style={{ height: '42px' }}
      color="lightBlueKW"
      round
      endIcon={<SaveAlt />}
      onClick={() => {
        if (active) {
          if (customFunction) {
            customFunction()
          } else {
            exportToCSV(csvData, fileName)
          }
        }
      }}
    >
      <div style={{ width: "100%", textAlign: "center" }}>
      {languages[lang].button_export}
      </div>
    </Button>
  )
}