/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
// import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
import firebase from "firebase";
// import { convertCompilerOptionsFromJson } from "typescript";


import CircularProgress from '@material-ui/core/CircularProgress';

import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import db from 'db/db.jsx'

//import custom CSS for bootstrap
import './assets/css/extra-bootstrap.css'
import NoPermission from "components/NoPermission/NoPermission";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const firebaseConfig = {
  apiKey: "AIzaSyAagjAaTcIoenaBzk5sRnD3DxmG5CDODOc",
  authDomain: "eval-28570.firebaseapp.com",
  databaseURL: global.Direction_Firebase_DB,
  projectId: "eval-28570",
  storageBucket: "eval-28570.appspot.com",
  messagingSenderId: "822393729726",
  appId: "1:822393729726:web:b156c640a49097efe564ef"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const hist = createBrowserHistory();
// let myStorage = window.localStorage;
// INFO DEL USUARIO
//global.user = null

localStorage.zonaHoraria = -new Date().getTimezoneOffset() * 1000 * 60
if (!localStorage.lang) {
  localStorage.lang = "es"
}
//localStorage.lang="en"

localStorage.esSiglo = false

export default class Home extends React.Component {
  loadSettings = () => {


  }
  constructor(props) {

    super(props);
    this.state = {
      user: null,
      loaded: false,
      loading: false,
      notificationErrorUser: false,
      activeNoPermission: false,
      textNoPermission: false
    }
  }

  handleClose = () => {
    this.setState({ notificationErrorUser: false })
  }
  checkTokenFB = () => {
    let token = localStorage.fb_jwt
    if (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      jsonPayload = JSON.parse(jsonPayload)
      console.log(jsonPayload)
      if (jsonPayload.exp * 1000 - Date.now() < 1000 * 60 * 5) {
        this.refreshTokenFB()
      }
    }
  }
  refreshTokenFB = () => {
    let user = firebase.auth().currentUser
    if (user) {
      firebase.auth().currentUser.getIdToken(true).then((idToken) => {
        // Send token to your backend via HTTPS
        localStorage.setItem('fb_jwt', idToken)
        console.log('token refreshed')
        // global.loggedIn(back_jwt, idToken, this.parseJwt(back_jwt), '-')
      }).catch(function (error) {
        // Handle error
        console.log('error', error)
      });
    }
  }
  componentDidMount() {
    this.checkTokenFB()
    setInterval(() => {
      this.checkTokenFB()
    }, 1000 * 60 * 2)
    // window.location.reload(true)
    // localStorage.clear();
    if (localStorage.from) {
     
    } else {
      localStorage.from = "-"
    }

    // global.customAuth = (_data, _token, refreshtoken) => {

    global._authFirebase = (close) => {
      if (!localStorage.lang) {
        localStorage.lang = "es"
      }
      if (localStorage.from === undefined) {
        localStorage.from = "-"
      } else {
        if (localStorage.from !== 'siglo21') {
          firebase.auth().onAuthStateChanged((x) => {
            this.setState({
              user: firebase.auth().currentUser
            }, () => {

              if (firebase.auth().currentUser != null) {
                firebase.database().ref('users/' + firebase.auth().currentUser.uid)
                  .once('value', (x) => {
                    let user = x.val()

                    if (user != null) {
                      x = x.val()
                      let _e = null
                      if (x && x.e) {
                        _e = x.e
                      }

                      firebase.database().ref('environment/' + _e)
                        .once('value', (data) => {
                          global.user = { authorities: ["Biv_User_Admin"] }
                          global.environment = data.val()
                          if (firebase.auth().currentUser) {
                            //console.log('me logueo true')
                            this.setState({ loaded: true })
                          }
                        })
                    } else {
                      //console.log('genero notificacion de usuarios');
                      this.setState({ notificationErrorUser: true }, () => { })
                    }
                  })
              } else {
                //console.log('genero notificacion de usuarios');
                this.setState({ notificationErrorUser: true }, () => { })

              }
            });
          })
        }
      }
    }
    global.customAuth = (_data, _token) => {

    }
    global.loggedIn = (back_jwt, fb_jwt, rol_info, from,withoutRol) => {
      if (!localStorage.lang) {
        localStorage.lang = "es"
      }
      localStorage.USER = JSON.stringify(rol_info)
      localStorage.from = from || 'lms'
      localStorage.setItem('rol', JSON.stringify(rol_info))
      global.rol = rol_info
      global.user = { authorities: ["Biv_User_Admin"] }
      global.environment = { k: 'hbidfjkbisfjhf', n: 'conquer' }

      const validateRol = () => {
        let rol = rol_info
  
        rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
        delete rol.downloadDashboard
        delete rol.downloadExamns
        let rolAssigned = false
        for (let i in rol) {
          if (rol[i]) {
            rolAssigned = true
            break;
          }
        }
        return rolAssigned
      }
      let rolAssigned = validateRol()
      if(!rolAssigned){
        withoutRol()
        return
      }
      this.setState({ loaded: true, user: true })
    }
    global.login_oauth = (x) => {

    }
    global._loginSiglo21 = (x) => {
      if (!localStorage.lang) {
        localStorage.lang = "es"
      }

      global.user = { authorities: ["Biv_User_Admin"] }
      this.setState({ loaded: true, user: true })

    }
   

    global.showLoading = () => {
      this.setState({ loading: true })
    }

    global.hideLoading = () => {
      this.setState({ loading: false })
    }

    global.validationInMaintenance = false
    global.inicialValidationInMaintenance = false
    global.registryInMaintenance = false
    global.enrollInMaintenance = false
    global.enrollIncompleteInMaintenance = false

    global.readOnlyUser = false

    db.fillSectionStatus()


    global.showNoPermission = (x) => {
      if (x) {
        this.setState({ activeNoPermission: true, textNoPermission: x })

      }
      else {
        this.setState({ activeNoPermission: true, textNoPermission: false })

      }
    }
    global.showTimeOut=(x)=>{

    }

  }
  hideNoPermission() { }
  render() {

    return (

      <div>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.notificationErrorUser}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message="Usuario o contraseña incorrecta"
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        {this.state.loading ?
          <div style={{ position: 'absolute', backgroundColor: '#ffffff', opacity: '0.7', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', zIndex: 9999 }}>
            <CircularProgress />
          </div> : <div />}

        {(this.state.user && this.state.loaded)
          ?
          <Router history={hist}>
            <NoPermission active={this.state.activeNoPermission} textNoPermission={this.state.textNoPermission} close={() => {
              this.setState({ activeNoPermission: false })
            }}></NoPermission>
            <Switch>
              {/* <Route path="/rtl" component={RtlLayout} /> */}
              {/* <Route path="/auth" component={AuthLayout} /> */}
              <Route path="/admin" component={AdminLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </Router>
          :

          <Router history={hist}>
            <GoogleReCaptchaProvider
              reCaptchaKey={global.tokenRecaptcha}
              language="es"
              // useRecaptchaNet="[optional_boolean_value]"
              useEnterprise="true"
              useRecaptchaNet
              scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'body', // optional, default to "head", can be "head" or "body",
              }}
            >
              <Switch>
                {/* <Route path="/rtl" component={RtlLayout} /> */}
                <Route path="/auth" component={AuthLayout} />
                {/* <Route path="/admin" component={AdminLayout} /> */}
                <Redirect from="/" to="/auth" />
              </Switch>
            </GoogleReCaptchaProvider>

          </Router>}
      </div>

    )
  }
}

// export default createStore(Start, 'hola')
