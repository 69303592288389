import React, { useEffect, useState } from "react";


import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem.jsx";

import utilTable from "utilities/utilTable.jsx";
import BivPagination from 'components/BivPagination/BivPagination.jsx'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import db from "db/db.jsx";
import moment from "moment";
import { Moment } from "moment";
import utilities from "utilities/utilities.jsx";
import GridContainer from "components/Grid/GridContainer";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import Stack from "@mui/material/Stack";
import {
    Typography,
    Collapse,
    Grid,
    CircularProgress,
} from "@mui/material";
import Button from "components/CustomButtons/Button.jsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import TablePagination from '@mui/material/TablePagination';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Switch from '@mui/material/Switch';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

moment.locale("es");

export default function ActionInformation(props) {
    const d1 = moment()
        .subtract(2, "days")
        .format("YYYY-DD-MM");
    const d2 = moment().format("YYYY-DD-MM");
    const [valueSince, setValueSince] = React.useState(
        moment().subtract(7, 'days').startOf("day")
    );
    const [valueTo, setValueTo] = React.useState(
        moment().endOf("day")
    );
    const [user, setUser] = React.useState(null);
    const [date, setDate] = React.useState(null);
    const [hour, setHour] = React.useState(null);
    const [actividad, setActividad] = React.useState(null);

    const searchStartDate = moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD");
    const searchToDate = moment().format("YYYY-MM-DD");


    const [logs, setLogs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageNumber, pageNumberChange] = React.useState(1);
    const [totalRegistry, setTotalRegistry] = React.useState(0);

    function actionData(x, y, z, w) {
        if (loading) {
            return true
        }
        setLoading(true)
        if (z) {
            pageNumberChange(z)
        }
        var myHeaders = new Headers();
        let _token = localStorage.getItem("fb_jwt");
        myHeaders.append("Authorization", "Bearer " + _token);
        var formdata = new FormData();
        formdata.append("page", z || pageNumber);
        formdata.append("start", x);
        formdata.append("end", y);
        if (user) {
            formdata.append("userID", user);
        }
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            global.Direction_Back_panel + "/logsAuditor/settings/registrySettings",
            requestOptions
        )
            .then((response) => response.json())

            .then((result) => {
                const _logs = result.data;
                setTotalRegistry(_logs.total)

                const _logs2 = JSON.parse(JSON.stringify(result.data));
                const endData = []

                _logs.data.map((item, b) => {
                    const olddata = JSON.parse(item.old);
                    const newdata = JSON.parse(item.new);
                    fieldsGroup.map((sections, b) => {
                        const fields = sections.fields
                        const changes = []
                        fields.map((field, index) => {


                            let oldit
                            let newit

                            if (Array.isArray(field)) {
                                oldit = { a: olddata[field[0]], l: olddata[field[1]], sm: olddata[field[2]] }
                                newit = { a: newdata[field[0]], l: newdata[field[1]], sm: newdata[field[2]] }
                                field = "pc"
                            }
                            else {
                                oldit = olddata[field]
                                newit = newdata[field]
                            }

                            if (JSON.stringify(oldit) !== JSON.stringify(newit)) {

                                let oldlist = []
                                let newlist = []

                                let newItems = []
                                let deletedItems = []
                                if (oldit.l) {

                                    if (newit.l) {
                                        newit.l.map((a, b) => {
                                            newlist.push(a.pname)
                                        })
                                        oldit.l.map((a, b) => {
                                            oldlist.push(a.pname)
                                        })

                                        newlist.map((a) => {
                                            if (!oldlist.includes(a)) {
                                                newItems.push(a)
                                            }
                                        })

                                        oldlist.map((a) => {
                                            if (!newlist.includes(a)) {
                                                deletedItems.push(a)
                                            }
                                        })
                                    }
                                    if (newItems.length > 0 || deletedItems.length > 0) {
                                        newit.l = {}
                                        if (newItems.length > 0) {
                                            newit.l.new = newItems
                                        }
                                        if (deletedItems.length > 0) {
                                            newit.l.del = deletedItems
                                        }
                                    }

                                }

                                changes.push([field, oldit, newit])
                            }


                        })
                        if (changes.length > 0) {
                            endData.push({ ty: sections.ty, title: sections.title, changes: changes, time: item.time, rolName: item.rolName, auditor: item.auditor })
                        }
                    })
                })
                setLogs(endData);
                setLoading(false)

                return true
            })
            .catch((error) => console.log("error", error));
    }
    const [users, setUsers] = React.useState([]);

    useEffect(() => {
        db.getUsers((_res) => {
            setUsers(_res ? _res : [])
        }, () => {

        })
        actionData(valueSince.valueOf(), valueTo.valueOf());
    }, []);

    const fieldsGroup = [

        {
            ty: 2,
            fields: ["fr"],
            title: "Registro",
        },
    ];

    const texts = {

        //ambiente
        ec: 'Control de ambiente.',
        bl: "Controlar del nivel de batería.",
        cs: "Controlar cantidad de espacio libre de almacenamiento.",

        mc: "Controlar cantidad de monitores.",
        msc: "Controlar el nivel de volumen del micrófono",
        pc: "Controlar procesos abiertos.",
        vc: "Controlar cámaras virtuales",

        vm: "Controlar ejecución de máquinas virtuales.",
        fr: "Nivel de rigurosidad para el reconocimiento facial"
    };
    const [openF, setOpenF] = React.useState([]);
    const [openI, setOpenI] = React.useState([]);


    function RenderItemCategory(props) {

        const row = props.data
        const index = props.index
        const fecha = moment(row.time).format("DD/MM/YYYY");
        const hora = moment(row.time).format("HH:mm");
        return (
            <React.Fragment>
                <TableRow
                    key={1}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                    <TableCell>

                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                let openf2 = JSON.parse(JSON.stringify(openF))
                                const n = openf2.indexOf(index)
                                if (n > -1) {
                                    openf2.splice(n, 1)
                                } else {
                                    openf2.push(index)
                                }
                                setOpenF(openf2);
                            }}
                        >
                            {openF.includes(index) ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <b>{row.auditor}</b><br></br>
                        {row.rolName}
                    </TableCell>

                    <TableCell align="right">
                        {fecha}
                    </TableCell>
                    <TableCell align="right">
                        <b>{row.title}</b>
                    </TableCell>
                    <TableCell align="right">
                        {/* {ButtonTable(info[0], 1)} */}
                    </TableCell>
                </TableRow>
                {row.changes.map((item, index2) => {
                    return (<RenderItemFunction hora={hora} style={!openF.includes(index) ? { maxHeight: 0 } : {}} ty={row.ty} data={item} index={index} index2={index + "_" + index2}></RenderItemFunction>)
                })}

            </React.Fragment>
        )
    }
    function RenderItemFunction(props) {
        const row = props.data
        const index = props.index
        const index2 = props.index2
        const typeofrow = typeof row[1]
        const ty = props.ty

        // let switchStatus = (typeofrow !== "object" && row[1] !== row[2] || typeofrow === "object" && row[1].a !== row[2].a) ? ((typeofrow !== "object" && row[2]) || (typeofrow === "object" && row[2].a)) ? true : false : null

        let _old = JSON.parse(JSON.stringify(row[1]))
        let _new = JSON.parse(JSON.stringify(row[2]))
        let somekeys = ['abA', 'amA', 'aaA', 'acA']

        let oldactive = row[1]
        let newactive = row[2]

        if (ty !== 1 && _old.a) {
            oldactive = _old.a
            newactive = _new.a
            delete _old.a
            delete _new.a

            _old = JSON.stringify(_old)
            _new = JSON.stringify(_new)
        }


        let switchStatus = oldactive !== newactive ? newactive ? true : false : null
        // ((ty === 1 && oldactive !== newactive) || (ty !== 1 && oldactive !== newactive)) ? ((ty === 1 && row[2]) || (ty !== 1 && newactive)) ? true : false : null



        return (
            <React.Fragment  >
                <TableRow
                    style={!openF.includes(index) ? { height: "0px" } : {}}
                >
                    <TableCell style={!openF.includes(index) ? { height: "0px", border: 0, padding: 0 } : { padding: 5, border: 0, paddingLeft: '10px' }} colSpan={12}>
                        <Collapse in={openF.includes(index)} timeout="auto" unmountOnExit>
                            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                <Grid xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", fontSize: "small" }}>{props.hora}</Grid>
                                <Grid xs={10} style={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '100%' }}>
                                    <Box
                                        width='100%'
                                        padding={0}
                                        backgroundColor="#dddddd"
                                        borderRadius={1}
                                        container
                                        direction="row"  >
                                        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                            <Grid xs={1} style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }}>
                                                {typeofrow === "object" && _old !== _new ?
                                                    <TableCell style={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                let openi2 = JSON.parse(JSON.stringify(openI))
                                                                const n = openi2.indexOf(index2)
                                                                if (n > -1) {
                                                                    openi2.splice(n, 1)
                                                                } else {
                                                                    openi2.push(index2)
                                                                }
                                                                setOpenI(openi2);
                                                            }}
                                                        >
                                                            {openI.includes(index2) ? (
                                                                <KeyboardArrowUpIcon />
                                                            ) : (
                                                                <KeyboardArrowDownIcon />
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                    : null}
                                            </Grid>

                                            <Grid xs={8} style={{ display: 'flex', justifyContent: 'start', alignContent: "center" }}>
                                                <TableCell component="th" scope="row" style={{ fontWeight: "bold", padding: 10 }} >
                                                    {texts[row[0]]}
                                                </TableCell>
                                            </Grid>
                                            <Grid xs={3} style={{ display: 'flex', justifyContent: 'end', alignContent: "center" }}>
                                                {/* {(switchStatus || switchStatus === false) ?
                                                    <TableCell xs={3} component="th" scope="row" style={{ fontWeight: "bold", padding: 0 }}>
                                                        {switchStatus ? "On" : "Off"} <Switch disabled checked={switchStatus} />
                                                    </TableCell>
                                                    : null} */}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </TableCell>
                </TableRow>

                {
                    typeof row === "object" && _old !== _new && openF.includes(index) ?

                        <RenderSubItem hora={props.hora} style={!openI.includes(index2) ? { maxHeight: 0 } : {}} index={index2} ty={props.ty} item={row}></RenderSubItem>
                        : null
                }
            </React.Fragment >
        )

    }

    const getColorText = (value) => {

        return !value
            ? "#20dfb1"
            : "#ffe1b5";
    };

    const getColorBg = (value) => {

        return !value ?
            "#9cffe7"
            : "#ff9800";

    };

    const getText = (value) => {

        return !value ? "TOLERANTE"
            : "ESTRICTO";
    };

    const ButtonTable = (value) => (
        <Button
            simple={false}
            size="sm"
            style={{ border: "solid", color: getColorText(value), backgroundColor: getColorBg(value), fontWeight: "bold" }}
            disabled
        >
            {getText(value)}
        </Button>
    );
    const ButtonTable4 = (value) => (
        <Button
            simple={false}
            size="sm"
            style={{ border: "solid", color: getColorText4(value), backgroundColor: getColorBg4(value), fontWeight: "bold" }}
            disabled
        >
            {getText4(value)}
        </Button>
    );
    const getColorText4 = (value) => {

        return value == 0.27
            ? "#20dfb1"
            : value == 0.35
                ? "#f3e32d"
                : value == 0.43
                    ? "#ffe1b5"
                    : "#ffbfba";
    };

    const getColorBg4 = (value) => {

        return value == 0.27
            ? "#9cffe7"
            : value == 0.35
                ? "#fff9af"
                : value == 0.43
                    ? "#ff9800"
                    : "#f44336";
    };

    const getText4 = (value) => {

        return value == 0.27
            ? "BAJO"
            : value == 0.35
                ? "MEDIO"
                : value == 0.43
                    ? "ALTO"
                    : "CRÍTICO";
    };

    function RenderSubItem(props) {
        const item = props.item
        const index = props.index
        const _old = item[1]
        const _new = item[2]
        const ty = props.ty
        return (<TableRow style={!openI.includes(index) ? { height: "0px" } : {}} >
            <TableCell style={!openI.includes(index) ? { height: "0px", border: 0, padding: 0 } : { padding: 5, paddingLeft: '50px', border: 0 }} colSpan={12}>
                <Collapse in={openI.includes(index)} timeout="auto" unmountOnExit>
                    <Box marginTop={1}
                        padding={0}

                        borderRadius={1}
                        container
                        direction="row">
                        <Timeline
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}
                        >

                            {_old.co && JSON.stringify(_new.co) !== JSON.stringify(_old.co) ?
                                <TimelineItem style={{ justifyContent: 'center', alignItems: 'center', marginTop: '-15px' }}>
                                    <TimelineSeparator>
                                        <TimelineDot style={{ padding: '2px', }} />
                                        {/* <TimelineConnector /> */}
                                    </TimelineSeparator>

                                    <TimelineContent style={{ marginTop: 0, paddingTop: 0 }}>
                                        <Grid
                                            marginTop={1}
                                            style={{ marginTop: 0, paddingTop: 0 }}
                                            padding={0}
                                            backgroundColor="#e0e0e0"
                                            borderRadius={1}
                                            container
                                            direction="row"
                                        >
                                            <Grid style={{ display: 'flex', justifyContent: 'start', padding: 10, fontWeight: "bold" }} item xs={7}>
                                                <Typography variant="subtitle2" style={{ margin: 0, fontSize: '12px' }} gutterBottom>Nivel de rigurosidad para el reconocimiento facial</Typography>
                                            </Grid>


                                            {/* <Grid style={{ display: 'flex', justifyContent: 'center', alignContent: "center", alignItems: "center" }} item xs={5}> */}
                                            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: "center", color: "#aaaeb3", fontWeight: "bold" }} item xs={2}>

                                                {_old.co === 0.27 || _old.co === 0.35 || _old.co === 0.43 ? ButtonTable4(_old.co) : parseInt(_old.co * 100)}
                                            </Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} item xs={1}><ChevronRightIcon style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }} /></Grid>
                                            <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: "center", fontWeight: "bold" }} item xs={2}>
                                                {_new.co === 0.27 || _new.co === 0.35 || _new.co === 0.43 ? ButtonTable4(_new.co) : parseInt(_new.co * 100)}
                                            </Grid>
                                            {/* </Grid> */}

                                        </Grid>
                                    </TimelineContent>
                                </TimelineItem>
                                : null}
                        </Timeline>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow >)
    }
    const fmap = () => {


        return logs.map((row, index) => (<RenderItemCategory index={index} data={row}></RenderItemCategory>))


    }



    const handleChange = (event) => {
        if (event.target.value == "todos") {
            setUser(null);

        }
        else {
            setUser(event.target.value);
        }
    };

    // const rows = [logs.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map]

    return (
        <div style={{ display: "flex", flexDirection: "column", maxHeight: '100%' }}>
            <GridContainer>
                <GridItem xs={3} sm={3} md={3} lg={3} xl={3} style={{ display: 'flex', justifyContent: 'center', alignContent: "center" }}>
                    <Typography variant="h5" bold style={{ margin: 15 }}>
                        {"Registro"}
                    </Typography>
                </GridItem>
                <GridItem xs={8} sm={8} md={8} lg={8} xl={8} />
                {/* <GridItem  xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignContent: "center", alignItems: "center" }}>
        <ManageSearchOutlinedIcon cursor="pointer" onClick={() => {
          actionData(valueSince.valueOf(), valueTo.valueOf())
        }} />
        </GridItem> */}
                <GridItem xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignContent: "center", alignItems: "center", marginLeft: "inherit" }}>
                    <CachedOutlinedIcon cursor="pointer" onClick={() => {
                        actionData(valueSince.valueOf(), valueTo.valueOf(),1)
                    }} />
                </GridItem>
            </GridContainer>

            <GridContainer style={{ paddingLeft: 15, paddingRight: 15 }}>
                {/* Usuario */}


                {/** data range picker */}
                <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={user}
                            label="Usuario"
                            onChange={handleChange}
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>

                            {users.map((a, b) => (
                                <MenuItem key={b} value={a.id}>{a.email}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>

                </GridItem>
                <GridItem
                    xs={12}
                    md={4}
                >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {/* <Stack direction="row" spacing={3}> */}
                        <DatePicker
                            disableFuture
                            label="Desde"
                            openTo="day"
                            views={["day", "month", "year"]}
                            inputFormat="DD/MM/YYYY"
                            value={valueSince}
                            onChange={(newValue) => {
                                setValueSince(newValue);
                            }}
                            renderInput={(params) => <TextField color="grayColor" variant="outlined" {...params} />}
                        />

                        {/* </Stack> */}
                    </LocalizationProvider>
                </GridItem>
                <GridItem
                    xs={12}
                    md={4}
                >


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        {/* <Stack direction="row" spacing={3}> */}
                        <DatePicker
                            disableFuture
                            label="Hasta"
                            openTo="day"
                            inputFormat="DD/MM/YYYY"
                            views={["day", "month", "year"]}
                            value={valueTo}
                            onChange={(newValue) => {
                                setValueTo(moment(newValue).endOf('day'));
                            }}
                            renderInput={(params) => <TextField variant="outlined" {...params} />}
                        />
                        {/* </Stack> */}
                    </LocalizationProvider>
                </GridItem>
            </GridContainer>

            {/* tabla */}
            <TableContainer style={{ padding: 20, background: "none" }}>
                <Table aria-label="simple table">
                    <TableHead style={{ borderStyle: "none none solid none" }}>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Usuario</TableCell>
                            <TableCell align="right">Fecha</TableCell>
                            <TableCell align="right">Categoría</TableCell>
                            {/* <TableCell align="right">&nbsp;</TableCell> */}
                        </TableRow>
                    </TableHead>
                    {
                        loading || (!logs || logs.length < 1) ? null
                            :
                            <TableBody style={{ padding: 0, margin: 0 }}>{fmap()}</TableBody>
                    }
                </Table>
                {
                    loading ?
                        <div style={{ width: '100%', display: "flex", justifyContent: "center", paddingTop: 20, paddingBottom: 20 }}>
                            <CircularProgress /></div> :
                        null
                }
                {!loading && (!logs || logs.length < 1) ?



                    <Typography style={{ width: "100%", textAlign: "center", paddingTop: '10px', paddingBottom: '5px', }} variant="subtitle2" gutterBottom>No se encontraron modificaciones.</Typography> : null

                }
                {totalRegistry > 0 ?
                    <div
                        style={{ paddingBottom: 20, width: "100%" }}
                    >
                        <BivPagination

                            onClickPrev={() => {
                                if (pageNumber !== 1) {

                                    // pageNumberChange()
                                    actionData(valueSince.valueOf(), valueTo.valueOf(), (pageNumber - 1))
                                }
                            }}
                            onClickPage={(value) => {
                                // pageNumberChange(value)
                                actionData(valueSince.valueOf(), valueTo.valueOf(), value)
                            }}
                            onClickNext={() => {
                                if (pageNumber !== totalRegistry) {
                                    // pageNumberChange(pageNumber + 1)
                                    actionData(valueSince.valueOf(), valueTo.valueOf(), (pageNumber + 1))
                                }
                            }}
                            page={pageNumber}
                            totalRegistry={totalRegistry}
                        />
                    </div>
                    : null}

            </TableContainer>

        </div>
    );
}
