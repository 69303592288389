/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react"
import PropTypes from "prop-types"


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import Baja from "@material-ui/icons/SentimentVerySatisfiedOutlined"
import Media from "@material-ui/icons/SentimentSatisfiedOutlined"
import Alta from "@material-ui/icons/SentimentDissatisfiedOutlined"
import Critica from "@material-ui/icons/SentimentVeryDissatisfiedOutlined"

import withStyles from "@material-ui/core/styles/withStyles"
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"

import Card from "components/Card/Card.jsx"
import BivDashboardCard from 'components/BivDashboardCard/BivDashboardCard.jsx'
import BivDashboardCardIcon from 'components/BivDashboardCardIcon/BivDashboardCardIcon.jsx'
import BivDashboardCardList from 'components/BivDashboardCardList/BivDashboardCardList.jsx'

//NEW VALIDATION import KWDashboardCardListItem from 'components/KWDashboardCardListItem/KWDashboardCardListItem.jsx';

import BivPagination from 'components/BivPagination/BivPagination.jsx'

import BivButtonFind from 'components/BivButtonFind/BivButtonFind.jsx'

import BivExportCsvButton from 'components/BivExportCsvButton/BivExportCsvButton.jsx'
import BivExportCsvFunction from "components/BivExportCsvFunction/BivExportCsvFunction.jsx"

//import {dailySalesChart,emailsSubscriptionChart,completedTasksChart,} from "variables/charts"

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import "assets/jss/material-dashboard-pro-react.jsx";


import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
//import cellEditFactory from "react-bootstrap-table2-editor"
import BootstrapTable from "react-bootstrap-table-next"

import * as FileSaver from 'file-saver';

import db from 'db/db.jsx'
import utilities from 'utilities/utilities.jsx'
import columnTable from 'utilities/columnTable.jsx'
import utilData from "utilities/utilData.jsx"

import moment from "moment"
import Problem from "@material-ui/icons/ReportProblem"
import { Paper } from "@material-ui/core"
moment.locale("es")




const languages = require('../../assets/translate/translate_panel_dashboard.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

class Dashboard extends React.Component {
  IsMounted = false

  constructor(props) {

    super(props)
    let rolAssigned = this.validateRol()
    if (rolAssigned == false) {
      this.state = { rolAssigned: rolAssigned, }
    } else {

      this.state = {
        rolAssigned: rolAssigned,
        //IsMounted
        IsMounted: true,

        //Show loading icon
        loadingToExport: false,

        //Show loading in table
        loadingTable: true,

        //Store data cards
        dataCards: utilities.getCardsModel(),
        loadingCards: true,

        //Store pagination values
        pageNumber: 1,
        totalRegistry: 0,

        //Store pagination order value
        order: 'desc',
        column: 'createdAt',

        //Store card selected and data
        cardSelected: 1,
        tableTitle: "",
        tableColumns: [],
        tableData: [],

        //Search Date From
        searchStartDate: moment().startOf('day').subtract(30, 'days').format('YYYY-MM-DD'),
        //Search Date To
        searchEndDate: moment().startOf('day').startOf('day').add(1, 'days').format('YYYY-MM-DD'),

        //Top 3 Incidences
        dataBivTop3Incidence: [{ key: 1, name: '-', value: '-', }, { key: 2, name: '-', value: '-', }, { key: 3, name: '-', value: '-', }],

        //Data for Graphs
        graphData1: { labels: ['L', 'M', 'M', 'J', 'V', 'S', 'D'], series: [[0, 0, 0, 0, 0, 0, 0]], },
        graphData2: { labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'], series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]], },

        //Graphs counters
        graphCount1: 0,
        graphCount2: 0,
        graphCount3: 0,

      }
    }

  }

  validateRol() {
    let rol = localStorage.rol

    try {
      rol = JSON.parse(rol)
    }
    catch (_e) {
      rol = {}
    }

    rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
    delete rol.downloadDashboard
    delete rol.downloadExamns
    let rolAssigned = false
    for (let i in rol) {
      if (rol[i]) {
        rolAssigned = true
        break;
      }
    }
    return rolAssigned
  }
  componentDidMount() {
    let rolAssigned = this.validateRol()

    this._isMounted = true
    if (rolAssigned) {
      this.getDashBoardData()
    }
  }

  getDashBoardData() {
    this.getDashboardCards()
    this.getDashBoardTable()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getDashboardCards() {

    let _result = await db.getDashboardCards(
      this.state.searchStartDate,
      this.state.searchEndDate
    )

    let _finalResult = await utilities.fillCardsModel(_result)

    //let _dataBivTop3Incidence = this.state.dataBivTop3Incidence
    console.log(
      _finalResult
    )
    let _dataBivTop3Incidence = utilData.prepateTop3Incidences(_finalResult)
    console.log(
      _dataBivTop3Incidence
    )
    if (this._isMounted) {
      this.setState({
        loadingCards: false,
        graphCount3: _finalResult.totalAnomalies,
        dataCards: _finalResult,
        dataBivTop3Incidence: _dataBivTop3Incidence,
      })
    }

  }

  async getDashBoardTable() {

    let _preResult = await db.getDashboardTable(
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.pageNumber,
      this.state.cardSelected,
      this.state.order,
      this.state.column
    )

    let _page = _preResult.page
    let _totalRegistry = _preResult.total

    let _result = _preResult.data
    _result = await utilData.prepareDashDataToTable(_result)
    if (this._isMounted) {
      this.setState({
        pageNumber: _page,
        totalRegistry: _totalRegistry,
        loadingTable: false,
        tableTitle: utilities.getTitle(this.state.cardSelected),
        tableColumns: columnTable.getTableDashboardExamns(this.sortFunction.bind(this)),
        tableData: _result,
      })
    }
  }



  async getDataDashboardToExport() {

    let _result = await db.getDashboardTableToExport(
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.cardSelected
    )

    let _dataToExport = await utilData.prepareDataToExport(_result, 'Dashboard')

    if (this._isMounted) {
      this.setState({
        loadingToExport: false
      }, BivExportCsvFunction(_dataToExport, 'klarway-data'))
    }
  }

  async getDataDashboardToLink() {

    let _result = await db.getDashboardTableToLink(
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.cardSelected
    )

    if (_result.link !== "") {
      FileSaver.saveAs(_result.link);
    }

    if (this._isMounted) {
      this.setState({
        loadingToExport: false
      })
    }
  }

  sortFunction(_field, _order) {
    if (this._isMounted) {
      this.setState({
        loadingTable: true,
        order: _order,
        column: _field,
      }, this.getDashBoardTable)
    }
  }
  findfunc = () => {
    this.setState({
      pageNumber: 1,
      loadingTable: true,
      loadingCards: true,
    }, this.getDashBoardData)

  }

  render() {

    const { classes } = this.props

    return (

      <div>
        {!this.state.rolAssigned ?
          <div style={{ paddingLeft: 20, paddingRight: 20, top: 30, position: 'relative' }}>
            <Paper elevation={2} style={{
              position: 'absolute',
              width: '100%',
              // border: '2px solid #000',
              borderRadius: 15,
              top: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              left: '50%',
              boxShadow: 24,
              overflow: 'hidden'
            }}>
              <div style={{ backgroundColor: '#01796b', padding: '32px 16px 8px 16px', }}>
                <h3 id="simple-modal-title" style={{ marginTop: 0, color: 'white', fontWeight: "bold" }}> Perfil creado exitosamente!</h3>
              </div>
              <div style={{ padding: '16px 16px 17px 16px', }}>
                <h5 id="simple-modal-description" style={{ marginTop: 0, color: 'black' }}> Podrás navegar en el Panel, una vez que tu Institución asigne los permisos correspondientes a tu Usuario.</h5>


              </div>

            </Paper>
            <p></p>
          </div>
          :
          <div>

            {this.state.loadingToExport ?
              <div style={{ position: 'fixed', left: 0, top: 0, backgroundColor: '#ffffff', opacity: '0.7', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', zIndex: 9999 }}>
                <CircularProgress />
              </div>
              :
              <div />
            }
            <div>
              {/* Container Fecha de Busqueda.*/}
              <GridContainer>

                <GridItem xs={12} sm={6} md={2} lg={2} xl={2} style={{ display: "flex", alignItems: "center" }}>
                  <h1 style={{ fontSize: 16, margin: 0 }}>{languages[lang].dash_filterDate}</h1>
                </GridItem>

                <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>

                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    label={languages[lang].dash_from}
                    type="date"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    color="secondary"
                    value={this.state.searchStartDate}
                    onChange={(x) => {
                      this.setState({ searchStartDate: x.target.value })
                    }}
                    onKeyPress={
                      (ev) => {
                        utilities.elEnter(ev, this.findfunc)
                      }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={6} md={3} lg={3} xl={3}>
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    label={languages[lang].dash_to}
                    type="date"
                    variant="outlined"
                    size="small"
                    margin="dense"
                    color="secondary"
                    value={this.state.searchEndDate}
                    onChange={(x) => {
                      this.setState({ searchEndDate: x.target.value })
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onKeyPress={
                      (ev) => {
                        utilities.elEnter(ev, this.findfunc)
                      }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6} md={2} lg={2} xl={2}>
                  <BivButtonFind
                    onClick={() => {
                      this.findfunc()
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6} md={2} lg={2} xl={2}>
                  <BivExportCsvButton
                    active={!this.state.loadingToExport}
                    csvData={[]}
                    fileName='klarway-data'
                    customFunction={true}
                    onClickCustom={() => {
                      this.setState({
                        loadingToExport: true
                      }, () => {
                        //this.getDataDashboardToExport()
                        this.getDataDashboardToLink()
                      })
                    }}
                  />
                </GridItem>

              </GridContainer>

              {/* Paneles de Examenes con validaciones bajas, medias, altas y criticas. */}
              <GridContainer>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <BivDashboardCardIcon
                    loading={this.state.loadingCards}
                    title={languages[lang].dash_low}
                    //value={(this.state.arrIncidenciasBajas !== null) ? this.state.arrIncidenciasBajas.length : 0}
                    value={this.state.dataCards.validations[0].quantity}
                    description={languages[lang].dash_lowValidationExams}
                    icon={<Baja style={{ width: "100%" }} />}
                    color="greenBiv"
                    clickFunction={() => {
                      this.setState({
                        pageNumber: 1,
                        loadingTable: true,
                        cardSelected: 1,
                      }, this.getDashBoardTable)
                    }}
                    classes={classes} />
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <BivDashboardCardIcon
                    loading={this.state.loadingCards}
                    title={languages[lang].dash_medium}
                    //value={(this.state.arrIncidenciasMedias !== null) ? this.state.arrIncidenciasMedias.length : 0}
                    value={this.state.dataCards.validations[1].quantity}
                    description={languages[lang].dash_mediumValidationExams}
                    icon={<Media style={{ width: "100%" }} />}
                    color="yellowBiv"
                    clickFunction={() => {
                      this.setState({
                        pageNumber: 1,
                        loadingTable: true,
                        cardSelected: 2,
                      }, this.getDashBoardTable)
                    }}
                    classes={classes} />
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <BivDashboardCardIcon
                    loading={this.state.loadingCards}
                    title={languages[lang].dash_high}
                    //value={(this.state.arrIncidenciasAltas !== null) ? this.state.arrIncidenciasAltas.length : 0}
                    value={this.state.dataCards.validations[2].quantity}
                    description={languages[lang].dash_highValidationExams}
                    icon={<Alta style={{ width: "100%" }} />}
                    color="orangeBiv"
                    clickFunction={() => {
                      this.setState({
                        pageNumber: 1,
                        loadingTable: true,
                        cardSelected: 3,
                      }, this.getDashBoardTable)
                    }}
                    classes={classes} />
                </GridItem>

                <GridItem xs={12} sm={6} md={6} lg={3}>
                  <BivDashboardCardIcon
                    loading={this.state.loadingCards}
                    title={languages[lang].dash_critical}
                    //value={(this.state.arrIncidenciasCriticas !== null) ? this.state.arrIncidenciasCriticas.length : 0}
                    value={this.state.dataCards.validations[3].quantity}
                    description={languages[lang].dash_criticalValidationExams}
                    icon={<Critica style={{ width: "100%" }} />}
                    color="redBiv"
                    clickFunction={() => {
                      this.setState({
                        pageNumber: 1,
                        loadingTable: true,
                        cardSelected: 4,
                      }, this.getDashBoardTable)
                    }}
                    classes={classes} />
                </GridItem>

              </GridContainer>

              {/*Paneles con cantidad de examenes validos, aplazados y con solicitud de sanción.*/}
              <GridContainer>

                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GridContainer>

                    {/* Examenes válidos.*/}
                    <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>
                      {/* <BivDashboardCard validation={5} _onClick={this.cardClick.bind(this, 5) } value={this.state.auditSuccess} classes={classes} />*/}
                      <BivDashboardCard
                        loading={this.state.loadingCards}
                        title={"Válidos"}
                        //value={(this.state.arrAuditoriaValida !== null) ? this.state.arrAuditoriaValida.length : 0}
                        value={this.state.dataCards.results[0].quantity ? this.state.dataCards.results[0].quantity : 0}
                        description={languages[lang].dash_validExams}
                        color="lightBlueKW"
                        clickFunction={() => {
                          this.setState({
                            pageNumber: 1,
                            loadingTable: true,
                            cardSelected: 6,
                          }, this.getDashBoardTable)
                        }}
                        classes={classes} />
                    </GridItem>

                    {/* Con solicitud de sanción.*/}
                    <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>
                      <BivDashboardCard
                        loading={this.state.loadingCards}
                        title={languages[lang].dash_penaltyRequest}
                        //value={(this.state.arrAuditoriaSancion !== null) ? this.state.arrAuditoriaSancion.length : 0}
                        value={this.state.dataCards.results[1].quantity ? this.state.dataCards.results[1].quantity : 0}
                        description={languages[lang].dash_penaltyRequestExams}
                        color="lightBlueKW"
                        clickFunction={() => {
                          this.setState({
                            pageNumber: 1,
                            loadingTable: true,
                            cardSelected: 5,
                          }, this.getDashBoardTable)
                        }}
                        classes={classes} />
                    </GridItem>

                    {/* Llamado de atención. NEWVALIDATION
                <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>
                  <BivDashboardCard
                    loading={this.state.loadingCards}
                    title="Llamado de Atención"
                    //value={(this.state.arrAuditoriaSancion !== null) ? this.state.arrAuditoriaSancion.length : 0}
                    value={this.state.dataCards.results[3].quantity ? this.state.dataCards.results[3].quantity : 0}
                    description="Examenes con llamado de atención."
                    color="info"
                    clickFunction={() => {
                      this.setState({
                        pageNumber: 1,
                        loadingTable: true,
                        cardSelected: 8,
                      }, this.getDashBoardTable)
                    }}
                    classes={classes} />
                </GridItem> */}

                    {/* Examenes aplazados.*/}
                    <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>
                      <BivDashboardCard
                        loading={this.state.loadingCards}
                        title={languages[lang].dash_failed}
                        //value={(this.state.arrAuditoriaAplazada !== null) ? this.state.arrAuditoriaAplazada.length : 0}
                        value={this.state.dataCards.results[2].quantity ? this.state.dataCards.results[2].quantity : 0}
                        description={languages[lang].dash_failedExams}
                        color="lightBlueKW"
                        clickFunction={() => {
                          this.setState({
                            pageNumber: 1,
                            loadingTable: true,
                            cardSelected: 7,
                          }, this.getDashBoardTable)
                        }}
                        classes={classes} />
                    </GridItem>

                    {/* Tabla TOP anomalías.*/}
                    <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>
                      <BivDashboardCardList
                        loading={this.state.loadingCards}
                        title={languages[lang].dash_top3anomalies}
                        values={this.state.dataBivTop3Incidence}
                        color="darkVioletBiv"
                        clickFunction={() => {
                          this.setState({
                            cardSelected: 9,
                            pageNumber: 1,
                            totalRegistry: 1,
                            tableTitle: utilities.getTitle(9),
                            tableColumns: columnTable.getTableIncidences(),
                            tableData: this.state.dataCards.counters
                          })
                        }}
                        classes={classes}
                      />
                    </GridItem>

                  </GridContainer>
                </GridItem>

              </GridContainer>

              {/* Nueva Tabla de detalle.*/}
              {this.state.tableData.length > 0 ?

                <GridContainer>
                  <GridItem xs={12}>
                    <Card>

                      {/* Cabezera de Tabla */}
                      <CardHeader>
                        <h4 className={classes.cardIconTitle}>
                          {this.state.tableTitle}
                        </h4>
                      </CardHeader>

                      <div>
                        {/* Datos de Tabla , overflow: "scroll" */}
                        <CardBody style={{ maxHeight: 600, overflow: "auto" }}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={12} md={12} style={{ minWidth: 1050 }}>
                              <BootstrapTable
                                bootstrap4
                                keyField="key"
                                data={this.state.tableData}
                                columns={this.state.tableColumns}
                                striped
                                bordered={true}
                                wrapperClasses="table-responsive"
                                classes='scrollable-table'
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>

                        <BivPagination
                          onClickPrev={() => {
                            if (this.state.pageNumber !== 1) {
                              this.setState({
                                loadingTable: true,
                                pageNumber: this.state.pageNumber - 1
                              }, this.getDashBoardTable)
                            }
                          }}
                          onClickPage={(value) => {
                            this.setState({
                              loadingTable: true,
                              pageNumber: value
                            }, this.getDashBoardTable)
                          }}
                          onClickNext={() => {
                            if (this.state.pageNumber !== this.state.totalRegistry) {
                              this.setState({
                                loadingTable: true,
                                pageNumber: this.state.pageNumber + 1
                              }, this.getDashBoardTable)
                            }
                          }}
                          page={this.state.pageNumber}
                          totalRegistry={this.state.totalRegistry}
                        />

                      </div>

                    </Card>
                  </GridItem>
                </GridContainer>

                :

                <GridContainer>
                  <GridItem xs={12}>
                    <Card>
                      {/* Cabezera de Tabla */}
                      <CardHeader>

                        {/*(this.state.tableIcon != null) ?
                          <CardIcon color={this.state.tableIconColor}>
                            {this.state.tableIcon}
                          </CardIcon>
                          :
                          <div />
                        */}

                        <h4 className={classes.cardIconTitle}>
                          {this.state.tableTitle}
                        </h4>
                      </CardHeader>

                      {/* Datos de Tabla , overflow: "scroll" */}
                      <CardBody style={{ maxHeight: 465, overflow: "auto" }}>
                        <GridContainer justify="space-between">
                          <GridItem xs={12} style={{ minWidth: 1050 }}>
                            <div
                              style={{
                                height: 250,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                flexDirection: "column",
                              }}>
                              {
                                (this.state.loadingTable === true) ?
                                  <CircularProgress />
                                  :
                                  <div>
                                    <Problem
                                      style={{ fontSize: 50, marginBottom: 10 }}
                                    />
                                    <div style={{ fontSize: 19 }}>
                                      {languages[lang].dash_noDTD}
                                    </div>
                                  </div>
                              }
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>

              }

              {/* Graficos Nuevos.*/}
              {/* Validaciones semanales.*/}
              {/* <GridContainer>

            
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card chart>
                <CardHeader color="violetBiv"
                  style={{ height: "180px" }}
                >
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={this.state.graphData1}
                    type="Line"
                  //options={dailySalesChart.options}
                  //listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>{languages[lang].dash_dailyValidations}</h4>
                  <p className={classes.cardCategory}>
                    <span className={classes.successText}>
                      <Visibility className={classes.upArrowCardCategory} /> {this.state.graphCount1}
                    </span>{" "}
                    {languages[lang].dash_inTLW}
                    </p>
                </CardBody>

                <CardFooter chart>
                
                </CardFooter>

              </Card>
            </GridItem> */}

              {/* Validaciones mensuales.*/}
              {/* <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card chart>
                <CardHeader color="greenBiv"
                  style={{ height: "180px", paddingTop: 25 }}
                >
                  <ChartistGraph
                    className="ct-chart-white-colors"
                    data={this.state.graphData2}
                    type="Bar"
                  //options={emailsSubscriptionChart.options}
                  //responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  //listener={emailsSubscriptionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>{languages[lang].dash_monthlyValidations}</h4>
                  <p className={classes.cardCategory}>
                    <span className={classes.successText}>
                      <Visibility className={classes.upArrowCardCategory} /> {this.state.graphCount2}
                    </span>{" "}
                    {languages[lang].dash_inTLY}
                    </p>
                </CardBody>
                <CardFooter chart>
                 
                </CardFooter>
              </Card>
            </GridItem> */}

              {/* Tabla TOP anomalías. NEWVALIDATION
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <Card chart>
                <CardHeader color="darkVioletBiv"
                  style={{ height: "180px" }}
                  onClick={() => {
                    this.setState({
                      cardSelected: 9,
                      pageNumber: 1,
                      totalRegistry: 1,
                      tableTitle: utilities.getTitle(9),
                      tableColumns: columnTable.getTableIncidences(),
                      tableData: this.state.dataCards.counters
                    })
                  }}
                >
                  {this.state.dataBivTop3Incidence.map((value, key) => (
                    <KWDashboardCardListItem key={value.key} name={value.name} value={value.value} classes={classes} />
                  ))}

                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Top 3 anomalías</h4>
                  <p className={classes.cardCategory}>
                    <span className={classes.successText}>
                      <Visibility className={classes.upArrowCardCategory} /> {this.state.graphCount3}
                    </span>{" "}
                      En el período seleccionado.
                    </p>
                </CardBody>
                <CardFooter chart>
                </CardFooter>
              </Card>
            </GridItem>
            */}

              {/* </GridContainer> */}

            </div>
          </div>
        }
      </div >

    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(dashboardStyle)(Dashboard)
