import React from 'react'

import Baja from "@material-ui/icons/SentimentVerySatisfiedOutlined";
import Media from "@material-ui/icons/SentimentSatisfiedOutlined";
import Alta from "@material-ui/icons/SentimentDissatisfiedOutlined";
import Critica from "@material-ui/icons/SentimentVeryDissatisfiedOutlined";
import Pendiente from '@material-ui/icons/Update';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';




const languages = require('../../assets/translate/translate_button.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

/* 
* Recibe props "value"
* value [1-4] indica el tipo
*/

function BivPrioritySentiment(props) {

  let {
    value,
    recovery,
    videos
  } = props

  // if(recovery==5){
  //   value='errorrecovery'
  // }
  // if(recovery==0 && !videos){
  //   value=0
  // }
  switch (value) {
    case 0:
      return (
        <div>
          <Pendiente style={{ fontSize: 28 }}></Pendiente>
          <div>{languages[lang].button_processing}</div>
        </div>
      )
    case 1:
      return (
        <div>
          <Baja style={{ fontSize: 28 }}></Baja>
          <div>{languages[lang].button_low}</div>
        </div>
      )
    case 2:
      return (
        <div>
          <Media style={{ fontSize: 28 }}></Media>
          <div>{languages[lang].button_medium}</div>
        </div>
      )
    case 3:
      return (
        <div>
          <Alta style={{ fontSize: 28 }}></Alta>
          <div>{languages[lang].button_high}</div>
        </div>
      )
    case 4:
      return (
        <div>
          <Critica style={{ fontSize: 28 }}></Critica>
          <div>{languages[lang].button_critic}</div>
        </div>
      )
    case 5:
      return (
        <div>
          <CloseOutlinedIcon style={{ fontSize: 28 }}></CloseOutlinedIcon>
          <div>Sin evidencia</div>
        </div>
      )
    case 6:
      return (
        <div>
          <Pendiente style={{ fontSize: 28 }}></Pendiente>
          <div>{languages[lang].button_processing}</div>
        </div>
      )
  
    default:
      return (<div />)
  }

}

export default BivPrioritySentiment
