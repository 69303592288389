/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";


import CircularProgress from '@material-ui/core/CircularProgress';


//import custom CSS for bootstrap
import './assets/css/extra-bootstrap.css'

import Home from "Home";
// import {exportarprueba} from "./utilities/utilities.jsx"
import utilities from 'utilities/utilities.jsx'

const hist = createBrowserHistory();
// utilities.exportarprueba()
// let myStorage = window.localStorage;
// INFO DEL USUARIO
//global.user = null

localStorage.zonaHoraria = -new Date().getTimezoneOffset() * 1000 * 60
if (!localStorage.lang) {
  localStorage.lang = "es"
}
//localStorage.lang="en"

localStorage.esSiglo = false
// const envprops = localStorage.envprops?JSON.parse(localStorage.envprops):null
//   global.backurl = envprops.backurl
//   global.portRestBack = envprops.portRestBack
//   global.portIntegration = envprops.portIntegration
//   global.loginType = envprops.loginType
//   global._lms_ = envprops._lms_
//   global._uni_ = envprops._uni_
//   global.env = envprops.env
//   global._uniName_ = envprops._uniName_
//   global.Direction_Firebase_DB = envprops.Direction_Firebase_DB
//   global.Direction_Back_Login = envprops.Direction_Back_Login
//   global.Direction_Back_panel = envprops.Direction_Back_panel
//   global.Direction_LoginOauth = envprops.Direction_LoginOauth

const setVariables = (props, callback) => {
  localStorage.envprops = JSON.stringify(props)
  callback()
}
class Start extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoading: global.backurl?false:true,
    }
    global.logincode=window.location.href.split('code=')[1]
    
  global.redirect=window.location.href.split('.com/admin/')[1]
  // global.redirect=window.location.href.split('3000/admin/')[1]
  }


  render() {
    if (this.state.firstLoading) {
      return (
        <div style={{ position: 'absolute', backgroundColor: '#ffffff', opacity: '0.7', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', zIndex: 9999 }}>
          <CircularProgress />
        </div>
      )
    }
    return (
      <Home></Home>
      // <div>algo</div>
    )
  }
}

ReactDOM.render(
  <Start></Start>,
  document.getElementById("root")
);
// export default createStore(Start, 'hola')
