/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
//bunker /// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import firebase from "firebase";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this._path = ''
    let _user = firebase.auth().currentUser
    // if(_user && _user.email &&(_user.email==="empadronamiento@poderjudicial.klarway" || _user.email==="empadronamiento@poderjudicialpredev.klarway")){
    //   this._path='generados'
    // }
    // if(_user && _user.email &&(_user.email==="supervisor@ipp.cl" )){
    //   this._path='proctorings/completas'
    // }

    //generados
    let rol = localStorage.rol

    try {
      rol = JSON.parse(rol)
    }
    catch (_e) {
      rol = {}
    }

    rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
    let dashPerm = rol.seeDashboard == 1 ? true : false
    let examnsPerm = rol.seeExamns == 1 ? true : false
    let settingsPerm = rol.editProctoringSettings == 1 ||rol.editEnviromentSettings == 1 || rol.seeRol == 1 || rol.editUsers == 1 ? true : false
    let sett11 = rol.editProctoringSettings == 1 ? true : false
    let sett12 = rol.editEnviromentSettings == 1 ? true : false
    let sett2 = rol.seeRol == 1 ? true : false
    let sett3 = rol.editUsers == 1 ? true : false
    let enrollsPerm = rol.seeEnrolls == 1 ? true : false
    const validateRol = () => {
      let rol = localStorage.rol

      try {
        rol = JSON.parse(rol)
      }
      catch (_e) {
        rol = {}
      }

      rol = rol && rol.authorities && rol.authorities.permission ? rol.authorities.permission : {}
      delete rol.downloadDashboard
      delete rol.downloadExamns
      let rolAssigned = false
      for (let i in rol) {
        if (rol[i]) {
          rolAssigned = true
          break;
        }
      }
      return rolAssigned
    }
    let rolAssigned = validateRol()
    // console.log('1', rolAssigned)
    // if (rolAssigned == false) {
    //   this.state = { rolAssigned: rolAssigned, }
    // } else {
const defaultPath=()=>{
  if (examnsPerm) {
   return 'proctorings/completas'
  }
  else if (dashPerm ) {
   return 'dashboard'
  }
  else if(!rolAssigned){
    return 'UserWithoutRol'
  }
  else if (settingsPerm) {
    if (sett11) {
     return 'ConfiguracionProctoring'

    }
    else if (sett12) {
      return 'ConfiguracionAmbiente'
 
     }
    else if (sett2) {
     return 'Roles'

    }
    else if (sett3) {
     return 'Usuarios'

    }
    else {
     return 'UserWithoutRol'

    }
  }
  else if (enrollsPerm) {
   return 'generados'
  }
  else {
   return "unauthorized"
  }
}
    const checkPerm = (x) => {
      let st = false
      let found = false
      if (x === ('proctorings/completas') ) {
        found = true
        if (examnsPerm) {
          st = true
        }

      }
      else if (x === ('dashboard') ) {
        found = true
        if (dashPerm) {
          st = true
        }

      }
      else if (x === ('UserWithoutRol') ) {
        found = true
        if ( !rolAssigned) {
          st = true
        }

      }
      else if (x === ('ConfiguracionProctoring') ) {
        found = true
        if (sett11) {
          st = true
        }
      }
      else if (x === ('ConfiguracionAmbiente') ) {
        found = true
        if (sett12) {
          st = true
        }
      }
      else if (x === ('Roles') ) {
        found = true
        if (sett2) {
          st = true
        }
      }
      else if (x === 'Usuarios' ) {
        found = true
          st = true
      }
      else if (x === 'RolesUsuarios' ) {
        found = true
          st = true
      }
      else if (x === ('generados') ) {
        found = true
        if (enrollsPerm) {
          st = true
        }
      }
      else if(x === "changepass"){
        found = true
          st = true
      }
      if (st && found) {
        return  x
      }
      else if(!found){
        // TODO : Make 404 page
        return defaultPath()
      }
      else if(!st){
        // TODO : Make unauthorized page
        // return "unauthorized"
        return defaultPath()

      }
     
    }

    if (global.redirect) {
      this._path = checkPerm(global.redirect)

    }
    else {
      this._path = defaultPath()
    }
    console.log(this._path)
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: require("assets/img/sidebar-2.jpg"),
      color: "lightBlueKW",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown",
      logo: require("assets/img/logo-white.svg")
    };
  }

  mainPanel = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleCloseProfile = () => {
    //console.log('localStorage.from ajajsjajaja', localStorage.from)
    if (localStorage.from === 'siglo21') {
      global._logoutSiglo21()
    } else {
      localStorage.from = "-"
      localStorage.clear();
      firebase.auth().signOut().then(function () {
        //console.log('se cerro la secion')
      }).catch(function (error) {
        //console.log('ERROR no se puede iniciar la ssecion')
      });
      this.setState({ openProfile: false });
    }
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    switch (bgColor) {
      case "white":
        this.setState({ logo: require("assets/img/logo.svg") });
        break;
      default:
        this.setState({ logo: require("assets/img/logo-white.svg") });
        break;
    }
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }

    });
  };
  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper} style={{ overflow: "visible" }}>
        <Sidebar
          routes={routes}
          logoText={"Creative Tim"}
          logo={this.state.logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={this.mainPanel}>
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {this.getRoutes(routes)}
                  <Redirect from="/admin" to={"/admin/" + this._path} />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>

                {this.getRoutes(routes)}

                <Redirect from="/admin" to={"/admin/" + this._path} />
              </Switch>
            </div>
          )}

          {this.getRoute() ? <Footer fluid /> : null}
          {//bunker elimniado configuracion de estilo del sidebar}
            /*
            <FixedPlugin
              handleImageClick={this.handleImageClick}
              handleColorClick={this.handleColorClick}
              handleBgColorClick={this.handleBgColorClick}
              handleHasImage={this.handleHasImage}
              color={this.state["color"]}
              bgColor={this.state["bgColor"]}
              bgImage={this.state["image"]}
              handleFixedClick={this.handleFixedClick}
              fixedClasses={this.state.fixedClasses}
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
            />
              */
          }
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
