import React from 'react'
import GridItem from "components/Grid/GridItem.jsx";

function KWTextWithTitle(props) {

  const {
    title,
    text,
  } = props

  KWTextWithTitle.defaultProps = {
    title: "",
    text: ""
  }

  return (
    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
      <h6>{title}</h6>
      <h5 style={{ fontSize: 14, marginLeft: 5 }}> {text}</h5>
    </GridItem>
  )

}

export default KWTextWithTitle

