import React from 'react'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Assignment from "@material-ui/icons/Assignment"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import BootstrapTable from "react-bootstrap-table-next"

import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import IconButton from '@material-ui/core/IconButton'
import Close from "@material-ui/icons/Close"
import Problem from "@material-ui/icons/ReportProblem"

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

import Collapse from '@material-ui/core/Collapse'

import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import CircularProgress from '@material-ui/core/CircularProgress'

import BivResult from "components/BivResult/BivResult.jsx"

import BivPagination from 'components/BivPagination/BivPagination.jsx'

import CardInMaintenance from 'components/CardInMaintenance/CardInMaintenance.jsx'

import MenuItem from "@material-ui/core/MenuItem"


import BivButtonFind from 'components/BivButtonFind/BivButtonFind.jsx'

import utilities from 'utilities/utilities.jsx'

import ClearIcon from '@material-ui/icons/Clear'

import moment from "moment"
import columnTable from 'utilities/columnTable'
import GridEmptySpace from 'components/GridEmptySpace/GridEmptySpace'

moment.locale("es")
const languages = require('../../assets/translate/translate_registros.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

// const languages = require('../../assets/translate/translate_button.json')
// let lang = localStorage.lang ? localStorage.lang : 'es'

class BivRegistry extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showFilters: false,
      searchUserName: '',
      searchRegisterNumber: '',
      searchDeviceData: '',
      searchStartDate: moment().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm'),
      searchToDate: moment().format('YYYY-MM-DDTHH:mm'),
      //searchResultCount: 20,
      searchResults: [],
      searchResultsFiltered: [],
      showLogDetail: false,
      logResults: [],
      searchFilter: '',
      showLogEmpty: false,
      origin: '',
      searchResultOrigin: "Todos",

      //Store page number
      pageNumber: 1,
      totalRegistry: 0,
      column: "created_at",
      order: "desc",

    }
  }

  findfunc = () => {
    this.setState({ pageNumber: 1, loading: true }, this.getLogs)
  }

  componentDidMount() {
    if (global.registryInMaintenance === true) {
      this.setState({ loading: false })
      return
    }
    this.getLogs(true)
  }

  getLogs(first, column, order) {

    //let searchResultCount = (this.state.searchResultCount) ? this.state.searchResultCount : 20

    var requestHeader = new Headers()
    requestHeader.append(
      "Cookie",
      "adonis-session=5ec332b88a1ff49a0c50b655916c5a00FGh3MrdHWV1l%2FRNsk8%2FPWVp%2Faaw%2Baa%2FT5g%2B1LSSu96pVotq7OFFVp7TzRKs4unHwV010DVMwi69sdbdff%2BmRGGdSRj%2B4cnciAUO8w05c6FgeWEgojfY5J%2BPZ0ENCI5Im"
    )

    var requestBody = new FormData()
    if (first) {
      //requestBody.append("start", moment(this.state.searchStartDate).startOf('day').valueOf())
      requestBody.append("start", moment(this.state.searchStartDate).valueOf())
    } else {
      //requestBody.append("start", moment(this.state.searchStartDate).startOf('day').valueOf())
      requestBody.append("start", moment(this.state.searchStartDate).valueOf())
    }

    switch (this.state.searchResultOrigin) {
      case "Aplicación de escritorio":
        requestBody.append("searchByApp", 1)
        break
      case "App mobile":
        requestBody.append("searchByApp", 2)
        break
      default:
        break
    }

    //requestBody.append("end", moment(this.state.searchToDate).startOf('day').add(1, 'days').valueOf())
    requestBody.append("end", moment(this.state.searchToDate).valueOf())
    //requestBody.append("limit", 9999)

    requestBody.append("page", this.state.pageNumber)

    if (this.state.searchFilter && this.state.searchFilter.length > 0) {
      requestBody.append("search", this.state.searchFilter)
    }

    if (this.state.filterNickname && this.state.filterNickname.length > 0) {
      requestBody.append("nickname", this.state.filterNickname)
    }

    if (this.state.filterdni && this.state.filterdni.length > 0) {
      requestBody.append("dni", this.state.filterdni)
    }
    if (this.state.filterText && this.state.filterText.length > 0) {
      requestBody.append("user", this.state.filterText)
    }
    if (this.state.searchFilter) {
      //   requestBody.append("searchFilter", this.state.searchFilter)
    }

    if (this.state.searchfile && this.state.searchfile.length > 0) {
      requestBody.append("file", this.state.searchfile)
    }


    switch (column) {

      case "detalle":
        column = "detalle"

        break;

      case "nickname":
        column = "nickname"

        break;
      case "app_id":
        column = "app_id"

        break;
      case "finalized_at":
        column = "finalized_at"

        break;
      case "created_at":
        column = "created_at"

        break;
      case "id":
        column = "id"

        break;
      case "user":
        column = "user"

        break;
      default: column = "finalized_at"
        break;
    }

    //requestBody.append("search", this.state.searchFilter)

    switch (column) {

      case "detalle":
        column = "detalle"

        break;
      case "detail":
        column = "detail"
        break;
      case "file":
        column = "file"
        break;
      case "app_id":
        column = "app_id"
        //break;
        //case "serach":
        //column = "search"
        break;
      case "finalized_at":
        column = "finalized_at"

        break;
      case "created_at":
        column = "created_at"

        break;
      case "id":
        column = "id"

        break;
      case "user":
        column = "user"

        break;
      default: column = "finalized_at"
        break;
    }
    //if (order && column) {
    requestBody.append("order", this.state.order)
    requestBody.append("column", this.state.column)
    //}

    var requestOptions = {
      method: "POST",
      headers: requestHeader,
      body: requestBody,
      redirect: "follow",
    }

    fetch(global.Direction_Back_panel + "/logs/filterLog", requestOptions)
      .then(response => response.text())
      .then(result => {

        let _results = JSON.parse(result)

        let _page = _results.page
        let _totalRegistry = _results.total

        let _result = _results.data

        this.setState({
          pageNumber: _page,
          totalRegistry: _totalRegistry,
          searchResults: _result ? _result : []
        }, this.prepareData)
      })
      .catch((error) => {
        //console.log("error", error)
        this.setState({ loading: false })
      })

  }

  getLog(id) {

    var requestHeader = new Headers()
    requestHeader.append(
      "Cookie",
      "adonis-session=5ec332b88a1ff49a0c50b655916c5a00FGh3MrdHWV1l%2FRNsk8%2FPWVp%2Faaw%2Baa%2FT5g%2B1LSSu96pVotq7OFFVp7TzRKs4unHwV010DVMwi69sdbdff%2BmRGGdSRj%2B4cnciAUO8w05c6FgeWEgojfY5J%2BPZ0ENCI5Im"
    )

    var requestOptions = {
      method: "POST",
      headers: requestHeader,
      redirect: "follow",
    }

    fetch(global.Direction_Back_panel + "/logs/" + id + "/detail", requestOptions)
      .then(response => response.text())
      .then(result => {
        let _logRes = JSON.parse(result)
        let _logResNormalized = []

        for (let i = 0; i < _logRes.length; i++) {
          let _arr = []

          _arr = {
            key: i,
            id: _logRes[i].id,
            event: (_logRes[i].detalle) ? (_logRes[i].detalle !== '') ? this.toCapitalize(_logRes[i].detalle) : '-' : '-',
            client_time: (_logRes[i].client_time) ? moment(_logRes[i].client_time).format('HH:mm:ss') + "Hs" : '-',
            detail: (_logRes[i].event_id) ? this.procesarDetalle(_logRes[i].event_id, _logRes[i].event_detail) : '-',
          }

          _logResNormalized.push(_arr)

        }

        if (_logResNormalized.length > 0) {
          this.setState({ logResults: _logResNormalized, showLogDetail: true, loading: false })
        } else {
          this.setState({ showLogEmpty: true, loading: false })
        }

      })
      .catch((error) => {
        //console.log("error", error)
        this.setState({ loading: false })
      })

  }


  reset = () => {
    // Object.keys(this.state).map((key, index) => { 
    this.setState({ filterText: "", filterdni: "", filterNickname: "", searchfile: "", searchResultOrigin: "" }, this.findfunc());
    // });
  }

  toCapitalize(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  procesarDetalle(id, fields) {
    let detail = '-'
    let _logRes

    switch (id) {

      case 11:
        let _detail11 = '-'
        if (fields !== null) {
          let _detailData = JSON.parse(fields)
          _detail11 = _detailData.key
          detail = 'Socket: ' + _detail11
        } else {
          detail = '-'
        }
        break;

      case 18:
        _logRes = JSON.parse(fields)
        detail = (_logRes.d.n !== null) ? 'Usuario: ' + this.processName(_logRes.d.n) + ' ' + _logRes.d.s + ' Dni:' + _logRes.d.dni : 'Usuario: ' + _logRes.n.toString().toUpperCase()
        break;

      case 41:
        _logRes = JSON.parse(fields)
        detail = _logRes.cpu.brand.toString() + ' - ' + _logRes.cpu.cores.toString() + ' cores ' + _logRes.cpu.speed.toString() + 'Ghz - ' + (_logRes.mem.total / 1073741824).toString() + 'Gb Ram.'
        break;

      case 62:
        _logRes = JSON.parse(fields)
        if (_logRes.length > 0) {
          if (_logRes.length > 1) {
            detail = "Procesos: " + _logRes[0].name
            for (let i = 1; i < _logRes.length; i++) { detail = detail + "  -  " + _logRes[i].name }
          } else {
            detail = 'Proceso: ' + _logRes[0].name
          }
        } else {
          detail = '-'
        }
        break;

      case 75:
        //let _detail75 = '-'
        if (fields !== null) {
          //let _detailData = JSON.parse(fields)
          //_detail75 = _detailData.key
          detail = 'Socket: ' + fields
        } else {
          detail = '-'
        }
        break;

      case 83:
        _logRes = JSON.parse(fields)

        let _name = '-'
        let _userLastName = _logRes.d ? _logRes.d.n ? this.processName(_logRes.d.n) : '-' : '-'
        let _userName = _logRes.d ? _logRes.d.s ? _logRes.d.s : '-' : '-'
        let _dni = _logRes.d ? _logRes.d.dni ? ' ID:' + _logRes.d.dni : '-' : '-'

        if (_userLastName === '-' && _userName === '-') {
          let _user = _logRes.n ? _logRes.n.toString().toUpperCase() : '-'
          _name = 'Usuario: ' + _user
        } else {
          _name = 'Usuario: ' + _userLastName + ', ' + _userName + _dni
        }

        detail = _name

        break;

      default:
        detail = '-'
        break;
    }

    return detail
  }

  processName(nombre) {

    let nombres = nombre.toString().split(' ')
    let result = ''
    for (let i = 0; i < nombres.length; i++) {
      var letra = nombres[i].charAt(0).toUpperCase()
      if (letra.length > 0) {
        if (result === '') {
          result = letra + '.'
        } else {
          result = result + letra + '.'
        }
      }
    }

    return result

  }

  prepareData() {


    let _resultFiltered = []

    //console.log("this.state.searchResults")
    //console.log(this.state.searchResults)

    for (let i = 0; i < this.state.searchResults.length; i++) {
      let _arr = []

      if (this.state.searchResults[i].id != null) {

        let _user = '-'

        if (this.state.searchResults[i].padron_user != null) {
          let userData = JSON.parse(this.state.searchResults[i].padron_user.detail)
          //_user = userData ? userData.d ? userData.d.n ? userData.d.n : '-' : '-' : '-'
          _user = userData ? userData.n ? userData.n.toUpperCase() : '-' : '-'
        }

        if (_user === '-' && this.state.searchResults[i].proctoring_data != null) {
          _user = this.state.searchResults[i].proctoring_data.user ? this.state.searchResults[i].proctoring_data.user.toUpperCase() : '-'
        }

        _arr = {
          id: this.state.searchResults[i].id,
          created_at: (this.state.searchResults[i].created_at) ? moment(this.state.searchResults[i].created_at).format('DD-MM-YYYY') : '-',
          start_at: (this.state.searchResults[i].created_at) ? moment(this.state.searchResults[i].created_at).format('HH:mm') : '-',
          finalized_at: (this.state.searchResults[i].finalized_at) ? moment(this.state.searchResults[i].finalized_at).format('HH:mm') : '-',
          user: (this.state.searchResults[i].user),
          detail: (this.state.searchResults[i].detail),
          //lastEvent: (this.state.searchResults[i].lastEvent) ? (this.state.searchResults[i].lastEvent.event) ? (this.state.searchResults[i].lastEvent.event.detalle) ? this.toCapitalize(this.state.searchResults[i].lastEvent.event.detalle) : '-' : '-' : '-',
          app_id: (this.state.searchResults[i].app_id !== null) ? (this.state.searchResults[i].app_id === 1) ? 'Biv - Aplicación de escritorio' : 'App mobile' : '-',
          dni: (this.state.searchResults[i].dni),
          nickname: (this.state.searchResults[i].nickname),
          file: (this.state.searchResults[i].file)
        }

        _resultFiltered.push(_arr);

      }
    }

    this.setState({
      searchResultsFiltered: _resultFiltered,
      loading: false,
    })

  }

  sortIcon(order, column) {
    if (!order) return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    else if (order === 'asc') return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    else if (order === 'desc') return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
    return null
  }

  componentDidUpdate() {
    if (this.state.orderChange === true) {
      this.setState(
        {
          orderChange: false,
        }
      )
      this.getLogs(true, this.state.column, this.state.order)
    }
  }

  setSort(dataField, order) {

    this.setState({
      column: dataField,
      order: order,
    }, this.getLogs)

  }

  validationButtonFunction(cell, row) {
    return (
      <Button color="violetBiv" onClick={() => {
        this.setState({
          loading: true,
          origin: row.origin,
        })
        this.getLog(row.id)
      }} round>Ver</Button>
    )
  }

  render() {

    /*
    TODO: SACAR
    const formatWithIconSee = (cell, row) => {
      return (
        <Button color="violetBiv" onClick={() => {
          this.setState({
            loading: true,
            origin: row.origin,
          })
          this.getLog(row.key)
        }} round>Ver</Button>
      )
    }
     */
    /*
    TODO: SACAR
    const headerLogs = [
      {
        dataField: 'dateInit',
        text: 'Fecha',
        editable: false,
        sort: true,
        //sortFunc: (a, b, order) => {
        //if (order === 'asc') {
        //return Date.parse(moment(a, "DD/MM/YYYY").format('MM/DD/YYYY')) - Date.parse(moment(b, "DD/MM/YYYY").format('MM/DD/YYYY'))
        //} else if (order === 'desc') {
        //return Date.parse(moment(b, "DD/MM/YYYY").format('MM/DD/YYYY')) - Date.parse(moment(a, "DD/MM/YYYY").format('MM/DD/YYYY'))
        //}
        //},
        sortFunc: (a, b, order) => {

          this.setSort('dateInit', order)
        },

        // sortFunc: (a, b, order) => {
        //   if (order === 'asc') {
        //     //return Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY'))
        //     return Number(moment(a, 'DD/MM/YYYY').format('x')) - Number(moment(b, 'DD/MM/YYYY').format('x'))
        //   } else if (order === 'desc') {
        //     //return Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY'))
        //     return Number(moment(b, 'DD/MM/YYYY').format('x')) - Number(moment(a, 'DD/MM/YYYY').format('x'))
        //   }
        // },
        headerStyle: { minWidth: '88px' },
        sortCaret: this.sortIcon,
      },


      {
        dataField: 'dateStart',
        text: 'Inicio',
        editable: false,
        sort: true,
        headerStyle: { minWidth: '84px' },
        onSort: (field, order) => { getTableRegistry("dateStart", order) },
        sortCaret: this.sortIcon,
        sortFunc: (a, b, order) => {

          this.onSort('dateStart', order) 
        }
      }, {
        dataField: 'dateEnd',
        text: 'Fin',
        editable: false,
        sort: true,
        headerStyle: { minWidth: '69px' },
        sortCaret: this.sortIcon,
        sortFunc: (a, b, order) => {
          this.setSort('dateEnd', order)
        }
      }, {
        dataField: 'userName',
        text: 'Usuario',
        editable: false,
        sort: true,
        headerStyle: { minWidth: '98px' },
        sortCaret: this.sortIcon,
        sortFunc: (a, b, order) => {
          this.setSort('userName', order)
        }
       },
      // {
      //   dataField: 'lastEvent',
      //   text: 'Último evento',
      //   editable: false,
      //   sort: true,
      //   headerStyle: { minWidth: '137px' },
      //   sortCaret: this.sortIcon,
      //   sortFunc: (a, b, order) => {
      //     this.setSort('lastEvent', order)
      //     console.log(5677654567)
      //   }
      // }, 
      {
        dataField: 'key',
        text: 'Nº de registro',
        editable: false,
        sort: true,
        headerStyle: { minWidth: '137px' },
        sortCaret: this.sortIcon,
        sortFunc: (a, b, order) => {
          this.setSort('key', order)
        }
      }, {
        dataField: 'origin',
        text: 'Origen',
        editable: false,
        sort: true,
        headerStyle: { minWidth: '92px' },
        sortCaret: this.sortIcon,
        sortFunc: (a, b, order) => {
          this.setSort('origin', order)
        }
      }, {
        dataField: 'button',
        text: 'Acción',
        formatter: formatWithIconSee,
        sort: true,
        headerStyle: { minWidth: '94px' },
        sortCaret: this.sortIcon,
        // sortFunc: (a, b, order) => {
        //   this.setSort('button', order)
        // }
      }];
 */

    const headerLog = [
      {
        dataField: 'key',
        text: 'Key',
        hidden: true,
      }, {
        dataField: 'id',
        text: 'Nº',
        headerStyle: { width: '5%' },
      }, {
        dataField: 'event',
        text: 'Evento',
        headerStyle: { width: '40%' },
      }, {
        dataField: 'client_time',
        text: 'Momento',
        headerStyle: { width: '15%' },
      }, {
        dataField: 'detail',
        text: 'Detalle',
        headerStyle: { width: '40%' },
      }
    ];

    const typesOfOrigin = [
      {
        value: "Todos",
        label: "Todos",
      },
      {
        value: "Aplicación de escritorio",
        label: "Aplicación de escritorio",
      },
      {
        value: "App mobile",
        label: "App mobile",
      },
    ];

    return (

      <div style={{ width: '100%', height: '100%' }}>

        {this.state.loading ?
          <div style={{ position: 'fixed', left: 0, top: 0, backgroundColor: '#ffffff', opacity: '0.7', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', zIndex: 9999 }}>
            <CircularProgress />
          </div>
          :
          <div />
        }

        {(global.registryInMaintenance === true) ?

          <CardInMaintenance />

          :

          <GridContainer >
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}
              style={{ marginTop: -70 }}>
              <Card>

                {/* Icono y Titulo */}
                <CardHeader color="lightBlueBiv" icon>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                      <CardIcon color="lightBlueBiv">
                        <Assignment />
                      </CardIcon>
                      <h4 style={{ color: 'dimgray' }}>{languages[lang].registry_registryControl}</h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>

                {/* Card y Search */}
                <CardBody >

                  <GridContainer>


                    {/*filtros */}
                    {/* Fecha Desde */}
                    <GridItem xs={12} sm={6} md={6} lg={3} xl={4}>

                      <TextField
                        style={{ width: "100%" }}
                        id="searchStartDate"
                        label={languages[lang].registry_from}
                        type="datetime-local"
                        variant="outlined"
                        size="small"
                        margin="dense"

                        color="dimgray"
                        value={this.state.searchStartDate}
                        onKeyPress={
                          (ev) => {
                            utilities.elEnter(ev, this.findfunc)
                          }}
                        onChange={(x) => {
                          this.setState({ searchStartDate: x.target.value })
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                    {/* Fecha Hasta */}
                    <GridItem xs={12} sm={6} md={6} lg={3} xl={2}>
                      <TextField
                        style={{ width: "100%" }}
                        id="searchToDate"
                        label={languages[lang].registry_to}
                        type="datetime-local"
                        variant="outlined"
                        size="small"
                        margin="dense"

                        color="dimgray"
                        value={this.state.searchToDate}
                        onKeyPress={
                          (ev) => {
                            utilities.elEnter(ev, this.findfunc)
                          }}
                        onChange={(x) => {
                          this.setState({ searchToDate: x.target.value })
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} onClick={() => {
                      this.setState({ showFilters: !this.state.showFilters })
                    }} sm={6} md={9} lg={4} xl={2} style={{ display: "flex", alignItems: "center", paddingLeft: 5 }}>
                      <h4 style={{ fontSize: 14 }}>{languages[lang].registry_more}
                      </h4>

                      {/*botón colapsable*/}
                      <IconButton
                        id="unique"
                        style={{ outline: "none" }}

                        aria-expanded={this.state.showFilters}
                        aria-label="Expandir"
                      >
                        {this.state.showFilters ? <ExpandLess /> : < ExpandMoreIcon />}
                      </IconButton>

                    </GridItem>




                    {/* Boton Buscar */}
                    <GridItem xs={6} sm={6} md={3} lg={2} xl={2} style={{ display: "flex", alignItems: "center" }}>

                      <BivButtonFind
                        onClick={() => {
                          this.setState({ pageNumber: 1, loading: true }, this.getLogs)
                        }}
                      />
                    </GridItem>

                  </GridContainer>



                  <Collapse in={this.state.showFilters} timeout="auto" unmountOnExit>

                    {/* Search */}
                    <CardContent style={{ padding: 0 }}>

                      <GridContainer >

                        {/* Texto Filtro 
                        <GridItem xs={12} sm={6} md={3} lg={2} xl={2} style={{ display: "flex", alignItems: "center" }}>
                          <h1 style={{ fontSize: 16, margin: 0 }}>Filtro por fechas:</h1>
                        </GridItem>*/}



                        <GridContainer style={{ padding: 16 }}>
                          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 11, marginBottom: 11 }}>
                            <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }} />
                          </GridItem>
                        </GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3} >
                          <TextField
                            style={{ width: "100%" }}
                            id="user"
                            label={languages[lang].registry_nameLN}
                            variant="outlined"
                            size="small"
                            display="flex"
                            margin="dense"
                            color="secondary"
                            value={this.state.filterText}
                            onKeyPress={
                              (ev) => {
                                utilities.elEnter(ev, this.findfunc)
                              }}
                            onChange={(x) => {
                              let value = (x.target.value != null) ? x.target.value : ''
                              this.setState({ filterText: value })
                            }}
                          />

                        </GridItem>

                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3}>

                          <TextField
                            style={{ width: "100%", }}
                            id="dni"
                            label={global.dni_name}
                            variant="outlined"
                            size="small"
                            display="flex"
                            margin="dense"
                            color="secondary"
                            onKeyPress={
                              (ev) => {
                                utilities.elEnter(ev, this.findfunc)
                              }}
                            value={this.state.filterdni}
                            onChange={(x) => {
                              let value = (x.target.value != null) ? x.target.value : ''
                              this.setState({ filterdni: value })
                            }}
                          />

                        </GridItem>

                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3}>

                          <TextField
                            style={{ width: "100%", }}
                            id="nickname"
                            label={languages[lang].registry_user}
                            variant="outlined"
                            size="small"
                            display="flex"
                            margin="dense"
                            color="secondary"
                            value={this.state.filterNickname}
                            onKeyPress={
                              (ev) => {
                                utilities.elEnter(ev, this.findfunc)
                              }}
                            onChange={(x) => {
                              let value = (x.target.value != null) ? x.target.value : ''
                              this.setState({ filterNickname: value })
                            }}
                          />

                        </GridItem>


                        {/* File - text */}
                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3}>
                          <TextField
                            style={{ width: "100%" }}
                            id="searchfile"
                            label={languages[lang].registry_file}
                            // type="number"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            color="secondary"
                            value={this.state.searchfile}
                            onKeyPress={
                              (ev) => {
                                utilities.elEnter(ev, this.findfunc)
                              }}
                            onChange={(x) => {
                              this.setState({ searchfile: x.target.value })
                            }}
                          />
                        </GridItem>




                        {/* App */}
                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3}>

                          {/* <form noValidate autoComplete="off"> */}
                          <TextField
                            style={{ width: "100%" }}
                            id="searchResultOrigin"
                            select
                            variant="outlined"
                            label={languages[lang].registry_source}
                            size="small"
                            margin="dense"
                            color="secondary"
                            value={this.state.searchResultOrigin}
                            onChange={(event) => {
                              this.setState({ searchResultOrigin: event.target.value });
                            }}
                          >
                            {typesOfOrigin.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {/* </form> */}
                        </GridItem>

                        {/* <GridItem xs={12} sm='hidden' md='hidden' lg={6} xl={3} ></GridItem> */}
                        {/* <GridItem xs={6} implementation='css' lgUp component={Hidden} /> */}
                        <GridEmptySpace
                          xs={12}
                          sm="hidden"
                          md="hidden"
                          lg={6}
                          xl={6}
                        />
                        <GridItem xs={12} sm={6} md={4} lg={3} xl={3} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                          <Button
                            round
                            disabled={(!this.state.searchResultOrigin || this.state.searchResultOrigin === "Todos") && (!this.state.searchfile || this.state.searchfile === "") && (!this.state.filterNickname || this.state.filterNickname === "") && (!this.state.searchfile || this.state.searchfile === "") && (!this.state.filterNickname || this.state.filterNickname === "") && (!this.state.filterdni || this.state.filterdni === "") && (!this.state.filterText || this.state.filterText === "")}
                            endIcon={<ClearIcon />}
                            style={{ width: "100%", maxWidth: 150 }}
                            color="#B5C3AE"
                            onClick={() => {
                              this.reset()

                            }}
                          >{languages[lang].registry_clean}</Button>

                        </GridItem>

                      </GridContainer>

                      <GridContainer>



                        {/* Filtro Particular 
                       <GridItem xs={6} sm={8} md={8} lg={3} xl={10}>
                          <TextField
                            style={{ width: "100%", }}
                            id="searchFilter"
                            label="Palabra Clave"
                            variant="outlined"
                            size="small"
                            margin="dense"
                            color="secondary"
                            value={this.state.searchFilter}
                            onKeyPress={
                              (ev) => {
                                utilities.elEnter(ev,this.findfunc)
                              }}
                            onChange={(x) => {
                              let value = (x.target.value != null) ? x.target.value : ''
                              this.setState({ searchFilter: value })
                            }}
                          />
                        </GridItem>*/}



                      </GridContainer>

                    </CardContent>

                  </Collapse>

                </CardBody>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 20, marginRight: 20, marginTop: 11, marginBottom: 11 }}>
                    <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }} />
                  </GridItem>
                </GridContainer>

                <BivResult regsLength={this.state.totalRegistry}></BivResult>

                {/* Table */}
                {this.state.searchResultsFiltered.length > 0 ?

                  <div>
                    {/* Datos de Tabla , overflow: "scroll" */}
                    <CardBody style={{ maxHeight: 600, overflow: "auto" }}>
                      <GridContainer justify="space-between">
                        <GridItem xs={12} sm={12} md={12} style={{ minWidth: 1175 }}>
                          <BootstrapTable
                            bootstrap4
                            keyField="key"
                            data={this.state.searchResultsFiltered}
                            //columns={headerLogs}
                            columns={columnTable.getTableRegistry(this.setSort.bind(this), this.validationButtonFunction.bind(this))}
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                            classes='scrollable-table'
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>

                    <BivPagination
                      onClickPrev={() => {
                        if (this.state.pageNumber !== 1) {
                          this.setState({
                            loading: true,
                            pageNumber: this.state.pageNumber - 1
                          }, this.getLogs)
                        }
                      }}
                      onClickPage={(value) => {
                        this.setState({
                          loading: true,
                          pageNumber: value
                        }, this.getLogs)
                      }}
                      onClickNext={() => {
                        if (this.state.pageNumber !== this.state.totalRegistry) {
                          this.setState({
                            loading: true,
                            pageNumber: this.state.pageNumber + 1
                          }, this.getLogs)
                        }
                      }}
                      page={this.state.pageNumber}
                      totalRegistry={this.state.totalRegistry}
                    />

                  </div>

                  :

                  <GridContainer>
                    <GridItem xs={12} style={{
                      height: 250,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      flexDirection: "column",
                    }}>

                      <Problem
                        style={{ fontSize: 50, marginBottom: 10 }}
                      />
                      <div style={{ fontSize: 19 }}>
                        {languages[lang].registry_noRFUFTGResults}
                      </div>
                    </GridItem>
                  </GridContainer>

                }

              </Card>
            </GridItem>
          </GridContainer>
        }

        <Dialog
          open={this.state.showLogDetail}
          fullWidth={true}
          maxWidth={'lg'}
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDescription"
          onClose={() => {
            this.setState({ showLogDetail: false })
          }}
        >
          <DialogTitle
            id="dialogTitle"
            disableTypography
          >
            <Button
              justIcon
              style={{ position: 'absolute', right: 15 }}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                this.setState({ showLogDetail: false })
              }}
            >
              <Close />
            </Button>
            <h4 >{languages[lang].registry_registryInformation}</h4>
          </DialogTitle>

          <DialogContent id="dialogDescription">

            <GridContainer>

              <GridItem xs={12}>

                <Card style={{ marginTop: 0 }}>
                  <CardHeader>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={6} lg={6} xl={6} style={{ marginTop: 0, marginBottom: 15 }}>
                        <h4 style={{ color: 'dimgray' }}>{languages[lang].registry_registryEvents}</h4>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6} lg={6} xl={6} style={{ marginTop: 12, marginBottom: 15, display: 'flex', justifyContent: "flex-end" }}>
                        {(this.state.origin !== '-') ?
                          (this.state.origin === 'Biv - Aplicación de escritorio') ?
                            <p style={{ color: "#0bb4e4", fontSize: 16, fontWeight: 'bold' }}>{this.state.origin}</p>
                            :
                            <p style={{ color: "#15cacb", fontSize: 16, fontWeight: 'bold' }}>{this.state.origin}</p>
                          :
                          <div />
                        }
                      </GridItem>
                    </GridContainer>

                    {/*(this.state.origin !== '-') ?
                      <Button
                        color={(this.state.origin === 'Biv - Aplicación de escritorio') ? 'violetBiv' : 'lightBlueBiv'}
                        size="sm"
                        style={{ position: 'absolute', right: 15 }}
                      >{this.state.origin}</Button>
                      :
                      <div />
                    */}

                  </CardHeader>
                  <CardBody>
                    <BootstrapTable
                      bootstrap4
                      keyField="key"
                      data={this.state.logResults}
                      columns={headerLog}
                      striped
                      bordered={false}
                      wrapperClasses="table-responsive"
                      classes='scrollable-table'
                    />
                  </CardBody>

                </Card>

              </GridItem>

            </GridContainer>

          </DialogContent>

        </Dialog>

        <Dialog
          open={this.state.showLogEmpty}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="dialogEmptyTitle"
          aria-describedby="dialogEmptyDescription"
          onClose={() => {
            this.setState({ showLogEmpty: false })
          }}
        >
          <DialogTitle
            id="dialogEmptyTitle"
            disableTypography
          >
            <Button
              justIcon
              style={{ position: 'absolute', right: 15 }}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                this.setState({ showLogEmpty: false })
              }}
            >
              <Close />
            </Button>
            <h4 >{languages[lang].registry_registryInformation}</h4>
          </DialogTitle>

          <DialogContent id="dialogEmptyDescription">

            <GridContainer>

              <GridItem xs={12}>

                <Card style={{ marginTop: 0 }}>
                  <CardBody>
                    <div
                      style={{
                        height: 250,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        flexDirection: "column",
                      }}>
                      <Problem
                        style={{ fontSize: 50, marginBottom: 10 }}
                      />
                      <div style={{ fontSize: 19 }}>
                        {languages[lang].registry_noRegistryF}
                      </div>
                    </div>
                  </CardBody>

                </Card>

              </GridItem>

            </GridContainer>

          </DialogContent>

        </Dialog>

      </div>
    )

  }

}

export default withStyles(sweetAlertStyle)(BivRegistry)
