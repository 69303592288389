import React from 'react'
import Button from "components/CustomButtons/Button.jsx";

function BivPaginationButton(props) {

  BivPaginationButton.defaultProps = {
    id: "",
    onClick: () => null,
    active: true,
    visible: false,
    text: '',
    selected: false,
  }

  //"#00acc1"

  if (props.active === true) {

    //Active
    if (props.selected === true) {

      //Active & Selected
      return <Button
        id={props.id}
        style={{ color: '#FFFFFF', backgroundColor: '#0bb4e4' }}
        size="sm"
        hidden={!props.visible}
        onClick={() => { props.onClick() }}
      >
        {props.text}
      </Button>

    } else {

      //Active & Not selected
      return <Button
        id={props.id}
        style={{ color: '#0bb4e4', backgroundColor: '#FFFFFF' }}
        simple
        size="sm"
        hidden={!props.visible}
        onClick={() => { props.onClick() }}
      >
        {props.text}
      </Button>
    }

  }

  //Not Active
  return <Button
    id={props.id}
    style={{ color: '#A0A0A0', backgroundColor: '#FFFFFF' }}
    size="sm"
    simple
    disabled
    hidden={!props.visible}
    onClick={() => { props.onClick() }}
  >
    {props.text}
  </Button>

}

export default BivPaginationButton