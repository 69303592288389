import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Paper, Typography, TextField } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import ListItem from '@mui/material/ListItem';
import Button from '@material-ui/core/Button';

import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from "@mui/material";
import { Collapse, IconButton } from "@material-ui/core";

class ProfileManager extends React.Component {

  constructor(props) {
    super(props);

    this.textitoAbajo = ''
    this.write1 = false
    this.write2 = false
    this.write3 = false
    this.oldPass = ''
    this.newPass = ''
    this.newPassRepeat = ''
    this.showAlert = false
    this.intervalS = null
    this.user=JSON.parse(localStorage.USER).mail

    //CheckPasswordStrength 
    //Regular Expressions
    this.regex = new Array();
    this.regex.push("[A-Z]"); //For Uppercase Alphabet
    this.regex.push("[a-z]"); //For Lowercase Alphabet
    this.regex.push("[0-9]"); //For Numeric Digits
    this.regex.push("[$@!%*#?&]"); //For Special Characters


    this.defaultValues = {
      showAlerts: false,
      showAlert: false,
      showAlert2: false,
      helperText2: false,
      helperText3: false,
      disabledUpdate: true,
      helperText1: false,
    }

    this.defaultValues1 = {
      ...this.defaultValues,

    }
    this.state = {
      ...this.defaultValues1,

    }
  }

  setNull() {
    this.oldPass = ''
    this.newPass = ''
    this.newPassRepeat = ''
    this.write1 = false
    this.write2 = false
    this.write3 = false
    this.setState({ ...this.defaultValues1 })
  }

  CheckPasswordStrength(password) {
    if(this.write2 && password.length>0){
    let password_strength = "";


    //if textBox is empty

    let passed = 0;

    //Validation for each Regular Expression
    for (let i = 0; i < this.regex.length; i++) {
      if ((new RegExp(this.regex[i])).test(password)) {

        passed++;
      }
    }

    //Validation for Length of Password
    if (passed > 2 && password.length > 7) {
      passed++;
    }

    //Display of Status
    console.log(passed)
    switch (passed) {

      case 5:
        return {success:true}
        break;
      default:
        return {t2:"La contraseña debe tener al menos 8 caracteres, entre ellos 1 mayúscula , 1 minuscula, 1 numero y 1 caracter especial ($,@,!,%,*,#,?,&)",err:true}
        break;
    }
  }
  }
  compareBetweenOldAndNew() {
    let res = {}
    if (this.write1 && this.write2) {
      if (this.newPass && this.oldPass && this.newPass.length > 0 && this.oldPass.length > 0) {
        if (this.newPass === this.oldPass) {

          res.t2 = 'Las contraseñas son iguales, debe utilizar una distinta a la anterior'
        res.tf = true
        res.err = true
        }
        else{
              
              res.success=true
            }
      }
      else {
        if (!this.oldPass || this.oldPass.length < 1) {
          res.t1 = "Completa este campo"
          res.err = true

        }
         if (!this.newPass || this.newPass.length < 1) {
          res.t2 = "Completa este campo"
          res.err = true
        }

        
      }
    }
   
    return res
  }
  compareBetweenNewAndRepeat() {
    let res = {}
    if (this.write2 && this.write3) {
      res.tf2 = true

      if (this.newPass && this.newPassRepeat && this.newPass.length > 0 && this.newPassRepeat.length > 0) {
        if (this.newPass !== this.newPassRepeat) {
          res.t3 = 'Las contraseñas no coinciden'
          res.err = true
        }
        else{
          res.success=true
        }
      }
      else {
        if (!this.newPassRepeat || this.newPassRepeat.length < 1) {
          res.t3 = "Completa este campo"
          res.err = true

        }
         if (!this.newPass || this.newPass.length < 1) {
          res.t2 = "Completa este campo"
          res.err = true
        }
        if(!(!this.newPass || this.newPass.length < 1)  &&  !(!this.newPassRepeat || this.newPassRepeat.length < 1)){
            res.success=true
        }
      }
    }
    else{
      res.err=true
    }
    return res

  }

  comparePass() {
    
   
    let c1 = this.compareBetweenOldAndNew()

    let c2 = this.CheckPasswordStrength(this.newPass)
    let c3 = this.compareBetweenNewAndRepeat()
    this.endCompare({...c1,...c2,...c3})
    console.log('ellog',c1,c2,c3,c1.success && c2.success && c3.success?false:true)
      this.setState({ disabledUpdate: c1.success && c2.success && c3.success?false:true })


    // this.helperText2 = null
    // this.helperText1 = null
    // let twoFieldsE = false
    // this.setState({ helperText1: false, helperText2: false, twoFieldsE: true, disabledUpdate: false })

  }
  endCompare(res) {
    this.helperText1 = res.t1
    this.helperText2 = res.t2
    this.helperText3 = res.t3
    this.setState({ helperText1: res.t1 ? true : false, helperText2: res.t2? true : false, helperText3: res.t3 ? true : false, twoFieldsE: res.tf })

  }
  changePassword() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");


    let raw = JSON.stringify({
      "email": this.state.email,
      // "token": this.state.token,
      "password": this.state.password
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.Direction_Back_panel + "/users/modifyPassword", requestOptions)
      .then(response => response.text())
      .then(result => {
        let _res = JSON.parse(result)
        if (_res.success) {
          this.ShowAlert('La contraseña fue actualizada con éxito, serás redireccionado al login.')
          setTimeout(() => {
            this.refreshPage()
          }, 4000)
        }
        else {
          this.showError('Ocurrió un error, la contraseña no cumple con los requerimientos')

        }
      })
      .catch(error => { this.showError('Ocurrió un error, la contraseña no cumple con los requerimientos') });
  }

  refreshPage = () => {
    localStorage.removeItem('fb_jwt')
    localStorage.removeItem('back_jwt')
    window.location.reload();
  }

  __showAlert(_alert) {

    clearTimeout(this.intervalS)
    if (_alert === 1) {
      this.setState({ showAlert: true })
    }
    else if (_alert === 2) {
      this.setState({ showAlert2: true })
    }

    this.intervalS = setTimeout(() => {
      if (_alert === 1) {
        this.setState({ showAlert: false })
      }
      else if (_alert === 2) {
        this.setState({ showAlert2: false })
      }
    }, 5500)
  }


  savePassword() {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let _token = localStorage.getItem('fb_jwt')
    myHeaders.append("Authorization", "Bearer " + _token);
    let raw = JSON.stringify({
      "oldPassword": this.oldPass,
      "password": this.newPass,
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.Direction_Back_panel + "/users/modifyPasswordIn", requestOptions)
      .then(response => response.text())
      .then(result => {
        let _res = JSON.parse(result)
        if (_res.success) {
          this.__showAlert(1)
          setTimeout(() => {
            this.refreshPage()
          }, 4000)
        }
        else {
          this.__showAlert(2)
          // setTimeout((),3000)
        }
      })
      .catch(error => console.log('error', error));
  }
  render() {
    return (<div>
      <GridContainer style={{ paddingRight: 0 }}>
        <GridItem xs={12}>
          <Collapse
            in={this.state.showAlert}

          >
            <Alert
              className="w-full h-full sm:w-12/12 p-12"
              style={{ marginBottom: 10 }}
              severity="success" color="info"
              action={
                <IconButton

                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ showAlert: false })
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              La contraseña fue actualizada con éxito, serás redireccionado al login.
            </Alert>
          </Collapse>
          <Collapse
            in={this.state.showAlert2}
          >
            <Alert
              className="w-full h-full sm:w-12/12 p-12"
              style={{ marginBottom: 10 }}
              severity="warning" color="warning"
              action={
                <IconButton

                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ showAlert2: false })
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >

              <AlertTitle>No pudimos modificar tu contraseña</AlertTitle>
              Verifica que sea correcta tu contraseña actual
            </Alert>
          </Collapse>
          <Paper >
            <CardBody >
              <Typography color="textPrimary" variant="h5" component="p">
                Cambiar contraseña
              </Typography>
              <Typography color="textPrimary" variant="h6" style={{fontWeight:400}} component="p">
                Usuario: {this.user}
              </Typography>
              <Typography color="textPrimary" variant="body1" component="p" style={{ marginTop: 15 }}>
                Contraseña actual
              </Typography>
              <ListItem
                style={{ paddingLeft: 0 }}
              >
                <TextField style={{ width: '100%' }} variant="filled" type="password" placeholder="Contraseña actual"
                  error={this.state.helperText1 || this.state.twoFieldsE}
                  onChange={(event) => {
                    this.oldPass = event.target.value
                    this.write1 = true
                    this.comparePass()
                  }}
                  helperText={(this.state.helperText1 === true) ? this.helperText1 : null}
                  inputProps={{ 'aria-label': 'description' }}
                />
              </ListItem>
              <Typography color="textPrimary" variant="body1" component="p" style={{ marginTop: 15 }}>
                Nueva contraseña
              </Typography>
              <ListItem
                style={{ paddingLeft: 0 }}
              >
                <TextField style={{ width: '100%' }} variant="filled" type="password" placeholder="Nueva contraseña"
                  error={this.state.helperText2 || this.state.twoFieldsE}
                  onChange={(event) => {
                    this.write2 = true
                    this.newPass = event.target.value
                    this.comparePass()
                  }}

                  helperText={(this.state.helperText2 === true) ? this.helperText2 : null}
                  inputProps={{ 'aria-label': 'description' }} />
              </ListItem>
              <ListItem
                style={{ paddingLeft: 0 }}
              >
                <TextField style={{ width: '100%' }} variant="filled" type="password" placeholder="Repetir contraseña"
                  error={this.state.helperText3 }
                  onChange={(event) => {
                    this.write3 = true
                    this.newPassRepeat = event.target.value
                    this.comparePass()
                  }}

                  helperText={(this.state.helperText3 === true) ? this.helperText3 : null}
                  inputProps={{ 'aria-label': 'description' }} />
              </ListItem>
              <div style={{ display: 'flex', justifyContent: 'end', padding: '0px 15px 0px 0px' }}>
                <Button
                  disabled={this.state.helperText1 || this.state.helperText2 || this.state.helperText3 || this.state.disabledUpdate}
                  onClick={() => {
                    this.savePassword()
                  }} >Actualizar</Button>
              </div>
            </CardBody>

          </Paper>
        </GridItem>
      </GridContainer>
    </div>
    )
  }

}

export default withStyles(sweetAlertStyle)(ProfileManager)
