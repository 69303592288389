import React from 'react'

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";




const languages = require('../../assets/translate/translate_registros.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

function BivResult(props) {

  const {
    regsLength,
  } = props

  BivResult.defaultProps = {
    regsLength: []
  }

  const _count = regsLength ? regsLength : "0" 

  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12}
        style={{ display: "flex", direction: "column", justifyContent: 'top', textAlign: 'left', marginTop: 5, marginBottom: 5, marginLeft: 30, marginRight: 30 }}
        >
        <div style={{marginRight: 8}}>{languages[lang].registros_result}</div><div style={{fontWeight: "bold"}}> { _count.toString() } </div>
      </GridItem>
    </GridContainer>
  )

}

export default BivResult

