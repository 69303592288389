import React from 'react'

import BivControlFaceR from "components/BivControlFaceR/BivControlFaceR.jsx";
import BivControlComun from "components/BivControlComun/BivControlComun.jsx";




const languages = require('../../../assets/translate/translate_proctoringControl.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

class TabImagen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      controlExpanded: "",
    }
  }

  handleExpandClick(x) {
    if (this.state.controlExpanded === x) {
      this.setState({ controlExpanded: "" })
    } else {
      this.setState({ controlExpanded: x })
    }
  };

  render() {

    return (
      <div style={{ display: 'flex', flexDirection: "column", overflow: 'hidden' }}>

        {/* Alumno es reemplazado por otra persona. (fr) */}
        {this.props.values.fr ?
          <span>
            <BivControlFaceR
              values={this.props.values.fr}

              switchText={languages[lang].proctoringC_studentIsRBSElse}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.fr.a = !this.props.values.fr.a
                    if (this.props.values.fr.a) {
                      //this.state.controlExpanded = "frA"
                      this.setState({ controlExpanded: "frA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "frA") ? true : false}
              expandId="frA"
              expandOnChange={this.handleExpandClick.bind(this, "frA")}

              coincidenceCountOnChange={(number) => {
                if (number != null) {
                  this.setState({}, () => {
                    //this.props.values.fr.co = number / 100
                    this.props.values.fr.co = number
                    this.props.checkChanges()
                  })
                }
              }}
            
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.fr.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.fr.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.fr.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
          : null}


        {/* Alumno está presente pero se detectan otras personas más. (mf) */}
        {this.props.values.mf ?
          <span>
            <BivControlComun
              values={this.props.values.mf}

              switchText={languages[lang].proctoringC_studentIsPBOPADetected}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.mf.a = !this.props.values.mf.a
                    if (this.props.values.mf.a) {
                      this.setState({ controlExpanded: "mfA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "mfA") ? true : false}
              expandId="mfA"
              expandOnChange={this.handleExpandClick.bind(this, "mfA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.mf.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.mf.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.mf.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
          : null}


        {/* Detección de alteraciones en entorno. (nf) */}
        {this.props.values.nf ?
          <span>
            <BivControlComun
              values={this.props.values.nf}

              switchText={languages[lang].proctoringC_detectionOfAITEnviroment}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.nf.a = !this.props.values.nf.a
                    if (this.props.values.nf.a) {
                      //this.state.controlExpanded = "nfA"
                      this.setState({ controlExpanded: "nfA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "nfA") ? true : false}
              expandId="nfA"
              expandOnChange={this.handleExpandClick.bind(this, "nfA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.nf.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.nf.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.nf.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
          : null}

        {/* Alumno quita la vista de la pantalla y mira abajo. (td) */}
        {
          this.props.values.td ? <span>
            <BivControlComun
              values={this.props.values.td}

              switchText={languages[lang].proctoringC_studentTakesHEOTSALDown}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.td.a = !this.props.values.td.a
                    if (this.props.values.td.a) {
                      //this.state.controlExpanded = "tdA"
                      this.setState({ controlExpanded: "tdA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "tdA") ? true : false}
              expandId="tdA"
              expandOnChange={this.handleExpandClick.bind(this, "tdA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.td.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.td.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.td.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
            : null
        }

        {/* Alumno quita la vista de la pantalla y mira arriba. (tu) */}
        {
          this.props.values.tu ? <span>
            <BivControlComun
              values={this.props.values.tu}

              switchText={languages[lang].proctoringC_studentTakesHEOTSALUp}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.tu.a = !this.props.values.tu.a
                    if (this.props.values.tu.a) {
                      this.setState({ controlExpanded: "tuA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "tuA") ? true : false}
              expandId="tuA"
              expandOnChange={this.handleExpandClick.bind(this, "tuA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tu.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tu.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tu.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
            : null
        }

        {/* Alumno quita la vista de la pantalla y mira al costado. (tlr) */}
        {
          this.props.values.tlr ? <span>
            <BivControlComun
              values={this.props.values.tlr}

              switchText={languages[lang].proctoringC_studentTakesHEOTSALTTSide}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.tlr.a = !this.props.values.tlr.a
                    if (this.props.values.tlr.a) {
                      this.setState({ controlExpanded: "tlrA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "tlrA") ? true : false}
              expandId="tlrA"
              expandOnChange={this.handleExpandClick.bind(this, "tlrA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tlr.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tlr.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.tlr.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
            : null
        }


        {/* Alumno quita la vista de la pantalla, pero no se pudo establecer hacia donde. () */}
        {
          this.props.values.movInd ? <span>
            <BivControlComun
              values={this.props.values.movInd}

              switchText={languages[lang].proctoringC_studentLooksAFTSBCNEWhere}
              switchOnChange={() => {
                if (!global.readOnlyUser) {
                  this.setState({}, () => {
                    this.props.values.movInd.a = !this.props.values.movInd.a
                    if (this.props.values.movInd.a) {
                      //this.state.controlExpanded = "movIndA"
                      this.setState({ controlExpanded: "movIndA" }, this.props.checkChanges)
                    } else {
                      this.setState({ controlExpanded: "" }, this.props.checkChanges)
                    }
                    this.props.checkChanges()
                  })
                }
              }}
              expandValue={(this.state.controlExpanded === "movIndA") ? true : false}
              expandId="movIndA"
              expandOnChange={this.handleExpandClick.bind(this, "movIndA")}
              initialPriorityOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.movInd.ip = number
                  this.props.checkChanges()
                })
              }}
              changePriorityFromOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.movInd.t.v = number
                  this.props.checkChanges()
                })
              }}
              changePriorityToOnChange={(number) => {
                this.setState({}, () => {
                  this.props.values.movInd.t.p = number
                  this.props.checkChanges()
                })
              }}
            />
          </span>
            : null
        }

     
        {/** No se detecta rostro */}
        {
          this.props.values.np ?
            <span>
              <BivControlComun
                values={this.props.values.np}

                switchText={"No se detecta rostro."}
                switchOnChange={() => {
                  if (!global.readOnlyUser) {
                    this.setState({}, () => {
                      this.props.values.np.a = !this.props.values.np.a
                      if (this.props.values.np.a) {
                        this.setState({ controlExpanded: "npA" }, this.props.checkChanges)
                      } else {
                        this.setState({ controlExpanded: "" }, this.props.checkChanges)
                      }
                      this.props.checkChanges()
                    })
                  }
                }}
                expandValue={(this.state.controlExpanded === "npA") ? true : false}
                expandId="npA"
                expandOnChange={this.handleExpandClick.bind(this, "npA")}
                initialPriorityOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.np.ip = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityFromOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.np.t.v = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityToOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.np.t.p = number
                    this.props.checkChanges()
                  })
                }}
              />
            </span>
            : null
        }
        {/** No se identifica a la persona registrada */}
        {
          this.props.values.rpni ?
            <span>
              <BivControlFaceR
                values={this.props.values.rpni}

                switchText={"No se identifica a la persona registrada."}
                switchOnChange={() => {
                  if (!global.readOnlyUser) {
                    this.setState({}, () => {
                      this.props.values.rpni.a = !this.props.values.rpni.a
                      if (this.props.values.rpni.a) {
                        //this.state.controlExpanded = "rpniA"
                        this.setState({ controlExpanded: "rpniA" }, this.props.checkChanges)
                      } else {
                        this.setState({ controlExpanded: "" }, this.props.checkChanges)
                      }
                    })
                  }
                }}
                expandValue={(this.state.controlExpanded === "rpniA") ? true : false}
                expandId="rpniA"
                expandOnChange={this.handleExpandClick.bind(this, "rpniA")}

                coincidenceCountOnChange={(number) => {
                  if (number != null) {
                    this.setState({}, () => {
                      //this.props.values.rpni.co = number / 100
                      this.props.values.rpni.co = number
                      this.props.checkChanges()
                    })
                  }
                }}

                initialPriorityOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rpni.ip = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityFromOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rpni.t.v = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityToOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rpni.t.p = number
                    this.props.checkChanges()
                  })
                }}
              />
            </span>
            : null
        }
        {/** Se detectan movimientos de rostro */}
        {
          this.props.values.fm ?
            <span>
              <BivControlComun
                values={this.props.values.fm}

                switchText={"Se detectan movimientos de rostro."}
                switchOnChange={() => {
                  if (!global.readOnlyUser) {
                    this.setState({}, () => {
                      this.props.values.fm.a = !this.props.values.fm.a
                      if (this.props.values.fm.a) {
                        this.setState({ controlExpanded: "fmA" }, this.props.checkChanges)
                      } else {
                        this.setState({ controlExpanded: "" }, this.props.checkChanges)
                      }
                      this.props.checkChanges()
                    })
                  }
                }}
                expandValue={(this.state.controlExpanded === "fmA") ? true : false}
                expandId="fmA"
                expandOnChange={this.handleExpandClick.bind(this, "fmA")}
                initialPriorityOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.fm.ip = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityFromOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.fm.t.v = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityToOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.fm.t.p = number
                    this.props.checkChanges()
                  })
                }}
              />
            </span>
            : null
        }
        {/** Se reconoce a más de una persona */}
        {
          this.props.values.rmp ?
            <span>
              <BivControlComun
                values={this.props.values.rmp}

                switchText={"Se reconoce a más de una persona."}
                switchOnChange={() => {
                  if (!global.readOnlyUser) {
                    this.setState({}, () => {
                      this.props.values.rmp.a = !this.props.values.rmp.a
                      if (this.props.values.rmp.a) {
                        this.setState({ controlExpanded: "rmpA" }, this.props.checkChanges)
                      } else {
                        this.setState({ controlExpanded: "" }, this.props.checkChanges)
                      }
                      this.props.checkChanges()
                    })
                  }
                }}
                expandValue={(this.state.controlExpanded === "rmpA") ? true : false}
                expandId="rmpA"
                expandOnChange={this.handleExpandClick.bind(this, "rmpA")}
                initialPriorityOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rmp.ip = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityFromOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rmp.t.v = number
                    this.props.checkChanges()
                  })
                }}
                changePriorityToOnChange={(number) => {
                  this.setState({}, () => {
                    this.props.values.rmp.t.p = number
                    this.props.checkChanges()
                  })
                }}
              />
            </span>
            : null
        }

      </div>

    )
  }
}

export default TabImagen
