import React from 'react'

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function BivDivider(props) {

  return(
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 20, marginRight: 20, marginTop: 11, marginBottom: 11 }}>
        <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }}/>
      </GridItem>
    </GridContainer>
  )

}

export default BivDivider