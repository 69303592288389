import React from 'react'
import Badge from "components/Badge/Badge.jsx";


const languages = require('../../assets/translate/translate_validationFormProfile.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

function ValidationLabel(props) {

    const { validation } = props

    let _text = ""
    let _color = "greenBiv"

    switch (validation) {
        case 1:
            _text = languages[lang].validationFormProfile_low
            _color = "greenBiv"
            break;
        case 2:
            _text = languages[lang].validationFormProfile_medium
            _color = "yellowBiv"
            break;
        case 3:
            _text = languages[lang].validationFormProfile_high
            _color = "orangeBiv"
            break;
        case 4:
            _text = languages[lang].validationFormProfile_critic
            _color = "redBiv"
            break;
        default:
            _text = languages[lang].validationFormProfile_deviceData
            _color = "greenBiv"
            break;
    }

    return (
        <Badge color={_color}>{_text}</Badge>
    )

}

export default ValidationLabel