/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from '@material-ui/icons/MenuOpen';

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FixedPlugin from "components/FixedPlugin/FixedPlugin";

const languages = require('../../assets/translate/translate_button.json')
let lang  =  localStorage.lang?localStorage.lang:'es'

 function AvatarChips (){ 
  let rol =JSON.parse(localStorage.rol).name
  let userRol = JSON.parse(localStorage.rol).authorities.rol
  return null
  return  (

    

<Paper sx={{ maxWidth: 300, maxHeight:50, marginRight:2,paddingY:.3,paddingX:.5  }}>
{/* <CardContent sx={{ margin:0 , padding:0}}> */}
  <Typography sx={{ fontSize: 12 }}  >
  <strong>Rol: </strong>{userRol}
  </Typography>
  <Typography sx={{ fontSize: 12 }} >
  <strong>Usuario: </strong>{rol}
  </Typography>
  {/* </CardContent> */}
</Paper>


  );}



function AdminNavbar({ ...props }) {
  const { classes, color, rtlActive, brandText, getAccountInfo } = props;    
  

  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
    
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
    
  return (
    <div>
      <Hidden smDown implementation="css">
        <div className={sidebarMinimize + " "} style={{ position: "fixed", marginLeft: -35, marginTop: 10, zIndex: 1032 }}>
          {props.miniActive ? (
            <Button
              justIcon
              round
              color="white"
              onClick={props.sidebarMinimize}
            >
              <Menu className={classes.sidebarMiniIcon} />
            </Button>
          ) : (
            <Button
              justIcon
              round
              color="white"
              onClick={props.sidebarMinimize}
            >
              <MoreVert className={classes.sidebarMiniIcon} />
            </Button>
          )}
        </div>
        {/* <div className={sidebarMinimize + " "} style={{ position: "fixed", right: -20, marginTop: 10, zIndex: 1032 }}>
          <Button
            
            onClick={handleOpen}
            justIcon
            round
            color="lightBlueKW"
            style={{width:'100%'}}
          >
            <LanguageIcon color='white' className={classes.sidebarMiniIcon} style={{marginRight:25}}/>
            </Button>

            <FormControl className={classes.formControl} style={{height:0, width:0,visibility:'hidden'}}>
        <InputLabel id="demo-controlled-open-select-label" >Lang</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={'en'} onClick={refreshPage}>{languages[lang].button_english}</MenuItem>
          <MenuItem value={'es'} onClick={refreshPage}>{languages[lang].button_spanish}</MenuItem>
        </Select>
      </FormControl>
        </div> */}
      </Hidden>
      <AppBar className={classes.appBar + appBarClasses}>

        <Toolbar className={classes.container} >

          {/* <div className={classes.flex}>
            {
              (brandText === languages[lang].button_proctoringControl || brandText === languages[lang].button_environmentControl || brandText === languages[lang].button_dashboard ) ?
                <Button href="#" className={classes.title} color="transparent">
                  {brandText}
                </Button>
                :
                null
            }

          </div> */}
          <Hidden smDown implementation="css">

          <AvatarChips rtlActive={rtlActive} />

            {/* <Button>aaa</Button> */}
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
              
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
