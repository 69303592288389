import React from 'react'

function BivDashboardCardListItem(props) {

    const { name, value, classes} = props

    return (
        
        <div style={{ marginTop: 6, marginBottom: 5, display:"flex", flexDirection:"row", width: "100%"}}>

            <div style={{width: "82%"}}>
                <p
                    className={classes.cardCategory}
                    style={{paddingBottom: 2,
                        paddingTop: 2,
                        marginBottom: 2,
                        marginTop: 2,
                        fontSize: '0.7rem',
                        color: 'white',
                        //fontWeight: 'bold'
                    }}>
                    {name}
                </p>
            </div>
            <div style={{width: "17%", textAlign: 'right', paddingRight: 3 }}>
                <p
                    className={classes.cardCategory}
                    style={{paddingBottom: 2,
                        paddingTop: 2,
                        marginBottom: 2,
                        marginTop: 2,
                        fontSize: '0.7rem',
                        color: 'white',
                        //fontWeight: 'bold'
                    }}>
                    {value}
                </p>
            </div>
        </div>
    )

}

export default BivDashboardCardListItem